import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SHADOWS, SPACING } from '../../styles/variables';
import Section from '../Section/Section';

// Importar imágenes de las categorías
import furgonImage from '../../assets/images/03-Furgon-L-768x768.png';
import pickupImage from '../../assets/images/04-PickUp-768x768.png';
import cargoImage from '../../assets/images/05-Cargo-768x768.png';
import cargoBoxImage from '../../assets/images/06-Cargo-Box-768x768.png';
import camion34Image from '../../assets/images/07-3cuarto-768x768.png';
import { default as camion34BoxImage, default as mudanzasImage } from '../../assets/images/08-3cuarto-768x768.png';

const CategoriesContainer = styled.div`
  width: 100%;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_MAIN};
  font-size: calc(${FONTS.SIZE_HEADING_2} * 1.1);
`;

const SectionDescription = styled.p`
  text-align: center;
  max-width: 800px;
  margin: 0 auto ${SPACING.LARGE};
`;

const Divider = styled.div`
  width: 80px;
  height: 4px;
  background-color: ${COLORS.SUCCESS};
  margin: 0 auto ${SPACING.LARGE};
  border-radius: 2px;
`;

const CategoryTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SPACING.SMALL};
  margin-bottom: ${SPACING.LARGE};
  
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    overflow-x: auto;
    padding-bottom: ${SPACING.SMALL};
    justify-content: flex-start;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.GREY_LIGHT};
      border-radius: 10px;
    }
  }
`;

const CategoryTab = styled.button`
  background: ${({ active }) => active ? COLORS.BLUE_MAIN : COLORS.WHITE};
  color: ${({ active }) => active ? COLORS.WHITE : COLORS.BLACK_TEXT};
  border: 2px solid ${({ active }) => active ? COLORS.BLUE_MAIN : COLORS.GREY_LIGHT};
  border-radius: ${SPACING.SMALL};
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  font-size: ${FONTS.SIZE_BODY};
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  
  &:hover {
    background: ${({ active }) => active ? COLORS.BLUE_MAIN : COLORS.BLUE_MAIN + '11'};
    border-color: ${COLORS.BLUE_MAIN};
  }
`;

const CategoryContent = styled.div`
  background-color: transparent;
  border-radius: ${SPACING.MEDIUM};
  box-shadow: ${SHADOWS.MEDIUM};
  overflow: hidden;
  transition: all 0.3s ease;
  min-height: ${({ minHeight }) => minHeight ? `${minHeight}px` : '500px'};
  position: relative;
`;

const CategoryDetails = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
  }
`;

const CategoryImage = styled.div`
  background-color: transparent;
  flex: 1;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(2px);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    min-width: 40%;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 1;
  }
`;

const VehicleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  padding: ${SPACING.MEDIUM};
  transition: transform 0.5s ease;
  position: relative;
  z-index: 2;
  
  ${CategoryContent}:hover & {
    transform: scale(1.05);
  }
`;

const CategoryInfo = styled.div`
  flex: 1.5;
  padding: ${SPACING.LARGE};
  background-color: ${COLORS.WHITE}F8;
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;
`;

const CategoryName = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_MAIN};
`;

const CategoryTagline = styled.h4`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLACK_TEXT};
`;

const CategoryDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.6;
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLACK_TEXT}DD;
`;

const CategoryFeatures = styled.div`
  display: flex;
  gap: ${SPACING.MEDIUM};
  margin-bottom: ${SPACING.MEDIUM};
  flex-wrap: wrap;
`;

const CategoryFeature = styled.span`
  background-color: ${COLORS.BLUE_MAIN}11;
  color: ${COLORS.BLUE_MAIN};
  padding: ${SPACING.XSMALL} ${SPACING.SMALL};
  border-radius: 50px;
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  display: inline-flex;
  align-items: center;
`;

// Componente mejorado para precargar imágenes
const ImagePreloader = ({ images }) => {
  // Usar useEffect para cargar las imágenes inmediatamente
  useEffect(() => {
    // Precargar todas las imágenes inmediatamente
    images.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, [images]);

  // También renderizar las imágenes ocultas como respaldo
  return (
    <div style={{ display: 'none', position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}>
      {images.map((src, index) => (
        <img 
          key={index} 
          src={src} 
          alt="preload" 
          onLoad={() => console.log(`Imagen precargada: ${src}`)}
        />
      ))}
    </div>
  );
};

const VehicleCategoriesSection = () => {
  // Definir las categorías primero
  const categories = [
    {
      id: 'pickup',
      name: 'PICKUP',
      tagline: 'Potencia y versatilidad para tu flete',
      description: 'Nuestras Pickup están preparadas para transportar objetos de hasta 3 metros de longitud. Son ideales para mover muebles medianos como sillas, mesas, y escritorios, así como materiales de construcción. Su versatilidad y potencia ofrecen un equilibrio inmejorable entre precio y capacidad de carga.',
      features: ['VERSÁTIL', '800 KG', 'POTENCIA'],
      image: pickupImage
    },
    {
      id: 'furgon-m',
      name: 'FURGÓN M',
      tagline: 'Seguridad y espacio para tus Fletes',
      description: 'Nuestros Furgones M o L son la solución perfecta para transportar muebles medianos y grandes, como sofás, camas, y electrodomésticos voluminosos. Estas categorías ofrecen un amplio espacio y total seguridad para tus bienes, todo con un valor excepcional en cuanto a calidad precio. Furgón M o L, garantiza que cada carga llegue en perfectas condiciones, sin importar el volumen.',
      features: ['CALIDAD', '600 KG', 'SEGURIDAD'],
      image: furgonImage
    },
    {
      id: 'cargo',
      name: 'CARGO',
      tagline: 'Fletes grandes a bajo costo',
      description: 'Los vehículos de la categoría Cargo permiten transportar grandes volúmenes de mercancía sirviendo para trasladar materiales de construcción y equipos industriales, ofreciendo un balance óptimo entre calidad y precio. Su diseño permite el uso eficiente del espacio, tanto en ancho como en alto, asegurando que cada entrega sea rápida y segura, sin importar el tipo de carga.',
      features: ['CAPACIDAD', '1.525 KG', 'MEJOR PRECIO'],
      image: cargoImage
    },
    {
      id: 'cargo-box',
      name: 'CARGO BOX',
      tagline: 'Protección total para tu flete',
      description: 'Los vehículos de la categoría Cargo Box ofrecen un área de carga completamente cerrada, protegiendo tus productos de inclemencias climáticas y suciedad. Son ideales para el transporte de productos delicados o electrónicos. Además, su estructura cerrada asegura una distribución eficiente a un costo que se adecua a tu bolsillo, sin sacrificar la calidad.',
      features: ['CALIDAD', '1.525 KG', 'PROTECCIÓN'],
      image: cargoBoxImage
    },
    {
      id: 'camion-3-4',
      name: 'CAMIÓN 3/4',
      tagline: 'Capacidad perfecta para fletes grandes',
      description: 'Esta categoría es ideal para transportar maquinaria pesada, productos industriales, grandes cantidades de materiales de construcción u objetos de tu hogar. Con una estructura robusta y dimensiones óptimas, esta categoría ofrece un desempeño superior a un precio justo garantizando que la carga llegue segura a su destino. Esta categoría ofrece la confianza y la capacidad necesaria para superar cualquier desafío de transporte pesado.',
      features: ['CONFIANZA', '4.900 KG', 'CARGAS PESADAS'],
      image: camion34Image
    },
    {
      id: 'camion-3-4-box',
      name: 'CAMIÓN 3/4 BOX',
      tagline: 'Protección y gran capacidad',
      description: 'Nuestros vehículos de la categoría de Camiones 3/4 Box son la opción para transportar objetos de hasta 8 metros de largo con una mayor seguridad por su capacidad cerrada. Son ideales para el transporte de muebles, maquinaria pesada, materiales de construcción, ofrecen un balance ideal entre protección y economía.',
      features: ['CERRADO', '4.900 KG', 'PROTECCIÓN'],
      image: camion34BoxImage
    },
    {
      id: 'mudanzas',
      name: 'MUDANZAS',
      tagline: 'Servicio completo para tu traslado',
      description: 'Nuestro servicio de mudanzas está diseñado para ofrecerte una experiencia sin complicaciones al cambiar de domicilio. Contamos con personal especializado, equipamiento adecuado y vehículos preparados para garantizar que tus pertenencias lleguen en perfectas condiciones a su nuevo destino, todo con la calidad y eficiencia que caracteriza a Trineo.',
      features: ['INTEGRAL', 'EXPERIENCIA', 'CUIDADO'],
      image: mudanzasImage
    }
  ];

  // Intentar recuperar la última categoría seleccionada del localStorage
  const getInitialCategory = () => {
    try {
      const savedCategory = localStorage.getItem('trineo_selected_category');
      return savedCategory && categories.some(cat => cat.id === savedCategory) 
        ? savedCategory 
        : 'pickup';
    } catch (e) {
      // En caso de error (ej. localStorage deshabilitado)
      return 'pickup';
    }
  };

  const [activeCategory, setActiveCategory] = useState(getInitialCategory);
  const [visibleCategory, setVisibleCategory] = useState(getInitialCategory);
  const [displayedCategory, setDisplayedCategory] = useState(getInitialCategory);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const detailsRef = useRef(null);
  
  // Guardar la categoría seleccionada en localStorage
  useEffect(() => {
    try {
      localStorage.setItem('trineo_selected_category', activeCategory);
    } catch (e) {
      // Manejar silenciosamente errores de localStorage
      console.log('No se pudo guardar la preferencia de categoría');
    }
  }, [activeCategory]);
  
  // Manejar el cambio de categoría con un efecto de fade
  useEffect(() => {
    if (activeCategory !== visibleCategory) {
      // Iniciar transición
      setIsTransitioning(true);
      
      // Ocultar la categoría actual
      const hideTimer = setTimeout(() => {
        setVisibleCategory('');
      }, 50);
      
      // Cambiar la categoría que se muestra después de que se oculte la actual
      const changeTimer = setTimeout(() => {
        setDisplayedCategory(activeCategory);
      }, 450);
      
      // Mostrar la nueva categoría después de un breve retraso
      const showTimer = setTimeout(() => {
        setVisibleCategory(activeCategory);
        setIsTransitioning(false);
      }, 500);
      
      return () => {
        clearTimeout(hideTimer);
        clearTimeout(changeTimer);
        clearTimeout(showTimer);
      };
    }
  }, [activeCategory, visibleCategory]);
  
  // Actualizar la altura del contenido cuando cambia la categoría visible o al cargar inicialmente
  useEffect(() => {
    if (detailsRef.current && visibleCategory === activeCategory) {
      // Pequeño retraso para asegurar que el contenido se ha renderizado completamente
      const timer = setTimeout(() => {
        const newHeight = detailsRef.current.offsetHeight;
        setContentHeight(newHeight);
        
        // Marcar que la carga inicial ha terminado
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [visibleCategory, activeCategory, isInitialLoad]);
  
  // Establecer altura inicial basada en el tamaño de la ventana
  useEffect(() => {
    // Estimar una altura inicial basada en el tamaño de la ventana
    const estimateInitialHeight = () => {
      const windowHeight = window.innerHeight;
      // En dispositivos móviles, usar una proporción mayor de la altura de la ventana
      if (windowHeight < 768) {
        return Math.min(windowHeight * 0.7, 500);
      }
      // En pantallas más grandes, usar una altura fija más adecuada
      return 500;
    };
    
    if (isInitialLoad) {
      setContentHeight(estimateInitialHeight());
    }
  }, [isInitialLoad]);
  
  // Obtener la categoría actual para mostrar
  const currentCategory = categories.find(cat => cat.id === displayedCategory);
  
  // Crear un array con todas las imágenes para precargar
  const allImages = categories.map(category => category.image);
  
  // Precargar imágenes al montar el componente
  useEffect(() => {
    // Precargar imágenes con prioridad alta
    const preloadImages = async () => {
      try {
        // Si el navegador soporta link rel=preload
        if ('connection' in navigator) {
          allImages.forEach(src => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = src;
            document.head.appendChild(link);
          });
        }
      } catch (e) {
        console.log('Error al precargar imágenes:', e);
      }
    };
    
    preloadImages();
  }, []); // Solo se ejecuta una vez al montar el componente
  
  return (
    <Section background="white" id="categorias">
      {/* Precargar todas las imágenes */}
      <ImagePreloader images={allImages} />
      
      <CategoriesContainer>
        <SectionTitle>Categorías de vehículos</SectionTitle>
        <Divider />
        <SectionDescription>
          Encuentra el vehículo ideal para tu flete. En Trineo contamos con diferentes categorías adaptadas para cada necesidad.
        </SectionDescription>
        
        <CategoryTabs>
          {categories.map((category) => (
            <CategoryTab 
              key={category.id}
              active={activeCategory === category.id}
              onClick={() => {
                if (!isTransitioning && activeCategory !== category.id) {
                  setActiveCategory(category.id);
                }
              }}
            >
              {category.name}
            </CategoryTab>
          ))}
        </CategoryTabs>
        
        <CategoryContent minHeight={contentHeight}>
          <CategoryDetails 
            isVisible={visibleCategory === displayedCategory}
            ref={detailsRef}
          >
            <CategoryImage>
              <VehicleImage 
                src={currentCategory.image} 
                alt={`Vehículo ${currentCategory.name}`} 
              />
            </CategoryImage>
            <CategoryInfo>
              <CategoryFeatures>
                {currentCategory.features.map((feature, index) => (
                  <CategoryFeature key={index}>
                    {feature}
                  </CategoryFeature>
                ))}
              </CategoryFeatures>
              <CategoryName>{currentCategory.name}</CategoryName>
              <CategoryTagline>{currentCategory.tagline}</CategoryTagline>
              <CategoryDescription>{currentCategory.description}</CategoryDescription>
            </CategoryInfo>
          </CategoryDetails>
        </CategoryContent>
      </CategoriesContainer>
    </Section>
  );
};

export default VehicleCategoriesSection; 