import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const BackgroundCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: ${props => props.$opacity || 0.15};
  pointer-events: none;
  display: block;
`;

const NetworkBackground = ({ 
  opacity = 0.3, // Aumentado a 0.3 para mayor visibilidad
  nodeCount = 80, 
  speed = 0.5,
  parallaxFactor = 0.3, // Factor de parallax (0 = sin efecto, 1 = movimiento completo)
  className = ''
}) => {
  // Definimos los colores para transportistas (amarillo) y usuarios (azul)
  const TRANSPORTISTA_COLOR = '#FBAC02'; // Color amarillo para transportistas (TRIP_YELLOW)
  const USUARIO_COLOR = '#0E3386';       // Color azul para usuarios
  
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Detectar si es un dispositivo móvil
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Consideramos móvil si el ancho es menor o igual a 768px
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);
  
  // Calcular la cantidad de nodos según el dispositivo
  const actualNodeCount = isMobile ? Math.floor(nodeCount * 0.2) : nodeCount; // 20% de nodos en móvil
  
  // Efecto para observar visibilidad
  useEffect(() => {
    if (!containerRef.current) return;
    
    const currentContainer = containerRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    
    observer.observe(currentContainer);
    
    return () => {
      observer.unobserve(currentContainer);
    };
  }, []);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let nodes = [];
    let isAnimating = false;
    
    // Definición de la cuadrícula de calles
    let streets = {
      horizontal: [],
      vertical: []
    };
    
    // Cargar imágenes para los iconos
    const truckIcon = new Image();
    const vanIcon = new Image();
    const deliveryIcon = new Image();
    const userIcon = new Image();
    
    // Crear SVGs en base64 para los iconos
    const createSVGIcon = (icon, color) => {
      let path = '';
      
      switch(icon) {
        case 'truck':
          // Camión grande
          path = 'M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
          break;
        case 'van':
          // Camioneta/Van
          path = 'M17 5H3c-1.1 0-2 .9-2 2v9h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-6-6zm-2 2h1l3 3h-4V7zM9 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
          break;
        case 'delivery':
          // Furgón de reparto
          path = 'M19.5 8H17V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v9h3c0 1.66 1.34 3 3 3s3-1.34 3-3h4c0 1.66 1.34 3 3 3s3-1.34 3-3h3v-3.5l-3.5-3.5zM7 17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm10 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z';
          break;
        case 'user':
          // Persona/Usuario
          path = 'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z';
          break;
        default:
          path = '';
      }
      
      const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="${color}">
          <path d="${path}"/>
        </svg>
      `;
      return 'data:image/svg+xml;base64,' + btoa(svg);
    };
    
    truckIcon.src = createSVGIcon('truck', TRANSPORTISTA_COLOR);
    vanIcon.src = createSVGIcon('van', TRANSPORTISTA_COLOR);
    deliveryIcon.src = createSVGIcon('delivery', TRANSPORTISTA_COLOR);
    userIcon.src = createSVGIcon('user', USUARIO_COLOR);
    
    // Ajustar el tamaño del canvas al tamaño de la ventana
    const handleResize = () => {
      const parent = canvas.parentElement;
      canvas.width = parent.offsetWidth || window.innerWidth;
      canvas.height = parent.offsetHeight || window.innerHeight;
      
      // Recalcular la cuadrícula de calles
      createStreetGrid();
      
      initNodes();
    };
    
    // Crear la cuadrícula de calles
    const createStreetGrid = () => {
      streets = {
        horizontal: [],
        vertical: []
      };
      
      // Definir cuántas calles tendremos (ajustar según el tamaño de la pantalla)
      const numHorizontalStreets = Math.max(3, Math.floor(canvas.height / 200));
      const numVerticalStreets = Math.max(3, Math.floor(canvas.width / 200));
      
      // Crear calles horizontales
      const horizontalSpacing = canvas.height / (numHorizontalStreets + 1);
      for (let i = 1; i <= numHorizontalStreets; i++) {
        streets.horizontal.push(horizontalSpacing * i);
      }
      
      // Crear calles verticales
      const verticalSpacing = canvas.width / (numVerticalStreets + 1);
      for (let i = 1; i <= numVerticalStreets; i++) {
        streets.vertical.push(verticalSpacing * i);
      }
    };
    
    // Verificar si una posición está en una calle
    const isPositionOnStreet = (x, y, streetWidth = 20) => {
      // Verificar calles horizontales
      for (const streetY of streets.horizontal) {
        if (Math.abs(y - streetY) < streetWidth) {
          return true;
        }
      }
      
      // Verificar calles verticales
      for (const streetX of streets.vertical) {
        if (Math.abs(x - streetX) < streetWidth) {
          return true;
        }
      }
      
      return false;
    };
    
    // Encontrar una posición para usuarios que no esté en una calle
    const findPositionNotOnStreet = () => {
      const streetBuffer = 25; // Zona de seguridad para no estar cerca de las calles
      let x, y;
      let attempts = 0;
      
      do {
        x = Math.random() * canvas.width;
        y = Math.random() * canvas.height;
        attempts++;
        
        // Si después de 50 intentos no se encuentra posición, aceptamos cualquiera
        if (attempts > 50) break;
      } while (isPositionOnStreet(x, y, streetBuffer));
      
      return { x, y };
    };
    
    // Encontrar la intersección más cercana a una posición
    const findNearestIntersection = (x, y) => {
      let closestDistance = Infinity;
      let intersection = null;
      
      for (const streetY of streets.horizontal) {
        for (const streetX of streets.vertical) {
          const dx = x - streetX;
          const dy = y - streetY;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < closestDistance) {
            closestDistance = distance;
            intersection = { x: streetX, y: streetY };
          }
        }
      }
      
      return intersection;
    };
    
    // Inicializar los nodos
    const initNodes = () => {
      nodes = [];
      
      // Decidir la distribución de usuarios y transportistas
      const userRatio = 0.5; // 40% usuarios, 60% transportistas
      const userCount = Math.floor(actualNodeCount * userRatio);
      const transportistaCount = actualNodeCount - userCount;
      
      // Distribuir los usuarios en posiciones fijas que NO ESTÉN en las calles
      for (let i = 0; i < userCount; i++) {
        // Encontrar una posición que no esté en una calle
        const position = findPositionNotOnStreet();
        
        nodes.push({
          x: position.x,
          y: position.y,
          radius: Math.random() * 2.5 + 2.0,
          vx: 0, // Velocidad cero para mantenerlos fijos
          vy: 0, // Velocidad cero para mantenerlos fijos
          color: USUARIO_COLOR,
          type: 'usuario',
          vehicleType: 'user',
          size: 10
        });
      }
      
      // Distribuir los transportistas en las calles
      for (let i = 0; i < transportistaCount; i++) {
        // Determinar si el transportista irá en calle horizontal o vertical
        const isHorizontal = Math.random() > 0.5;
        
        // Seleccionar una calle al azar
        const streetArray = isHorizontal ? streets.horizontal : streets.vertical;
        const streetIndex = Math.floor(Math.random() * streetArray.length);
        const streetPosition = streetArray[streetIndex];
        
        // Asignar posición inicial y dirección
        let x, y, vx, vy;
        
        if (isHorizontal) {
          // En calle horizontal, varía X y fija Y
          x = Math.random() * canvas.width;
          y = streetPosition;
          vx = (Math.random() > 0.5 ? 1 : -1) * (0.5 + Math.random() * 1.0) * speed;
          vy = 0;
        } else {
          // En calle vertical, varía Y y fija X
          x = streetPosition;
          y = Math.random() * canvas.height;
          vx = 0;
          vy = (Math.random() > 0.5 ? 1 : -1) * (0.5 + Math.random() * 1.0) * speed;
        }
        
        // Determinar tipo de vehículo
        const vehicleRandom = Math.random();
        let vehicleType;
        if (vehicleRandom < 0.4) {
          vehicleType = 'truck';
        } else if (vehicleRandom < 0.6) {
          vehicleType = 'van';
        } else {
          vehicleType = 'delivery';
        }
        
        nodes.push({
          x,
          y,
          radius: Math.random() * 2.5 + 2.0,
          vx,
          vy,
          color: TRANSPORTISTA_COLOR,
          type: 'transportista',
          vehicleType,
          size: 16,
          street: {
            isHorizontal,
            position: streetPosition
          },
          // Probabilidad de cambiar de dirección en intersecciones
          turnProbability: Math.random() * 0.3,
          // Tiempo mínimo antes de poder girar de nuevo (evita giros repetidos)
          nextTurnAllowedAt: 0
        });
      }
    };
    
    // Dibujar las "calles" como líneas más visibles
    const drawStreets = () => {
      ctx.save();
      
      // Dibujar el fondo de las calles
      ctx.globalAlpha = 0.15; // Más visible que antes
      ctx.lineWidth = 20; // Ancho de calle
      ctx.strokeStyle = '#333'; // Color gris oscuro para asfalto
      
      // Dibujar calles horizontales
      streets.horizontal.forEach(y => {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
        ctx.stroke();
      });
      
      // Dibujar calles verticales
      streets.vertical.forEach(x => {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
        ctx.stroke();
      });
      
      // Dibujar intersecciones
      ctx.globalAlpha = 0.2;
      ctx.fillStyle = '#555'; // Color más oscuro para intersecciones
      
      streets.horizontal.forEach(y => {
        streets.vertical.forEach(x => {
          ctx.beginPath();
          ctx.arc(x, y, 12, 0, Math.PI * 2);
          ctx.fill();
        });
      });
      
      // Dibujar líneas de separación en las calles
      ctx.globalAlpha = 0.3;
      ctx.strokeStyle = '#FFF'; // Líneas blancas
      ctx.lineWidth = 2;
      ctx.setLineDash([10, 10]); // Línea punteada
      
      // Líneas horizontales
      streets.horizontal.forEach(y => {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
        ctx.stroke();
      });
      
      // Líneas verticales
      streets.vertical.forEach(x => {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
        ctx.stroke();
      });
      
      ctx.setLineDash([]); // Restablecer estilo de línea
      ctx.restore();
    };
    
    // Dibujar los nodos y las líneas
    const drawNodes = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Dibujar las calles como fondo
      drawStreets();
      
      // Factor de aumento de opacidad para todos los elementos
      const opacityMultiplier = 2.0; // Aumentado a 2.0 para mayor visibilidad
      
      // Contador para depuración
      let connectionsDrawn = 0;
      let userToUserIgnored = 0;
      
      // Dibujar las líneas
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          // Verificar si ambos nodos son usuarios o ambos son transportistas, en ese caso no dibujar conexión
          const bothAreUsers = nodes[i].type === 'usuario' && nodes[j].type === 'usuario';
          const bothAreTransportistas = nodes[i].type === 'transportista' && nodes[j].type === 'transportista';
          
          // Si ambos son usuarios o ambos son transportistas, ignorar esta conexión
          if (bothAreUsers || bothAreTransportistas) {
            if (bothAreUsers) {
              userToUserIgnored++;
            }
            continue; // Saltar al siguiente par de nodos
          }
          
          // Solo dibujar líneas si están a una distancia adecuada
          if (distance < 150) { // Distancia máxima reducida a 150px
            connectionsDrawn++;
            
            // Calcular opacidad basada en la distancia (más cerca = más opaco)
            // Multiplicamos por el factor de opacidad para hacerlas más visibles
            const lineOpacity = (1 - (distance / 150)) * opacityMultiplier;
            
            // Asegurarnos de que la opacidad esté entre 0 y 1
            const clampedOpacity = Math.min(Math.max(lineOpacity, 0), 1);
            
            // Convertir a formato hexadecimal para el color
            const alphaHex = Math.floor(clampedOpacity * 255).toString(16).padStart(2, '0');
            
            // Determinar el color de la línea basado en los nodos que conecta
            let lineColor;
            
            // Si conecta transportista con usuario, usar un gradiente
            if (nodes[i].type !== nodes[j].type) {
              // Crear un gradiente para la línea
              const gradient = ctx.createLinearGradient(
                nodes[i].x, nodes[i].y, nodes[j].x, nodes[j].y
              );
              
              // Determinar qué nodo es transportista y cuál es usuario
              if (nodes[i].type === 'transportista') {
                gradient.addColorStop(0, `${TRANSPORTISTA_COLOR}${alphaHex}`);
                gradient.addColorStop(1, `${USUARIO_COLOR}${alphaHex}`);
              } else {
                gradient.addColorStop(0, `${USUARIO_COLOR}${alphaHex}`);
                gradient.addColorStop(1, `${TRANSPORTISTA_COLOR}${alphaHex}`);
              }
              
              lineColor = gradient;
            } else {
              // Si son del mismo tipo (solo ocurrirá con transportistas), usar el color correspondiente
              lineColor = `${nodes[i].color}${alphaHex}`;
            }
            
            ctx.beginPath();
            ctx.moveTo(nodes[i].x, nodes[i].y);
            ctx.lineTo(nodes[j].x, nodes[j].y);
            ctx.strokeStyle = lineColor;
            ctx.lineWidth = 1.5; // Aumentado a 1.5px para mayor visibilidad
            ctx.stroke();
          }
        }
      }
      
      // Log de depuración (solo lo mostramos una vez al inicio)
      if (window.networkLogShown !== true) {
        console.log('Network connections: ', {
          connectionsDrawn,
          userToUserIgnored,
          transportistaToTransportistaIgnored: nodes.filter(n => n.type === 'transportista').length * (nodes.filter(n => n.type === 'transportista').length - 1) / 2,
          totalNodes: nodes.length,
          usuarios: nodes.filter(n => n.type === 'usuario').length,
          transportistas: nodes.filter(n => n.type === 'transportista').length
        });
        window.networkLogShown = true;
      }
      
      // Dibujar los nodos como iconos
      for (let i = 0; i < nodes.length; i++) {
        ctx.save();
        ctx.globalAlpha = Math.min(1, opacityMultiplier * 1.2); // Aumentar opacidad pero no más de 1, ligeramente más visible
        
        // Seleccionar el icono según el tipo de vehículo o usuario
        let icon;
        switch(nodes[i].vehicleType) {
          case 'truck':
            icon = truckIcon;
            break;
          case 'van':
            icon = vanIcon;
            break;
          case 'delivery':
            icon = deliveryIcon;
            break;
          default:
            icon = userIcon;
        }
        
        const size = nodes[i].size;
        
        // Dibujar el icono centrado en la posición del nodo
        ctx.drawImage(icon, nodes[i].x - size/2, nodes[i].y - size/2, size, size);
        
        ctx.restore();
      }
    };
    
    // Actualizar la posición de los nodos
    const updateNodes = () => {
      const now = Date.now();
      
      for (let i = 0; i < nodes.length; i++) {
        // Solo actualizar posición si es transportista
        if (nodes[i].type === 'transportista') {
          // Guardar posición anterior
          const prevX = nodes[i].x;
          const prevY = nodes[i].y;
          
          // Actualizar posición
          nodes[i].x += nodes[i].vx;
          nodes[i].y += nodes[i].vy;
          
          // Verificar si el vehículo está cerca de una intersección
          if (now >= nodes[i].nextTurnAllowedAt) {
            const isNearIntersection = streets.horizontal.some(streetY => 
              Math.abs(nodes[i].y - streetY) < 10) && 
              streets.vertical.some(streetX => 
                Math.abs(nodes[i].x - streetX) < 10);
            
            // Si está cerca de una intersección, posiblemente cambiar de dirección
            if (isNearIntersection && Math.random() < nodes[i].turnProbability) {
              // Encontrar la intersección más cercana
              const intersection = findNearestIntersection(nodes[i].x, nodes[i].y);
              
              if (intersection) {
                // Ajustar posición exactamente a la intersección
                nodes[i].x = intersection.x;
                nodes[i].y = intersection.y;
                
                // Cambiar dirección (de horizontal a vertical o viceversa)
                if (nodes[i].street.isHorizontal) {
                  // Cambiar a movimiento vertical
                  nodes[i].street.isHorizontal = false;
                  nodes[i].street.position = intersection.x;
                  nodes[i].vx = 0;
                  nodes[i].vy = (Math.random() > 0.5 ? 1 : -1) * (0.5 + Math.random() * 1.0) * speed;
                } else {
                  // Cambiar a movimiento horizontal
                  nodes[i].street.isHorizontal = true;
                  nodes[i].street.position = intersection.y;
                  nodes[i].vx = (Math.random() > 0.5 ? 1 : -1) * (0.5 + Math.random() * 1.0) * speed;
                  nodes[i].vy = 0;
                }
                
                // Establecer tiempo mínimo antes de poder girar de nuevo (2-4 segundos)
                nodes[i].nextTurnAllowedAt = now + 2000 + Math.random() * 2000;
              }
            }
          }
          
          // Manejar el rebote en los bordes o salidas del mapa
          if (nodes[i].street.isHorizontal) {
            // Si está en calle horizontal, rebota en izquierda y derecha
            if (nodes[i].x < 0) {
              nodes[i].x = 0;
              nodes[i].vx = -nodes[i].vx;
            } else if (nodes[i].x > canvas.width) {
              nodes[i].x = canvas.width;
              nodes[i].vx = -nodes[i].vx;
            }
            
            // Corregir pequeñas desviaciones en Y para mantener el vehículo en la calle
            if (Math.abs(nodes[i].y - nodes[i].street.position) > 0.5) {
              nodes[i].y = nodes[i].street.position;
            }
          } else {
            // Si está en calle vertical, rebota arriba y abajo
            if (nodes[i].y < 0) {
              nodes[i].y = 0;
              nodes[i].vy = -nodes[i].vy;
            } else if (nodes[i].y > canvas.height) {
              nodes[i].y = canvas.height;
              nodes[i].vy = -nodes[i].vy;
            }
            
            // Corregir pequeñas desviaciones en X para mantener el vehículo en la calle
            if (Math.abs(nodes[i].x - nodes[i].street.position) > 0.5) {
              nodes[i].x = nodes[i].street.position;
            }
          }
        }
      }
    };
    
    // Función de animación
    const animate = () => {
      if (!isAnimating) return;
      
      updateNodes();
      drawNodes();
      animationFrameId = requestAnimationFrame(animate);
    };
    
    // Iniciar animación
    const startAnimation = () => {
      if (isAnimating) return;
      isAnimating = true;
      animate();
    };
    
    // Detener animación
    const stopAnimation = () => {
      isAnimating = false;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
    
    // Inicializar cuando las imágenes estén cargadas
    const initializeWhenReady = () => {
      let imagesLoaded = 0;
      const onImageLoad = () => {
        imagesLoaded++;
        if (imagesLoaded === 4) { // Cuatro imágenes cargadas (3 vehículos + usuario)
          createStreetGrid();
          handleResize();
          if (isVisible) {
            startAnimation();
          }
        }
      };
      
      truckIcon.onload = onImageLoad;
      vanIcon.onload = onImageLoad;
      deliveryIcon.onload = onImageLoad;
      userIcon.onload = onImageLoad;
    };
    
    initializeWhenReady();
    window.addEventListener('resize', handleResize);
    
    // Controlar animación basado en visibilidad
    if (isVisible) {
      startAnimation();
    } else {
      stopAnimation();
    }
    
    // Limpiar
    return () => {
      window.removeEventListener('resize', handleResize);
      stopAnimation();
    };
  }, [actualNodeCount, speed, isVisible, isMobile, TRANSPORTISTA_COLOR, USUARIO_COLOR]);
  
  // Consola para depuración
  console.log('NetworkBackground rendering with:', { 
    opacity, 
    nodeCount: actualNodeCount, 
    speed,
    isMobile,
    transportistaColor: TRANSPORTISTA_COLOR,
    usuarioColor: USUARIO_COLOR
  });
  
  return (
    <div ref={containerRef} className={className} style={{ position: 'relative', width: '100%', height: '100%' }}>
      <BackgroundCanvas 
        ref={canvasRef} 
        $opacity={opacity}
      />
    </div>
  );
};

export default NetworkBackground; 