import React from 'react';
import { FaEnvelope, FaExclamationTriangle, FaTrash, FaUserShield } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  position: relative;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const DeleteAccountContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  position: relative;
  z-index: 2;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.LARGE};
  text-align: center;
`;

const IntroText = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLACK_TEXT};
`;

const StepsContainer = styled.div`
  margin-top: ${SPACING.XLARGE};
  margin-bottom: ${SPACING.XLARGE};
`;

const StepTitle = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
`;

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.LARGE};
`;

const StepItem = styled.div`
  display: flex;
  gap: ${SPACING.MEDIUM};
  align-items: flex-start;
`;

const StepIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || COLORS.BLUE_LIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  svg {
    font-size: 24px;
    color: ${props => props.iconColor || COLORS.BLUE_MAIN};
  }
`;

const StepContent = styled.div`
  flex: 1;
`;

const StepHeading = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.SMALL};
`;

const StepDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
`;

const WarningBox = styled.div`
  background-color: ${COLORS.ORANGE_LIGHT};
  border-left: 4px solid ${COLORS.ORANGE_MAIN};
  padding: ${SPACING.MEDIUM};
  border-radius: 8px;
  margin-top: ${SPACING.LARGE};
  margin-bottom: ${SPACING.LARGE};
  display: flex;
  gap: ${SPACING.MEDIUM};
  align-items: flex-start;
`;

const WarningIconWrapper = styled.div`
  color: ${COLORS.ORANGE_MAIN};
  font-size: 24px;
  flex-shrink: 0;
`;

const WarningText = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin: 0;
`;

const ContactBox = styled.div`
  background-color: ${COLORS.BLUE_LIGHT};
  border-radius: 8px;
  padding: ${SPACING.MEDIUM};
  margin-top: ${SPACING.LARGE};
  text-align: center;
`;

const ContactText = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.SMALL};
`;

const EmailLink = styled.a`
  color: ${COLORS.BLUE_MAIN};
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const DeleteAccountPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Eliminar Cuenta" />
      
      <MainContent>
        <Container>
          <DeleteAccountContainer>
            <PageTitle>Elimina Tu Cuenta</PageTitle>
            
            <IntroText>
              En TrineoApp, respetamos su privacidad y valoramos la transparencia en el manejo de su información personal. Si en algún momento usted desea que eliminemos su información personal de nuestros registros, estamos dispuestos a atender su solicitud.
            </IntroText>
            
            <StepsContainer>
              <StepTitle>A continuación, se detallan los pasos a seguir para ejercer este derecho:</StepTitle>
              
              <StepsList>
                <StepItem>
                  <StepIconWrapper bgColor={`${COLORS.BLUE_LIGHT}`} iconColor={COLORS.BLUE_MAIN}>
                    <FaEnvelope />
                  </StepIconWrapper>
                  <StepContent>
                    <StepHeading>Solicitud de Eliminación</StepHeading>
                    <StepDescription>
                      Para solicitar la eliminación de su información personal, por favor, envíe un correo electrónico desde la dirección de correo electrónico asociada a su cuenta a <EmailLink href="mailto:registros@trineoapp.cl">registros@trineoapp.cl</EmailLink> con el asunto "Solicitud de Eliminación de Datos Personales".
                    </StepDescription>
                  </StepContent>
                </StepItem>
                
                <StepItem>
                  <StepIconWrapper bgColor={`${COLORS.GREEN_LIGHT}`} iconColor={COLORS.GREEN_MAIN}>
                    <FaUserShield />
                  </StepIconWrapper>
                  <StepContent>
                    <StepHeading>Información Necesaria</StepHeading>
                    <StepDescription>
                      En su correo electrónico, proporcione su nombre de usuario y cualquier otra información necesaria para identificar su cuenta y procesar su solicitud de manera precisa.
                    </StepDescription>
                  </StepContent>
                </StepItem>
                
                <StepItem>
                  <StepIconWrapper bgColor={`${COLORS.ORANGE_LIGHT}`} iconColor={COLORS.ORANGE_MAIN}>
                    <FaTrash />
                  </StepIconWrapper>
                  <StepContent>
                    <StepHeading>Procesamiento de la Solicitud</StepHeading>
                    <StepDescription>
                      Una vez recibida su solicitud, nuestro equipo procederá a procesarla. Tenga en cuenta que la eliminación de su información personal podría afectar su capacidad para utilizar nuestros servicios, ya que ciertos datos son esenciales para su interacción con la plataforma.
                    </StepDescription>
                  </StepContent>
                </StepItem>
                
                <StepItem>
                  <StepIconWrapper bgColor={`${COLORS.BLUE_LIGHT}`} iconColor={COLORS.BLUE_MAIN}>
                    <FaEnvelope />
                  </StepIconWrapper>
                  <StepContent>
                    <StepHeading>Notificación</StepHeading>
                    <StepDescription>
                      Le informaremos sobre la eliminación de su información personal una vez que se haya completado el proceso. Por favor, espere una confirmación de nuestra parte.
                    </StepDescription>
                  </StepContent>
                </StepItem>
              </StepsList>
            </StepsContainer>
            
            <WarningBox>
              <WarningIconWrapper>
                <FaExclamationTriangle />
              </WarningIconWrapper>
              <WarningText>
                Recuerde que la eliminación de su información personal de nuestros registros implica que ya no tendremos acceso a su historial de uso ni a su perfil de usuario. Esta acción no puede revertirse, por lo que le recomendamos considerar cuidadosamente su decisión antes de solicitar la eliminación de su información personal.
              </WarningText>
            </WarningBox>
            
            <IntroText>
              En TrineoApp, estamos comprometidos a garantizar que sus derechos de privacidad se respeten y protejan. Si tiene alguna pregunta o necesita más información sobre este proceso, no dude en ponerse en contacto con nuestro equipo de soporte. Estamos aquí para ayudar y responder a cualquier pregunta que pueda tener.
            </IntroText>
            
            <ContactBox>
              <ContactText>¿Necesitas ayuda con este proceso?</ContactText>
              <EmailLink href="mailto:contacto@trineoapp.cl">contacto@trineoapp.cl</EmailLink>
            </ContactBox>
          </DeleteAccountContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default DeleteAccountPage; 