import React from 'react';
import { FaClipboardCheck, FaClock, FaMobileAlt, FaShieldAlt, FaTruck, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import FeatureCard from '../FeatureCard/FeatureCard';
import Section from '../Section/Section';

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.MEDIUM};
  margin-top: ${SPACING.LARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    gap: ${SPACING.LARGE};
    margin-top: ${SPACING.XLARGE};
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: ${SPACING.SMALL};
  color: ${COLORS.BLUE_MAIN};
  font-size: calc(${FONTS.SIZE_HEADING_2} * 1.1);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin-bottom: ${SPACING.MEDIUM};
  }
`;

const SectionDescription = styled.p`
  text-align: center;
  max-width: 800px;
  margin: 0 auto ${SPACING.MEDIUM};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 0 auto ${SPACING.LARGE};
  }
`;

const Divider = styled.div`
  width: 80px;
  height: 4px;
  background-color: ${COLORS.SUCCESS};
  margin: 0 auto ${SPACING.MEDIUM};
  border-radius: 2px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 0 auto ${SPACING.LARGE};
  }
`;

const FeatureCategoryTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_3};
  text-align: center;
  margin: ${SPACING.LARGE} 0 ${SPACING.SMALL};
  color: ${COLORS.BLUE_SECONDARY};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: ${SPACING.XLARGE} 0 ${SPACING.MEDIUM};
  }
  
  &:first-of-type {
    margin-top: 0;
  }
`;

const OmniChannelSection = styled.div`
  padding: ${SPACING.MEDIUM};
  border-radius: ${SPACING.MEDIUM};
  margin-top: ${SPACING.LARGE};
  margin-bottom: ${SPACING.LARGE};
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(2px);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.LARGE};
    margin-top: ${SPACING.XLARGE};
    margin-bottom: ${SPACING.XLARGE};
  }
`;

const ChannelComparison = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.MEDIUM};
  margin-top: ${SPACING.MEDIUM};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${SPACING.LARGE};
    margin-top: ${SPACING.LARGE};
  }
`;

const ChannelCard = styled.div`
  background-color: ${COLORS.WHITE}DD;
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.LARGE};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(5px);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const ChannelTitle = styled.h4`
  display: flex;
  align-items: center;
  font-size: ${FONTS.SIZE_HEADING_3};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${({ color }) => color || COLORS.BLUE_MAIN};
`;

const ChannelIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${SPACING.SMALL};
  font-size: 24px;
`;

const ChannelFeatureList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ChannelFeatureItem = styled.li`
  margin-bottom: ${SPACING.SMALL};
  padding-left: ${SPACING.MEDIUM};
  position: relative;
  
  &:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: ${COLORS.SUCCESS};
    font-weight: bold;
  }
`;

// Contenedor adicional para ajustar el espaciado superior en móviles
const FeaturesSectionWrapper = styled.div`
  // Reducir el margen superior en dispositivos móviles
  margin-top: -30px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin-top: 0; // Restaurar margen normal en tablets y escritorio
  }
`;

const FeaturesSection = () => {
  const features = [
    {
      icon: <FaTruck />,
      title: 'Agilidad y Velocidad',
      description: 'Desde la solicitud hasta la ejecución del flete, el proceso está diseñado para ser lo más rápido y simple posible.'
    },
    {
      icon: <FaClipboardCheck />,
      title: 'Eficiencia Operativa',
      description: 'Garantizamos un control riguroso y transparente de cada operación con nuestra robusta estructura tecnológica.'
    },
    {
      icon: <FaShieldAlt />,
      title: 'Seguridad Garantizada',
      description: 'Todos los transportes son monitoreados en tiempo real, asegurando la integridad de tu carga en todo momento.'
    },
    {
      icon: <FaClock />,
      title: 'Ahorro de Tiempo',
      description: 'Optimizamos cada etapa del proceso para reducir tiempos de espera y mejorar la eficiencia logística.'
    },
    {
      icon: <FaMobileAlt />,
      title: 'Experiencia Cross-Device',
      description: 'Accede a nuestros servicios desde cualquier dispositivo, manteniendo siempre la misma calidad de experiencia.'
    },
    {
      icon: <FaWhatsapp />,
      title: 'Soporte Inmediato',
      description: 'Atención personalizada en tiempo real para resolver cualquier duda o requerimiento específico de tu flete.'
    }
  ];

  return (
    <FeaturesSectionWrapper>
      <Section id="caracteristicas">
        <SectionTitle>¿Por qué elegir Trineo?</SectionTitle>
        <Divider />
        <SectionDescription>
          Trineo ofrece una solución integral para la gestión de fletes, conectando de forma eficiente a todos los actores involucrados en el proceso logístico.
        </SectionDescription>
        
        <FeatureCategoryTitle>Beneficios principales</FeatureCategoryTitle>
        <FeaturesGrid>
          {features.slice(0, 3).map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </FeaturesGrid>
        
        <OmniChannelSection>
          <FeatureCategoryTitle>Experiencia Omnicanal</FeatureCategoryTitle>
          <SectionDescription>
            En Trineo entendemos que cada cliente tiene necesidades diferentes. Por eso, ofrecemos dos formas complementarias de solicitar tus fletes.
          </SectionDescription>
          
          <ChannelComparison>
            <ChannelCard>
              <ChannelTitle color={COLORS.BLUE_MAIN}>
                <ChannelIcon><FaMobileAlt /></ChannelIcon>
                App Móvil
              </ChannelTitle>
              <ChannelFeatureList>
                <ChannelFeatureItem>Seguimiento en tiempo real de tus fletes</ChannelFeatureItem>
                <ChannelFeatureItem>Historial completo de servicios</ChannelFeatureItem>
                <ChannelFeatureItem>Gestión de múltiples solicitudes</ChannelFeatureItem>
                <ChannelFeatureItem>Notificaciones automáticas</ChannelFeatureItem>
                <ChannelFeatureItem>Calificación de transportistas</ChannelFeatureItem>
              </ChannelFeatureList>
            </ChannelCard>
            
            <ChannelCard>
              <ChannelTitle color="#4CAF50">
                <ChannelIcon><FaWhatsapp /></ChannelIcon>
                WhatsApp
              </ChannelTitle>
              <ChannelFeatureList>
                <ChannelFeatureItem>Solicitudes rápidas sin descargar apps</ChannelFeatureItem>
                <ChannelFeatureItem>Atención personalizada inmediata</ChannelFeatureItem>
                <ChannelFeatureItem>Envío de ubicación en tiempo real</ChannelFeatureItem>
                <ChannelFeatureItem>Coordinación directa con operadores</ChannelFeatureItem>
                <ChannelFeatureItem>Soluciones a medida para casos especiales</ChannelFeatureItem>
              </ChannelFeatureList>
            </ChannelCard>
          </ChannelComparison>
        </OmniChannelSection>
        
        <FeatureCategoryTitle>Más razones para elegirnos</FeatureCategoryTitle>
        <FeaturesGrid>
          {features.slice(3).map((feature, index) => (
            <FeatureCard
              key={index + 3}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </FeaturesGrid>
      </Section>
    </FeaturesSectionWrapper>
  );
};

export default FeaturesSection; 