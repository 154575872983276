import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const FaqContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  position: relative;
  z-index: 2;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.XLARGE};
  text-align: center;
`;

const FaqItem = styled.div`
  margin-bottom: ${SPACING.MEDIUM};
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  padding-bottom: ${SPACING.MEDIUM};
  
  &:last-child {
    border-bottom: none;
  }
`;

const FaqQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${SPACING.SMALL} 0;
  
  &:hover h3 {
    color: ${COLORS.BLUE_SECONDARY};
  }
`;

const QuestionText = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLUE_MAIN};
  transition: color 0.2s ease;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${COLORS.BLUE_MAIN};
`;

const FaqAnswer = styled.div`
  padding: ${props => props.isOpen ? `${SPACING.MEDIUM} 0` : '0'};
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: ${props => props.isOpen ? '1' : '0'};
`;

const AnswerText = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
`;

// Datos de las preguntas frecuentes
const faqData = [
  {
    question: "¿Cómo puedo descargar la aplicación Trineo?",
    answer: "Puedes descargar la aplicación Trineo desde la App Store para dispositivos iOS o desde la Google Play Store para dispositivos Android."
  },
  {
    question: "¿Cómo puedo registrarme como conductor en Trineo?",
    answer: "Para registrarte como conductor en Trineo, descarga la aplicación y sigue las instrucciones de registro. Deberás proporcionar información personal y sobre tu vehículo, así como pasar por un proceso de verificación."
  },
  {
    question: "¿Cómo puedo solicitar un envío con Trineo?",
    answer: "Para solicitar un envío con Trineo, abre la aplicación y selecciona la categoría de carga que necesitas transportar. Luego, ingresa los detalles del envío, como la ubicación de recogida y entrega, y la hora y fecha deseada para el envío. Una vez que hayas confirmado los detalles, podrás ver una lista de conductores disponibles y seleccionar uno para que realice el envío."
  },
  {
    question: "¿Cómo puedo pagar por un envío en Trineo?",
    answer: "Trineo acepta tarjetas de crédito y débito como métodos de pago para los envíos."
  },
  {
    question: "¿Cómo puedo seguir el estado de mi envío?",
    answer: "Una vez que hayas solicitado un envío, podrás seguir su estado en tiempo real a través de la aplicación. Se te notificará cuando el conductor haya recogido la carga, cuando esté en ruta y cuando haya llegado a su destino."
  },
  {
    question: "¿Cómo puedo cancelar un envío en Trineo?",
    answer: "Puedes cancelar un envío en cualquier momento antes de que el conductor haya llegado a la ubicación de recogida. Para cancelar un envío, selecciona el envío en la sección \"Envíos\" de la aplicación y luego elige la opción \"Cancelar\"."
  },
  {
    question: "¿Cuál es la comisión que se cobra a conductores y usuarios?",
    answer: "Tanto a los conductores como a los usuarios se les cobra una comisión del 10% por el uso de la plataforma TrineoApp. Esta comisión se deduce automáticamente del pago por los servicios de transporte de carga o envío contratados."
  },
  {
    question: "¿Qué medidas de seguridad se toman para proteger mis datos personales?",
    answer: "En TrineoApp, tomamos la seguridad de tus datos personales muy en serio. Utilizamos medidas de seguridad avanzadas para proteger la información de nuestros usuarios y conductores. Puedes consultar nuestra política de privacidad para obtener más detalles sobre cómo manejamos tus datos."
  },
  {
    question: "¿Qué debo hacer si tengo un problema con un envío o conductor?",
    answer: "Si experimentas algún problema con un envío o conductor, puedes contactarnos a través de la sección de Soporte en la aplicación. Nuestro equipo estará encantado de ayudarte a resolver cualquier problema que puedas tener."
  },
  {
    question: "¿Qué sucede si necesito cancelar un envío después de que el conductor haya llegado a la ubicación de recogida?",
    answer: "Si necesitas cancelar un envío después de que el conductor haya llegado a la ubicación de recogida, te recomendamos comunicarte directamente con el conductor a través de la aplicación. La política de cancelación y posibles cargos dependerán de la situación y del acuerdo entre ambas partes."
  },
  {
    question: "¿Cuánto tiempo tengo para aceptar una solicitud de envío como conductor?",
    answer: "Como conductor, tendrás un tiempo determinado para aceptar o rechazar una solicitud de envío. Este plazo puede variar y se mostrará en la notificación de la solicitud. Si no respondes dentro del tiempo establecido, la solicitud se considerará automáticamente rechazada."
  }
];

const FaqPage = () => {
  // Estado para controlar qué preguntas están abiertas
  const [openItems, setOpenItems] = useState({});

  // Función para alternar el estado de apertura de una pregunta
  const toggleItem = (index) => {
    setOpenItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Preguntas Frecuentes" />
      
      <MainContent>
        <Container>
          <FaqContainer>
            <PageTitle>Preguntas Frecuentes</PageTitle>
            
            {faqData.map((faq, index) => (
              <FaqItem key={index}>
                <FaqQuestion onClick={() => toggleItem(index)}>
                  <QuestionText>{faq.question}</QuestionText>
                  <IconWrapper>
                    {openItems[index] ? <FaChevronUp /> : <FaChevronDown />}
                  </IconWrapper>
                </FaqQuestion>
                <FaqAnswer isOpen={openItems[index]}>
                  <AnswerText>{faq.answer}</AnswerText>
                </FaqAnswer>
              </FaqItem>
            ))}
          </FaqContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default FaqPage; 