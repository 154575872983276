import React, { useState } from 'react';
import { FaAndroid, FaApple, FaComments, FaDownload, FaTimes, FaWhatsapp } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Animación de rebote para el botón principal
const pulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); }
  50% { transform: scale(1.05); box-shadow: 0 6px 14px rgba(0, 0, 0, 0.3); }
  100% { transform: scale(1); box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); }
`;

// Animación para las opciones
const slideIn = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

// Contenedor principal que se posiciona en la esquina inferior derecha
const FloatingContainer = styled.div`
  position: fixed;
  bottom: ${SPACING.LARGE};
  right: ${SPACING.LARGE};
  z-index: 99; /* Por debajo del menú móvil (z-index: 1001) */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

// Botón principal que muestra/oculta las opciones
const MainButton = styled.button`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: linear-gradient(135deg, ${COLORS.BLUE_MAIN}, ${COLORS.BLUE_SECONDARY});
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: ${pulse} 2s infinite ease-in-out;
  animation-play-state: ${({ $isOpen }) => ($isOpen ? 'paused' : 'running')};
  
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  &:focus {
    outline: none;
  }
`;

// Contenedor para las opciones que aparecen al hacer clic
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING.MEDIUM};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transform: ${({ $isOpen }) => ($isOpen ? 'translateY(0)' : 'translateY(20px)')};
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

// Estilo para cada opción individual
const Option = styled.a`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 30px;
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  margin-bottom: ${SPACING.SMALL};
  text-decoration: none;
  color: ${COLORS.BLACK_TEXT};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: ${slideIn} 0.3s forwards;
  animation-delay: ${({ $index }) => `${$index * 0.1}s`};
  opacity: 0;
  
  &:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.XSMALL} ${SPACING.SMALL};
  }
`;

// Icono dentro de cada opción
const OptionIcon = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(135deg, ${props => props.$bgColor}, ${props => props.$bgColorSecondary || props.$bgColor});
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING.SMALL};
  font-size: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
`;

// Texto de cada opción
const OptionText = styled.div`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const FloatingButton = () => {
  // Estado para controlar si las opciones están visibles
  const [isOpen, setIsOpen] = useState(false);
  
  // Función para alternar la visibilidad de las opciones
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  
  // Función para abrir WhatsApp
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };
  
  // Función para descargar la app según el dispositivo
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };
  
  // Determinar el icono y texto según el dispositivo
  const getAppDownloadInfo = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      return {
        icon: <FaAndroid />,
        text: 'Descargar en Android'
      };
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return {
        icon: <FaApple />,
        text: 'Descargar en iOS'
      };
    } else {
      return {
        icon: <FaDownload />,
        text: 'Descargar App'
      };
    }
  };
  
  const appInfo = getAppDownloadInfo();

  return (
    <FloatingContainer>
      <OptionsContainer $isOpen={isOpen}>
        <Option href="#" onClick={(e) => { e.preventDefault(); downloadApp(); }} $index={0}>
          <OptionIcon $bgColor={COLORS.BLUE_MAIN} $bgColorSecondary={COLORS.BLUE_SECONDARY}>
            {appInfo.icon}
          </OptionIcon>
          <OptionText>{appInfo.text}</OptionText>
        </Option>
        <Option href="#" onClick={(e) => { e.preventDefault(); openWhatsApp(); }} $index={1}>
          <OptionIcon $bgColor="#25D366" $bgColorSecondary="#128C7E">
            <FaWhatsapp />
          </OptionIcon>
          <OptionText>Contactar por WhatsApp</OptionText>
        </Option>
      </OptionsContainer>
      <MainButton onClick={toggleOptions} $isOpen={isOpen}>
        {isOpen ? <FaTimes /> : <FaComments />}
      </MainButton>
    </FloatingContainer>
  );
};

export default FloatingButton; 