import React from 'react';
import styled from 'styled-components';
import { BORDER_RADIUS, COLORS, FONTS, SHADOWS, SPACING } from '../../styles/variables';

const CardWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.LARGE};
  padding: ${SPACING.LARGE};
  box-shadow: ${SHADOWS.MEDIUM};
  position: relative;
  margin-top: ${SPACING.XLARGE};
`;

const QuoteIcon = styled.div`
  position: absolute;
  top: -20px;
  left: ${SPACING.MEDIUM};
  font-size: 40px;
  color: ${COLORS.BLUE_MAIN};
  opacity: 0.2;
`;

const Content = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.MEDIUM};
  font-style: italic;
  position: relative;
  z-index: 1;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${COLORS.GREY_LIGHT};
  background-image: ${({ src }) => src ? `url(${src})` : 'none'};
  background-size: cover;
  background-position: center;
  margin-right: ${SPACING.SMALL};
  border: 2px solid ${COLORS.WHITE};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorName = styled.span`
  font-size: ${FONTS.SIZE_SECONDARY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLACK_TEXT};
`;

const AuthorRole = styled.span`
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLACK_TEXT}99;
`;

const TestimonialCard = ({ 
  content, 
  authorName, 
  authorRole, 
  authorImage,
  ...props 
}) => {
  return (
    <CardWrapper {...props}>
      <QuoteIcon>"</QuoteIcon>
      <Content>{content}</Content>
      <AuthorContainer>
        <AuthorImage src={authorImage} />
        <AuthorInfo>
          <AuthorName>{authorName}</AuthorName>
          <AuthorRole>{authorRole}</AuthorRole>
        </AuthorInfo>
      </AuthorContainer>
    </CardWrapper>
  );
};

export default TestimonialCard; 