import React from 'react';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  position: relative;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const PolicyContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: ${SPACING.XLARGE};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLUE_MAIN};
`;

const LastUpdated = styled.p`
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLACK_TEXT}99;
  margin-bottom: ${SPACING.LARGE};
`;

const Section = styled.div`
  margin-bottom: ${SPACING.XLARGE};
`;

const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_SECONDARY};
`;

const Paragraph = styled.p`
  margin-bottom: ${SPACING.MEDIUM};
  line-height: 1.7;
`;

const List = styled.ul`
  margin-bottom: ${SPACING.MEDIUM};
  padding-left: ${SPACING.LARGE};
`;

const ListItem = styled.li`
  margin-bottom: ${SPACING.SMALL};
  line-height: 1.7;
`;

const CookiesPage = () => {
  return (
    <PageWrapper>
      <NetworkBackground />
      
      <SubpageHeader title="Política de Cookies" />
      
      <Content>
        <Container>
          <PolicyContainer>
            <Title>Política de Cookies</Title>
            <LastUpdated>Última actualización: {new Date().toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })}</LastUpdated>
            
            <Section>
              <SectionTitle>1. ¿Qué son las cookies?</SectionTitle>
              <Paragraph>
                Las cookies son pequeños archivos de texto que se almacenan en su dispositivo (ordenador, tablet o móvil) cuando visita nuestra página web. Estas cookies nos ayudan a hacer que nuestra web funcione correctamente, hacerla más segura, proporcionar una mejor experiencia de usuario y entender cómo se utiliza nuestra web para mejorarla.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>2. Tipos de cookies que utilizamos</SectionTitle>
              <Paragraph>
                En Trineo utilizamos los siguientes tipos de cookies:
              </Paragraph>
              <List>
                <ListItem>
                  <strong>Cookies esenciales:</strong> Son necesarias para el funcionamiento básico de nuestra web. Permiten navegar por la página y utilizar funciones esenciales como áreas seguras, carrito de compras o facturación electrónica.
                </ListItem>
                <ListItem>
                  <strong>Cookies de preferencias:</strong> Estas cookies permiten a nuestra web recordar información que cambia el aspecto o el comportamiento de la página, como tu idioma preferido o la región en la que te encuentras.
                </ListItem>
                <ListItem>
                  <strong>Cookies estadísticas:</strong> Nos ayudan a entender cómo los visitantes interactúan con nuestra web, recopilando y reportando información de forma anónima. Nos permiten mejorar la forma en que funciona nuestra web.
                </ListItem>
                <ListItem>
                  <strong>Cookies de marketing:</strong> Se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual.
                </ListItem>
              </List>
            </Section>
            
            <Section>
              <SectionTitle>3. Cookies de terceros</SectionTitle>
              <Paragraph>
                Además de nuestras propias cookies, también utilizamos varias cookies de terceros para reportar estadísticas de uso, entregar anuncios y personalizar tu experiencia. Estas incluyen cookies de servicios como Google Analytics, Facebook Pixel y otras herramientas de análisis y marketing.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>4. Control de cookies</SectionTitle>
              <Paragraph>
                Puedes controlar y/o eliminar las cookies según desees. Puedes eliminar todas las cookies que ya están en tu dispositivo y puedes configurar la mayoría de los navegadores para que no las acepten. Si lo haces, es posible que tengas que establecer manualmente algunas preferencias cada vez que visites el sitio y que algunos servicios y funcionalidades no funcionen.
              </Paragraph>
              <Paragraph>
                Puedes configurar tu navegador para que no acepte cookies, y la siguiente página web te indica cómo eliminar las cookies de tu navegador. Sin embargo, si desactivas las cookies, ten en cuenta que algunas funciones de nuestra web pueden no funcionar correctamente.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>5. Más información</SectionTitle>
              <Paragraph>
                Para más información sobre cómo utilizamos las cookies, puedes contactarnos a través de:
              </Paragraph>
              <List>
                <ListItem>Email: contacto@trineoapp.cl</ListItem>
                <ListItem>Teléfono: +56 9 7720 9949</ListItem>
              </List>
            </Section>
            
            <Section>
              <SectionTitle>6. Cambios en nuestra política de cookies</SectionTitle>
              <Paragraph>
                Cualquier cambio que podamos hacer en nuestra política de cookies en el futuro se publicará en esta página y, cuando corresponda, se te notificará por correo electrónico. Te recomendamos que visites frecuentemente esta página para ver cualquier actualización o cambio en nuestra política de cookies.
              </Paragraph>
            </Section>
          </PolicyContainer>
        </Container>
      </Content>
      
      <Footer />
    </PageWrapper>
  );
};

export default CookiesPage; 