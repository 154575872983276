import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaMobileAlt, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import Section from '../Section/Section';
import TestimonialCard from '../TestimonialCard/TestimonialCard';

// Importar imágenes de usuarios
import anaImage from '../../assets/images/u-ana.png';
import carlosImage from '../../assets/images/u-carlos.png';
import miguelImage from '../../assets/images/u-miguel.png';

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.LARGE};
  margin-top: ${SPACING.LARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

// Contenedor para el carrusel en móviles
const MobileCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING.LARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none; // Ocultar en tablets y escritorio
  }
`;

// Contenedor para los testimonios en grid (tablets y escritorio)
const DesktopGridContainer = styled.div`
  display: none; // Ocultar en móviles
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: block; // Mostrar en tablets y escritorio
  }
`;

// Controles de navegación del carrusel
const CarouselControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${SPACING.MEDIUM};
  gap: ${SPACING.MEDIUM};
`;

const CarouselButton = styled.button`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BLUE_MAIN}33;
  color: ${COLORS.BLUE_MAIN};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${COLORS.BLUE_MAIN}11;
    transform: translateX(${props => props.direction === 'prev' ? '-3px' : '3px'});
  }
  
  &:active {
    background-color: ${COLORS.BLUE_MAIN}22;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING.XSMALL};
  margin-top: ${SPACING.SMALL};
`;

const CarouselIndicator = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? COLORS.BLUE_MAIN : COLORS.GREY_LIGHT};
  transition: background-color 0.2s ease, transform 0.2s ease;
  border: none;
  padding: 0;
  margin: 0 4px;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.2);
    background-color: ${props => props.active ? COLORS.BLUE_MAIN : COLORS.BLUE_MAIN + '88'};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${COLORS.BLUE_MAIN}33;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_MAIN};
  font-size: calc(${FONTS.SIZE_HEADING_2} * 1.1);
`;

const SectionDescription = styled.p`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const Divider = styled.div`
  width: 80px;
  height: 4px;
  background-color: ${COLORS.SUCCESS};
  margin: 0 auto ${SPACING.LARGE};
  border-radius: 2px;
`;

const ChannelTag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${SPACING.XXSMALL} ${SPACING.SMALL};
  border-radius: 50px;
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.SMALL};
  
  ${({ channel }) => channel === 'app' && `
    background-color: ${COLORS.BLUE_MAIN}22;
    color: ${COLORS.BLUE_MAIN};
  `}
  
  ${({ channel }) => channel === 'whatsapp' && `
    background-color: #4CAF5022;
    color: #4CAF50;
  `}
`;

const TagIcon = styled.span`
  margin-right: ${SPACING.XSMALL};
  display: flex;
  align-items: center;
`;

const TestimonialsSection = () => {
  // Estado para el carrusel
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const testimonials = [
    {
      content: "Trineo ha revolucionado nuestra logística. Ahora podemos coordinar fletes en minutos, lo que antes nos tomaba horas. La aplicación móvil es intuitiva y nos permite seguir cada entrega en tiempo real.",
      authorName: "Carlos Rodríguez",
      authorRole: "Gerente de Logística, Distribuidora Nacional",
      authorImage: carlosImage,
      channel: "app"
    },
    {
      content: "Como transportista independiente, utilizo WhatsApp para recibir solicitudes de fletes a través de Trineo. El proceso es directo y me ha permitido aumentar mis ingresos considerablemente.",
      authorName: "Ana Martínez",
      authorRole: "Transportista Independiente",
      authorImage: anaImage,
      channel: "whatsapp"
    },
    {
      content: "La opción de solicitar fletes por WhatsApp nos salvó cuando necesitábamos un transporte urgente y no teníamos acceso a la app. La respuesta fue inmediata y el servicio excelente.",
      authorName: "Miguel Sánchez",
      authorRole: "Director de Operaciones, ImportExport S.A.",
      authorImage: miguelImage,
      channel: "whatsapp"
    },
    {
      content: "Utilizamos la app diariamente para programar nuestros envíos. Las funciones de seguimiento y los reportes detallados han optimizado toda nuestra cadena logística.",
      authorName: "Laura Gómez",
      authorRole: "Coordinadora de Envíos, Retail Nacional",
      authorImage: "",
      channel: "app"
    },
    {
      content: "La flexibilidad de poder usar tanto la app como WhatsApp hace que Trineo sea ideal para nuestra empresa. Dependiendo de la urgencia y tipo de flete, elegimos el canal más conveniente.",
      authorName: "Roberto Pérez",
      authorRole: "Empresario PyME",
      authorImage: "",
      channel: "both"
    }
  ];

  // Mostrar solo los testimonios que tienen imágenes
  const displayedTestimonials = testimonials.filter(testimonial => testimonial.authorImage).slice(0, 3);
  
  // Funciones para navegar por el carrusel
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === displayedTestimonials.length - 1 ? 0 : prev + 1));
  };
  
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? displayedTestimonials.length - 1 : prev - 1));
  };
  
  // Auto-rotación del carrusel cada 5 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    
    return () => clearInterval(interval);
  }, []);

  // Función para renderizar un testimonio
  const renderTestimonial = (testimonial, index) => (
    <TestimonialCard
      key={index}
      content={
        <>
          {testimonial.channel === 'app' && (
            <ChannelTag channel="app">
              <TagIcon><FaMobileAlt /></TagIcon>
              Usuario de App
            </ChannelTag>
          )}
          {testimonial.channel === 'whatsapp' && (
            <ChannelTag channel="whatsapp">
              <TagIcon><FaWhatsapp /></TagIcon>
              Usuario de WhatsApp
            </ChannelTag>
          )}
          {testimonial.channel === 'both' && (
            <ChannelTag channel="app">
              <TagIcon><FaMobileAlt /></TagIcon>
              <TagIcon><FaWhatsapp /></TagIcon>
              Ambos canales
            </ChannelTag>
          )}
          <br />
          {testimonial.content}
        </>
      }
      authorName={testimonial.authorName}
      authorRole={testimonial.authorRole}
      authorImage={testimonial.authorImage}
    />
  );

  return (
    <Section id="testimonios">
      <SectionTitle>Experiencias con Trineo</SectionTitle>
      <Divider />
      <SectionDescription>
        Clientes y transportistas comparten cómo Trineo ha transformado su logística.
      </SectionDescription>
      
      {/* Carrusel para móviles */}
      <MobileCarouselContainer>
        {renderTestimonial(displayedTestimonials[currentSlide], currentSlide)}
        
        <CarouselControls>
          <CarouselButton onClick={prevSlide} direction="prev" aria-label="Testimonio anterior">
            <FaArrowLeft />
          </CarouselButton>
          <CarouselButton onClick={nextSlide} direction="next" aria-label="Testimonio siguiente">
            <FaArrowRight />
          </CarouselButton>
        </CarouselControls>
        
        <CarouselIndicators>
          {displayedTestimonials.map((_, index) => (
            <CarouselIndicator 
              key={index} 
              active={index === currentSlide} 
              onClick={() => setCurrentSlide(index)}
              aria-label={`Ir al testimonio ${index + 1}`}
            />
          ))}
        </CarouselIndicators>
      </MobileCarouselContainer>
      
      {/* Grid para tablets y escritorio */}
      <DesktopGridContainer>
        <TestimonialsGrid>
          {displayedTestimonials.map((testimonial, index) => (
            renderTestimonial(testimonial, index)
          ))}
        </TestimonialsGrid>
      </DesktopGridContainer>
    </Section>
  );
};

export default TestimonialsSection; 