import React from 'react';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const TermsContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  position: relative;
  z-index: 2;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.LARGE};
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-top: ${SPACING.XLARGE};
  margin-bottom: ${SPACING.MEDIUM};
`;

const SubSectionTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_SECONDARY};
  margin-top: ${SPACING.LARGE};
  margin-bottom: ${SPACING.SMALL};
`;

const Paragraph = styled.p`
  margin-bottom: ${SPACING.MEDIUM};
  line-height: 1.7;
`;

const List = styled.ul`
  margin-bottom: ${SPACING.MEDIUM};
  padding-left: ${SPACING.LARGE};
`;

const ListItem = styled.li`
  margin-bottom: ${SPACING.SMALL};
`;

const TermsPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Términos y Condiciones" />
      
      <MainContent>
        <Container>
          <TermsContainer>
            <PageTitle>Términos y Condiciones</PageTitle>
            
            {/* Contenido de los términos y condiciones */}
            <SectionTitle>MUSHER - CONDUCTOR</SectionTitle>
            
            <SubSectionTitle>Introducción</SubSectionTitle>
            <Paragraph>1.1. Estos términos y condiciones (en adelante, "T&C") constituyen un acuerdo legalmente vinculante entre usted (en adelante, "Conductor") y TrineoApp (en adelante, "la Empresa"), una empresa prestadora de servicios informáticos que ofrece una plataforma tecnológica para facilitar los servicios de transportes de cargas y conectar a los Conductores con Usuarios.</Paragraph>
            <Paragraph>1.2. Al completar cualquiera de nuestros registros, por cualquier medio, confirmo que he leído, comprendido y aceptado los Términos y Condiciones de uso de TrineoApp. Además, entiendo que, al registrarme, me comprometo a cumplir con todas las políticas, reglas y regulaciones establecidas por TrineoApp. También reconozco que la información proporcionada durante el registro es precisa y completa. Al aceptar estos términos, estoy listo para acceder a los servicios de TrineoApp y aprovechar todas las oportunidades que ofrece esta plataforma. Acepto la responsabilidad de cumplir con las obligaciones y normas establecidas en los Términos y Condiciones, y estoy emocionado de ser parte de la comunidad de TrineoApp."</Paragraph>
            <Paragraph>TrineoApp actúa como intermediario para juntar a una persona o empresa que tenga la necesidad de trasladar o enviar un electrodoméstico, artículo, producto, elemento, herramientas, equipos, materiales, etc. Y una persona con un vehículo con la capacidad de carga para el objeto a mover de una dirección o lugar a otro. Además de ser el intermediario para la transacción de usuario y conductor. Esto significa que los pagos realizados por los usuarios se procesarán a través de nuestra plataforma, lo que garantiza la seguridad y la integridad de las transacciones.</Paragraph>
            
            <SubSectionTitle>Uso de la Plataforma, Registro y Cuenta</SubSectionTitle>
            <Paragraph>2.1. El Conductor debe ser mayor de edad, y completar el proceso de registro en la plataforma de TrineoApp para poder utilizar los servicios y ofrecer servicios de transporte de carga. Para ello, el Conductor deberá seguir los siguientes pasos:</Paragraph>
            <List>
              <ListItem>(a) Proporcionar información personal exacta y completa, incluyendo nombre, dirección, número de teléfono y dirección de correo electrónico.</ListItem>
              <ListItem>(b) Cargar y proporcionar todos los documentos personales solicitados por la plataforma de TrineoApp, los cuales son, cedula de identidad (foto clara y legible por ambos lados), certificado de antecedentes y hoja de vida del conductor, y/o cualquier otro documento que requiera o sea requerido por la legislación aplicable.</ListItem>
              <ListItem>(c) Subir a la aplicación Trineo Musher todos los documentos del vehículo solicitados para ser validado por los integrantes de Trineo Spa. Dichos documentos serán licencia de conducir, cedula de identidad, revisión técnica, seguro obligatorio, permiso de circulación, certificado de inscripción (padrón), hoja de vida del conductor y certificado de antecedentes (estos documentos deben estar al día y ser legibles) todo esto en función del cumplimiento de las regulaciones y leyes aplicables.</ListItem>
              <ListItem>(d) En caso de que los documentos proporcionados no sean legibles o estén incompletos, el equipo de soporte de Trineo Spa se pondrá en contacto con el transportista para solicitar nuevamente los documentos necesarios. Esta medida es necesaria para asegurar la correcta validación de los datos del transportista y garantizar el cumplimiento de nuestras políticas y procedimientos.</ListItem>
              <ListItem>(e) Aceptar y cumplir con los Términos y Condiciones de TrineoApp y cualquier otro acuerdo aplicable.</ListItem>
              <ListItem>(f) Completar cualquier otro requisito de registro establecido por TrineoApp.</ListItem>
            </List>
            
            <Paragraph>2.2. El Conductor se compromete a utilizar la plataforma de acuerdo con las leyes y regulaciones aplicables y no utilizarla para actividades ilegales o inapropiadas incluyendo, pero no limitado a actividades que infrinjan derechos de autor, marcas registradas, patentes u otros derechos de propiedad intelectual, actividades que violen la privacidad de terceros, actividades que puedan dañar, interferir o perjudicar el funcionamiento de la plataforma o de otros usuarios.</Paragraph>
            
            <Paragraph>2.3. El Conductor se compromete a proporcionar información precisa y actualizada y a mantenerla de esa manera durante su uso continuo de los servicios de transporte de carga ofrecidos por TrineoApp. La falta de proporcionar información precisa y completa o de mantenerla actualizada puede resultar en la suspensión o cancelación de la cuenta del Conductor y la terminación de su acceso a la plataforma de TrineoApp.</Paragraph>
            
            <SubSectionTitle>Servicios de Transporte de Carga</SubSectionTitle>
            <Paragraph>3.1. TrineoApp facilita la plataforma que permite a los Conductores ofrecer servicios de transporte de carga a los Usuarios.</Paragraph>
            
            <Paragraph>3.2. El Conductor acepta que es responsable de la calidad, seguridad y confiabilidad de los servicios de transporte de carga que ofrece a través de la plataforma.</Paragraph>
            
            <Paragraph>3.3. El Conductor se compromete a cumplir con todas las leyes y regulaciones aplicables relacionadas con el transporte de carga.</Paragraph>
            
            <Paragraph>3.4. El registro en TrineoApp y la posterior validación por parte de la empresa, es una licencia que permite el uso de la plataforma de manera personal e intransferible a un tercero, esta licencia puede ser revocada por no cumplimiento a estos términos y condiciones.</Paragraph>
            
            <Paragraph>3.5. Los vehículos que se podrán registrar, pero no limitados a estos serán desde autos hasta camiones, los cuales serán clasificados de acuerdo con las necesidades que tenga TrineoApp sin superar su límite de carga establecido por su vehículo.</Paragraph>
            
            <Paragraph>3.6. En caso de encontrar algún objeto olvidado por el usuario o que sea parte de la carga que trasportaba, el Conductor se compromete a realizar las acciones necesarias para devolver el artículo u/o objeto al dueño de este, además, avisar instantáneamente a TrineoApp a través de contacto@trineoapp.cl</Paragraph>
            
            <SubSectionTitle>Pagos y Transferencias</SubSectionTitle>
            <Paragraph>TrineoApp actúa como intermediario financiero en estas transacciones. Esto significa que los pagos realizados por los usuarios se procesarán a través de nuestra plataforma, lo que garantiza la seguridad y la integridad de las transacciones.</Paragraph>
            
            <Paragraph>4.1. Una vez que se complete el servicio y se haya confirmado el monto total, TrineoApp retendrá los fondos pagados por el usuario. Estos fondos se utilizarán para cubrir el costo del servicio, con un tope máximo del 15% como parte de la colaboración con TrineoApp.</Paragraph>
            
            <Paragraph>4.2. El Conductor es responsable de cualquier impuesto aplicable a los ingresos obtenidos por los servicios de transporte de carga.</Paragraph>
            
            <Paragraph>4.3. Ciclo de Pagos y Transferencias: Los pagos a los Conductores se efectuarán semanalmente, todos los jueves, e incluirán todos los traslados realizados hasta el domingo anterior. La plataforma TrineoApp gestionará las transferencias de fondos a las cuentas bancarias proporcionadas por los Conductores dentro de un plazo de 1 día hábil a partir del día de pago. Los Conductores serán responsables de proporcionar la información bancaria precisa y actualizada necesaria para recibir los pagos de manera oportuna. Cualquier retraso o problema en las transferencias relacionados con información bancaria incorrecta o desactualizada no será responsabilidad de TrineoApp.</Paragraph>
            
            <Paragraph>4.4. Responsabilidad del Conductor: El Conductor es responsable de cumplir con el traslado aceptado de acuerdo con la descripción proporcionada en la plataforma. Si la carga no coincide en tamaño y/o cantidad con la descripción proporcionada, el Conductor deberá informar a la Empresa de manera inmediata y colaborar en la resolución de la discrepancia.</Paragraph>
            
            <Paragraph>4.5. Naturaleza de la Colaboración: TrineoApp es una empresa de servicios informáticos que pone su plataforma a disposición del Conductor para acceder a más usuarios y brindar servicios de transporte de carga. El Conductor, como usuario de la plataforma, contrata a TrineoApp para acercarlo al cliente final, y no se considera empleado de la Empresa.</Paragraph>
            
            <Paragraph>4.6 El conductor deberá enviar por correo electrónico los siguientes datos para procesar los pagos: nombre completo, RUN, número de cuenta bancaria, tipo de cuenta y el nombre del banco correspondiente. Es fundamental destacar que es su responsabilidad proporcionar datos precisos, ya que, de lo contrario, no podremos efectuar el pago.</Paragraph>
            
            <SubSectionTitle>Terminación de la Cuenta</SubSectionTitle>
            <Paragraph>5.1. TrineoApp se reserva el derecho de cancelar cualquier suscripción y/o cuenta en caso de un uso inapropiado o el incumplimiento de estos Términos y Condiciones. Estos términos y condiciones son fundamentales para la utilización adecuada de la plataforma, la cual está a disposición de los Conductores. TrineoApp se reserva el derecho de tomar medidas, como son: el cobro del 20% del monto total del viaje cancelado, reteniéndolo en su próximo traslado, sin limitar el monto total que se afecte, o la cancelación de la suscripción y cuenta, en respuesta a conductas que violen estas políticas o que puedan perjudicar la integridad y la calidad del servicio de la plataforma."</Paragraph>
            
            <SubSectionTitle>Modificaciones a los Términos y Condiciones</SubSectionTitle>
            <Paragraph>6.1. TrineoApp se reserva el derecho de modificar estos T&C en cualquier momento. Cualquier modificación será notificada al transportista en el momento de realizar la acción correspondiente, ya sea al iniciar sesión en la aplicación o al realizar cualquier otra actividad que requiera la aceptación de estos términos. El transportista tendrá la opción de aceptar o rechazar los términos modificados. La utilización continua de los servicios ofrecidos por TrineoApp después de la notificación y presentación de los nuevos términos constituirá la aceptación de los mismos por parte del transportista.</Paragraph>
            
            <Paragraph>6.2. El Conductor acepta que la continuación del uso de la plataforma después de cualquier modificación de los T&C constituirá su aceptación de estos.</Paragraph>
            
            <SubSectionTitle>Contacto</SubSectionTitle>
            <Paragraph>7.1. Si el Conductor tiene preguntas sobre estos T&C o cualquier otro aspecto de la plataforma, puede ponerse en contacto con TrineoApp en contacto@trineoapp.cl.</Paragraph>
            
            <SubSectionTitle>La Evaluación y Aceptación de Conductores</SubSectionTitle>
            <Paragraph>8.1. TrineoApp se reserva el derecho de validar la documentación proporcionada por los Conductores y de aceptar a aquellos que cumplan con los criterios y el perfil que TrineoApp considera adecuados, según su propio proceso de evaluación. La selección de Conductores se basará en los estándares y requisitos establecidos por TrineoApp, con el objetivo de garantizar la calidad y confiabilidad de los servicios ofrecidos.</Paragraph>
            
            <Paragraph>8.2. El proceso de activación de cuentas de los Musher se realizará exclusivamente durante los días hábiles. Durante este período, se otorgará un plazo de hasta tres días hábiles para la verificación y validación de los datos proporcionados.</Paragraph>
            
            <SectionTitle>USUARIO</SectionTitle>
            
            <SubSectionTitle>Introducción</SubSectionTitle>
            <Paragraph>1.1. Estos términos y condiciones (en adelante, "T&C") constituyen un acuerdo legalmente vinculante entre usted (en adelante, "Usuario") y TrineoApp (en adelante, "la Empresa"), una empresa prestadora de servicios informáticos que ofrece una plataforma tecnológica para facilitar la contratación de servicios de transporte de carga a través de Conductores.</Paragraph>
            
            <Paragraph>1.2. Los Usuarios aceptan estos Términos y Condiciones desde el momento en que se registran en el Sitio y sin necesidad de usar los servicios de TrineoApp.</Paragraph>
            
            <SubSectionTitle>Registro y cuenta</SubSectionTitle>
            <Paragraph>2.1. Para acceder a los servicios de transporte de carga ofrecidos por los Conductores en la plataforma de TrineoApp, es necesario que el usuario descargue la aplicación y se registre.</Paragraph>
            
            <Paragraph>2.2. El Usuario debe proporcionar información precisa y actualizada durante el proceso de registro en la plataforma. Es responsabilidad del Usuario mantener esta información actualizada en todo momento.</Paragraph>
            
            <Paragraph>2.3. El Usuario se compromete a utilizar la plataforma de TrineoApp de conformidad con todas las leyes y regulaciones aplicables. Se prohíbe el uso de la plataforma para actividades ilegales o inapropiadas, incluyendo, pero no limitado a actividades que infrinjan derechos de autor, marcas registradas, patentes u otros derechos de propiedad intelectual, actividades que violen la privacidad de terceros, actividades que puedan dañar, interferir o perjudicar el funcionamiento de la plataforma o de otros usuarios.</Paragraph>
            
            <Paragraph>2.4. El Usuario acepta y reconoce que TrineoApp puede recopilar y utilizar su información personal de acuerdo con su política de privacidad. Es fundamental que el Usuario revise y comprenda la política de privacidad para estar al tanto de cómo se manejan sus datos personales en la plataforma.</Paragraph>
            
            <SubSectionTitle>Servicios de Transporte de Carga</SubSectionTitle>
            <Paragraph>3.1. TrineoApp proporciona una plataforma que permite a los usuarios encontrar y contactar servicios de traslado de carga que se acomode a su necesidad. La plataforma sirve como intermediario para facilitar las operaciones y transacciones.</Paragraph>
            
            <Paragraph>3.2. El usuario reconoce y acepta que TrineoApp no será en ningún caso responsable por la pérdida, daño o retraso de la carga transportada. La responsabilidad por la carga recae en el usuario y el Conductor, de acuerdo con los términos y condiciones acordados entre ellos.</Paragraph>
            
            <Paragraph>3.3. El usuario es responsable de aprobar el inicio del viaje asegurándose de que la carga esté debidamente empaquetada y protegida antes de su transporte.</Paragraph>
            
            <Paragraph>3.4. En TrineoApp, comprendemos la importancia de mantener la integridad de los productos durante el transporte. Por lo tanto, ofrecemos a nuestros usuarios la opción de asegurar la integridad de sus productos durante todo el traslado acordado con el conductor.</Paragraph>
            
            <Paragraph>3.5. El usuario tiene la responsabilidad de asegurar la recepción de la carga en la dirección de destino.</Paragraph>
            
            <SubSectionTitle>Pagos</SubSectionTitle>
            <Paragraph>4.1. El Usuario debe pagar los servicios de transporte de su carga de acuerdo con los cálculos tarifarios establecidos en la plataforma.</Paragraph>
            
            <Paragraph>4.2. La Empresa se reserva el derecho de retener una comisión máxima del 15% diferenciado por categoría de vehículo, para cubrir el costo del servicio de la plataforma como parte de la colaboración con TrineoApp.</Paragraph>
            
            <Paragraph>4.3. El Usuario tiene un plazo máximo de 24 horas desde la finalización del servicio de transporte para realizar el pago correspondiente a dicho servicio, si el pago no se concreta dentro de este plazo, Trineo App se reserva la facultad de realizar el cobro automático del monto pactado, utilizando los métodos de pago asociados a la cuenta del usuario en la plataforma, al momento en que el usuario utilice el servicio de fletes.</Paragraph>
            
            <Paragraph>4.4. En caso de que el cobro forzoso no sea exitoso debido a fondos insuficientes o cualquier otra razón imputable al Usuario, Trineo App podrá aplicar intereses por mora del 3% conforme a la legislación vigente y tomar medidas adicionales, incluyendo la suspensión de la cuenta del Usuario hasta que la deuda sea saldada, amparándonos en la Ley del Consumidor (Ley 19.496), que establece el deber del consumidor de cumplir con sus compromisos contractuales, y haciendo de público conocimiento para todos los participantes del presente contrato, aseguramos la transparencia y equidad en la aplicación de estas condiciones.</Paragraph>
            
            <Paragraph>4.5. Trineo App acepta pagos por servicios de traslado a través de los siguientes métodos: tarjetas asociadas en la aplicación (crédito, débito y prepago) y el botón de pago que la plataforma dispondrá de manera específica para el o los servicios que lo requieran.</Paragraph>
            
            <SubSectionTitle>Propiedad Intelectual</SubSectionTitle>
            <Paragraph>5.1. La plataforma de TrineoApp, junto con todos los contenidos relacionados, son propiedad exclusiva de TrineoApp y están protegidos por las leyes de propiedad intelectual.</Paragraph>
            
            <Paragraph>5.2. El usuario reconoce y acepta de manera expresa que, al utilizar la plataforma para contratar servicios de transporte de carga, no adquiere ni se le concede ningún derecho de propiedad, licencia, o interés en la plataforma o sus contenidos. La propiedad intelectual y los derechos conexos permanecen exclusivamente en manos de TrineoApp.</Paragraph>
            
            <SubSectionTitle>Limitación de Responsabilidad</SubSectionTitle>
            <Paragraph>6.1. En ningún caso TrineoApp será responsable por daños indirectos, consecuentes o incidentales relacionados con los servicios de transporte de carga contratados por el Usuario a través de la plataforma.</Paragraph>
            
            <Paragraph>6.2. La responsabilidad total de TrineoApp por cualquier reclamación relacionada con los servicios de transporte de carga contratados por el usuario no excederá el monto pagado por dichos servicios.</Paragraph>
            
            <SubSectionTitle>Privacidad de datos</SubSectionTitle>
            <Paragraph>7.1. En TrineoApp, estamos comprometidos a proteger la privacidad y la seguridad de los datos de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos, divulgamos y protegemos la información personal de los usuarios de la aplicación TrineoApp ("la Aplicación"). Al utilizar la Aplicación, usted acepta las prácticas descritas en esta Política de Privacidad.</Paragraph>
            
            <Paragraph>7.2. Información que Recopilamos. Cuando se registra en la Aplicación, podemos recopilar información personal, como su nombre, dirección de correo electrónico, número de teléfono y detalles de facturación.</Paragraph>
            
            <Paragraph>Recopilamos información sobre los viajes que realiza a través de la Aplicación, incluyendo ubicación, fecha, hora, origen y destino.</Paragraph>
            
            <Paragraph>Para procesar los pagos, recopilamos información de pago, como detalles de tarjetas de crédito o débito, u otros métodos de pago utilizados.</Paragraph>
            
            <Paragraph>Podemos recopilar información del dispositivo móvil que utiliza para acceder a la Aplicación, incluyendo la dirección IP, sistema operativo, tipo de dispositivo y la identificación del dispositivo.</Paragraph>
            
            <Paragraph>7.3. Uso de la Información. Utilizamos la información recopilada para proporcionar servicios de transporte de carga, procesar pagos, mejorar la aplicación y personalizar la experiencia del usuario.</Paragraph>
            
            <Paragraph>7.3.1. La información del viaje, como la ubicación, se utiliza para coordinar el servicio de transporte.</Paragraph>
            
            <Paragraph>7.3.2. Los datos de pago se utilizan para procesar los pagos de los servicios.</Paragraph>
            
            <Paragraph>La información del dispositivo se utiliza para garantizar un funcionamiento adecuado de la aplicación y para fines de seguridad.</Paragraph>
            
            <Paragraph>7.4. Compartir Información. Compartimos información con conductores de su viaje para facilitar los servicios.</Paragraph>
            
            <Paragraph>7.4.1. Podemos compartir información con terceros para fines de procesamiento de pagos, prevención de fraudes, análisis y mejora de la aplicación.</Paragraph>
            
            <Paragraph>7.4.2 No vendemos ni compartimos su información personal con fines de marketing sin su consentimiento.</Paragraph>
            
            <Paragraph>7.5. Seguridad de los Datos. Implementamos medidas de seguridad para proteger su información contra el acceso no autorizado, la pérdida o el robo.</Paragraph>
            
            <Paragraph>7.5.1. Sin embargo, ninguna transmisión por Internet o sistema de almacenamiento de datos es completamente seguro, por lo que no podemos garantizar la seguridad absoluta.</Paragraph>
            
            <Paragraph>7.6. Sus Opciones</Paragraph>
            
            <Paragraph>Puede acceder y modificar su información de perfil en la Aplicación.</Paragraph>
            
            <Paragraph>Puede optar por no recibir comunicaciones promocionales de nuestra parte.</Paragraph>
            
            <SubSectionTitle>Modificaciones</SubSectionTitle>
            <Paragraph>8.1. TrineoApp se reserva el derecho de modificar estos T&C en cualquier momento. Cualquier modificación será notificada al usuario en el momento de realizar la acción correspondiente, ya sea al iniciar sesión en la aplicación o al realizar cualquier otra actividad que requiera la aceptación de estos términos. El usuario tendrá la opción de aceptar o rechazar los términos modificados. La utilización continua de los servicios ofrecidos por TrineoApp después de la notificación y presentación de los nuevos términos constituirá la aceptación de los mismos por parte del usuario.</Paragraph>
            
            <Paragraph>8.2. El Usuario acepta que la continuación del uso de la plataforma después de cualquier modificación de los T&C constituirá su aceptación de estos.</Paragraph>
            
            <SubSectionTitle>Contacto</SubSectionTitle>
            <Paragraph>9.1. Si el Conductor tiene preguntas sobre estos T&C o cualquier otro aspecto de la plataforma, puede ponerse en contacto con TrineoApp en contacto@trineoapp.cl.</Paragraph>
            
            <SubSectionTitle>La Evaluación y Aceptación de usuarios</SubSectionTitle>
            <Paragraph>10.1. TrineoApp se reserva el derecho de validar el comportamiento de los usuarios con la aplicación y de suspender las cuentas a aquellos que no cumplan con los criterios y buenas prácticas del perfil que TrineoApp considera adecuados, según su propio proceso de evaluación. Las evaluaciones periódicas se basarán en los estándares y requisitos establecidos por TrineoApp, con el objetivo de garantizar la calidad y confiabilidad de los servicios ofrecidos.</Paragraph>
            
            <Paragraph>10.2. Los usuarios que cancelen su solicitud de traslado después de que un conductor haya aceptado la misma incurrirán en una multa equivalente al 40% del precio base mínimo. Esta multa se aplicará para compensar al conductor por su tiempo y recursos comprometidos.</Paragraph>
            
            <Paragraph>10.3. Al solicitar un traslado, los usuarios deben proporcionar información precisa sobre la cantidad a transportar, descripción adecuada, peso y dimensiones aproximados de la carga que desean transportar. Ya que el conductor aceptara o rechazara el traslado de acuerdo con lo indicado. Es fundamental que los usuarios seleccionen un vehículo adecuado a las necesidades de su carga. Los usuarios que no cumplan con lo descrito anteriormente estarán sujetos a una multa que corresponderá al 40% del precio base mínimo.</Paragraph>
            
            <SubSectionTitle>Jurisdicción y Leyes aplicables</SubSectionTitle>
            <Paragraph>11.1. Este Acuerdo se rige por las leyes de la República de Chile. Cualquier disputa que surja en relación con este Acuerdo se resolverá en los tribunales competentes de Chile. Las partes acuerdan que la jurisdicción exclusiva y el lugar para cualquier acción legal relacionada con este Acuerdo serán los tribunales ubicados en Chillán. Las partes también acuerdan someterse a la jurisdicción personal de estos tribunales.</Paragraph>
            
            <Paragraph>11.2. Este Acuerdo se redacta en conformidad con las leyes y regulaciones chilenas aplicables y se interpreta de acuerdo con ellas. Cualquier disposición de este Acuerdo que sea contraria a la ley será nula y sin efecto, pero las demás disposiciones de este Acuerdo seguirán siendo plenamente válidas y ejecutables.</Paragraph>
            
            <Paragraph>11.3. Al utilizar nuestra aplicación en Chile, usted acepta someterse a la jurisdicción y las leyes de Chile para todas las cuestiones relacionadas con su uso de la aplicación, incluyendo cualquier disputa que pueda surgir entre usted y TrineoApp.</Paragraph>
            
            <SubSectionTitle>Subsistencia</SubSectionTitle>
            <Paragraph>12.1. Aun si los presentes Términos y Condiciones llegaran a su fin o fueran anulados, las cláusulas que rigen la responsabilidad del Usuario, la propiedad intelectual, la obligación de confidencialidad del Usuario, y las leyes y jurisdicción aplicables permanecerán vigentes.</Paragraph>
            
            <SubSectionTitle>Mensajes de texto</SubSectionTitle>
            <Paragraph>13.1. Al crear una Cuenta en TrineoApp, usted acepta que, como parte de la actividad comercial normal relacionada con el uso de nuestros servicios, podemos enviarle mensajes de texto informativos (SMS) a la información de contacto que proporcionó al registrarse. Estos mensajes de texto podrían incluir códigos para la confirmación de datos y verificación de usuario.</Paragraph>
          </TermsContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default TermsPage;
