import React from 'react';
import { FaEnvelope, FaHeadset, FaRegSmile } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const ContactContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: ${SPACING.XXLARGE} ${SPACING.XLARGE};
  position: relative;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
    margin: 0 ${SPACING.MEDIUM};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.LARGE};
  text-align: center;
`;

const ContactSection = styled.div`
  margin-bottom: ${SPACING.XLARGE};
`;

const ContactText = styled.p`
  font-size: ${FONTS.SIZE_LARGE};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
  text-align: center;
`;

const EmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${SPACING.XLARGE} 0;
`;

const EmailBox = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, ${COLORS.BLUE_LIGHT}, ${COLORS.BLUE_LIGHT}80);
  padding: ${SPACING.MEDIUM} ${SPACING.LARGE};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(10, 75, 129, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(10, 75, 129, 0.2);
  }
`;

const EmailIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING.MEDIUM};
  
  svg {
    font-size: 24px;
    color: white;
  }
`;

const EmailLink = styled.a`
  font-size: ${FONTS.SIZE_LARGE};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLUE_MAIN};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PrioritySection = styled.div`
  margin: ${SPACING.XLARGE} 0;
  padding: ${SPACING.LARGE};
  background-color: ${COLORS.BLUE_LIGHT}40;
  border-radius: 12px;
  border-left: 4px solid ${COLORS.BLUE_MAIN};
`;

const PriorityText = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: 0;
  text-align: center;
`;

const ClosingSection = styled.div`
  margin-top: ${SPACING.XLARGE};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClosingText = styled.p`
  font-size: ${FONTS.SIZE_LARGE};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.MEDIUM};
  text-align: center;
`;

const SmileIcon = styled.div`
  font-size: 36px;
  color: ${COLORS.BLUE_MAIN};
  margin-top: ${SPACING.MEDIUM};
`;

const ContactPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Contacto" />
      
      <MainContent>
        <Container>
          <ContactContainer>
            <PageTitle>Contacto</PageTitle>
            
            <ContactSection>
              <ContactText>
                ¡Nos encantaría saber de ti! Contáctanos a través de nuestro correo electrónico y estaremos encantados de responder a tus consultas y brindarte la mejor atención.
              </ContactText>
              
              <EmailContainer>
                <EmailBox>
                  <EmailIcon>
                    <FaEnvelope />
                  </EmailIcon>
                  <EmailLink href="mailto:contacto@trineoapp.cl">
                    contacto@trineoapp.cl
                  </EmailLink>
                </EmailBox>
              </EmailContainer>
              
              <ContactText>
                En Trineo, nuestra prioridad es brindar un servicio de alta calidad al mejor costo, y la satisfacción de nuestros clientes es lo más importante para nosotros. No dudes en escribirnos para cualquier pregunta, sugerencia o requerimiento que puedas tener. Estamos aquí para ayudarte.
              </ContactText>
            </ContactSection>
            
            <PrioritySection>
              <PriorityText>
                <FaHeadset style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                Nuestro equipo de soporte está disponible para asistirte y resolver todas tus dudas.
              </PriorityText>
            </PrioritySection>
            
            <ClosingSection>
              <ClosingText>
                ¡Esperamos recibir tu mensaje y poder asistirte pronto!
              </ClosingText>
              <SmileIcon>
                <FaRegSmile />
              </SmileIcon>
            </ClosingSection>
          </ContactContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default ContactPage; 