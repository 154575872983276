import React from 'react';
import { FaBullseye, FaHistory, FaLightbulb, FaRocket, FaUsers } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  position: relative;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const AboutContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: ${SPACING.XLARGE};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLUE_MAIN};
`;

const Section = styled.div`
  margin-bottom: ${SPACING.XLARGE};
`;

const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_SECONDARY};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: ${SPACING.SMALL};
    color: ${COLORS.BLUE_MAIN};
  }
`;

const Paragraph = styled.p`
  margin-bottom: ${SPACING.MEDIUM};
  line-height: 1.7;
`;

const VideoContainer = styled.div`
  margin: ${SPACING.LARGE} 0;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const AboutPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Quienes somos" />
      
      <Content>
        <Container>
          <AboutContainer>
            <Title>Quienes somos</Title>
            
            <VideoContainer>
              <iframe 
                src="https://www.youtube.com/embed/VDHgA2oqjbQ" 
                title="Trineo - YouTube" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>
            </VideoContainer>
            
            <Section>
              <SectionTitle>
                <FaRocket /> Misión
              </SectionTitle>
              <Paragraph>
                "Posicionarnos como la plataforma líder a nivel mundial en el transporte de carga, esforzándonos continuamente por transformar la gestión del traslado de carga. Destacándonos por nuestra agilidad, eficiencia, seguridad y compromiso con la innovación y la sostenibilidad. Ofreciendo soluciones que contribuyan positivamente al planeta. Implementando soluciones tecnológicas avanzadas para beneficiar a conductores, usuarios y empresas, marcando así un nuevo estándar en la industria del transporte de carga."
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaBullseye /> Visión
              </SectionTitle>
              <Paragraph>
                Ser la plataforma líder a nivel internacional en el transporte de carga, reconocida por nuestra agilidad, eficiencia, seguridad y compromiso con la innovación. Esforzándonos por revolucionar la forma en que se gestiona el traslado de carga, brindando soluciones tecnológicas avanzadas que beneficien a conductores, usuarios y empresas por igual.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaLightbulb /> Nuestra Historia
              </SectionTitle>
              <Paragraph>
                En Trineo, nuestra historia comenzó con la visión de hacer que el transporte de carga sea más fácil y eficiente para las personas en sus hogares y obras de construcción. Nos dimos cuenta de que muchas personas se enfrentaban a desafíos y complicaciones al enviar productos a sus destinos, lo que los llevaba a gastar más tiempo y dinero del necesario.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaHistory /> Nuestro Comienzo
              </SectionTitle>
              <Paragraph>
                Es por eso que creamos Trineo: para ofrecer una solución confiable y eficiente para el transporte de carga. Comenzamos con un equipo de desarrolladores dedicados a construir una plataforma fácil de usar que permitiera a los usuarios enviar y recibir productos de manera rápida y segura. A medida que crecimos, también construimos una red de conductores profesionales para llevar a cabo los envíos de manera confiable y segura.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaUsers /> Trineo Hoy
              </SectionTitle>
              <Paragraph>
                Hoy en día, Trineo se ha convertido en la plataforma más ágil en el transporte de carga para hogares y obras de construcción. Estamos orgullosos de nuestra historia y estamos comprometidos a seguir mejorando y evolucionando para satisfacer las necesidades cambiantes de nuestros usuarios y del mercado.
              </Paragraph>
            </Section>
          </AboutContainer>
        </Container>
      </Content>
      <Footer />
    </PageWrapper>
  );
};

export default AboutPage; 