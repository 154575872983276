import React from 'react';
import styled, { css } from 'styled-components';
import { ANIMATIONS, BORDER_RADIUS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Button variants
const VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE: 'outline',
  TEXT: 'text',
};

// Button sizes
const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const getVariantStyles = (variant) => {
  switch (variant) {
    case VARIANTS.PRIMARY:
      return css`
        background-color: ${COLORS.BLUE_SECONDARY};
        color: ${COLORS.WHITE};
        border: none;
        
        &:hover, &:focus {
          background-color: ${COLORS.BLUE_MAIN};
        }
      `;
    case VARIANTS.SECONDARY:
      return css`
        background-color: ${COLORS.SUCCESS};
        color: ${COLORS.WHITE};
        border: none;
        
        &:hover, &:focus {
          background-color: ${COLORS.SUCCESS}CC;
        }
      `;
    case VARIANTS.OUTLINE:
      return css`
        background-color: transparent;
        color: ${COLORS.BLUE_SECONDARY};
        border: 2px solid ${COLORS.BLUE_SECONDARY};
        
        &:hover, &:focus {
          background-color: ${COLORS.BLUE_SECONDARY}${COLORS.OPACITY_VERY_SUBTLE * 100};
        }
      `;
    case VARIANTS.TEXT:
      return css`
        background-color: transparent;
        color: ${COLORS.BLUE_MAIN};
        border: none;
        padding: ${SPACING.XSMALL} ${SPACING.SMALL};
        
        &:hover, &:focus {
          background-color: ${COLORS.BLUE_MAIN}${COLORS.OPACITY_VERY_SUBTLE * 100};
        }
      `;
    default:
      return css`
        background-color: ${COLORS.BLUE_SECONDARY};
        color: ${COLORS.WHITE};
        border: none;
        
        &:hover, &:focus {
          background-color: ${COLORS.BLUE_MAIN};
        }
      `;
  }
};

const getSizeStyles = (size) => {
  switch (size) {
    case SIZES.SMALL:
      return css`
        padding: ${SPACING.XSMALL} ${SPACING.MEDIUM};
        font-size: ${FONTS.SIZE_SECONDARY};
      `;
    case SIZES.LARGE:
      return css`
        padding: ${SPACING.MEDIUM} ${SPACING.LARGE};
        font-size: ${FONTS.SIZE_BUTTON};
      `;
    case SIZES.MEDIUM:
    default:
      return css`
        padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
        font-size: ${FONTS.SIZE_BUTTON};
      `;
  }
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDER_RADIUS.MEDIUM};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  transition: all ${ANIMATIONS.DURATION_MEDIUM} ${ANIMATIONS.EASING};
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  
  ${({ variant }) => getVariantStyles(variant)};
  ${({ size }) => getSizeStyles(size)};
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `};
  ${({ disabled }) => disabled && css`
    opacity: 0.6;
    cursor: not-allowed;
    
    &:hover {
      opacity: 0.6;
    }
  `};
`;

const Button = ({ 
  variant = VARIANTS.PRIMARY, 
  size = SIZES.MEDIUM, 
  type = 'button', 
  children, 
  fullWidth = false,
  disabled = false,
  onClick,
  ...props 
}) => {
  return (
    <StyledButton
      type={type}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export { SIZES, VARIANTS };
export default Button; 