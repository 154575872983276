import React from 'react';
import { FaDownload, FaMapMarkerAlt, FaShieldAlt, FaTruck } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Button from '../../components/Button/Button';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px; // Espacio para el header fijo
  padding-bottom: ${SPACING.XXLARGE}; // Añadir espacio adicional en la parte inferior
  position: relative;
  overflow: visible;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE}; // Más espacio en pantallas más grandes
  }
`;

const MarketingContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

const HeroSection = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  margin-bottom: ${SPACING.XLARGE};
  text-align: center;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
  text-align: center;
`;

const HeroText = styled.p`
  font-size: ${FONTS.SIZE_LARGE};
  line-height: 1.7;
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLACK_TEXT};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const FeaturesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${SPACING.LARGE};
  margin-bottom: ${SPACING.XLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FeatureCard = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.LARGE};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
`;

const FeatureIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || COLORS.BLUE_LIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${SPACING.MEDIUM};
  
  svg {
    font-size: 30px;
    color: ${props => props.iconColor || COLORS.BLUE_MAIN};
  }
`;

const FeatureTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.SMALL};
`;

const FeatureDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
`;

const CTASection = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  text-align: center;
  margin-bottom: ${SPACING.XLARGE};
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const CTATitle = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
`;

const CTAText = styled.p`
  font-size: ${FONTS.SIZE_LARGE};
  line-height: 1.7;
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLACK_TEXT};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING.MEDIUM};
  flex-wrap: wrap;
`;

const LinkSection = styled.div`
  text-align: center;
  margin-bottom: ${SPACING.LARGE};
`;

const LinkTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
`;

const LinkText = styled.a`
  font-size: ${FONTS.SIZE_BODY};
  color: ${COLORS.BLUE_MAIN};
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  
  &:hover {
    text-decoration: underline;
  }
`;

const MarketingPage = () => {
  // Función para descargar la app
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };

  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Marketing" />
      
      <MainContent>
        <Container>
          <MarketingContainer>
            <HeroSection>
              <PageTitle>Marketing</PageTitle>
              <HeroText>
                ¡Únete a la comunidad ágil de Trineo y experimenta la comodidad de trasladar tus compras directamente a tu hogar o lugar de trabajo! Nuestra plataforma fácil de usar te permite no solo viajar en el vehículo que transporta tus productos, sino también rastrear tus envíos en tiempo real y garantizar que tus compras lleguen de manera segura y a tiempo.
              </HeroText>
              <Button primary large onClick={downloadApp}>
                Descargar Trineo
              </Button>
            </HeroSection>
            
            <FeaturesSection>
              <FeatureCard>
                <FeatureIcon bgColor={`${COLORS.BLUE_LIGHT}`} iconColor={COLORS.BLUE_MAIN}>
                  <FaTruck />
                </FeatureIcon>
                <FeatureTitle>Variedad de Vehículos</FeatureTitle>
                <FeatureDescription>
                  En Trineo, nos enfocamos en ofrecerte una amplia variedad de categorías de vehículos para que puedas elegir la mejor opción para tus necesidades de transporte. Ya sea que necesites transportar carga ligera o pesada, nuestra plataforma tiene la solución perfecta para ti.
                </FeatureDescription>
              </FeatureCard>
              
              <FeatureCard>
                <FeatureIcon bgColor={`${COLORS.GREEN_LIGHT}`} iconColor={COLORS.GREEN_MAIN}>
                  <FaShieldAlt />
                </FeatureIcon>
                <FeatureTitle>Conductores Certificados</FeatureTitle>
                <FeatureDescription>
                  Con Trineo, puedes estar seguro de que tus productos están en buenas manos. Todos nuestros conductores están capacitados y certificados para transportar cargas, lo que garantiza que tus productos llegarán a su destino en perfectas condiciones.
                </FeatureDescription>
              </FeatureCard>
              
              <FeatureCard>
                <FeatureIcon bgColor={`${COLORS.ORANGE_LIGHT}`} iconColor={COLORS.ORANGE_MAIN}>
                  <FaMapMarkerAlt />
                </FeatureIcon>
                <FeatureTitle>Seguimiento en Tiempo Real</FeatureTitle>
                <FeatureDescription>
                  No más largas esperas o molestias al cargar tus productos. Con Trineo puedes rastrear tus envíos en tiempo real, lo que te permite saber exactamente cuándo llegarán tus productos a su destino.
                </FeatureDescription>
              </FeatureCard>
            </FeaturesSection>
            
            <CTASection>
              <CTATitle>¡Únete a la comunidad de Trineo!</CTATitle>
              <CTAText>
                No más largas esperas o molestias al cargar tus productos. Descarga Trineo hoy mismo y disfruta de un transporte de carga eficiente y sin complicaciones. ¡Únete a la comunidad de Trineo y experimenta una nueva forma de transporte!
              </CTAText>
              <ButtonContainer>
                <Button primary large onClick={downloadApp}>
                  <FaDownload style={{ marginRight: '8px' }} /> Descargar Ahora
                </Button>
              </ButtonContainer>
            </CTASection>
            
            <LinkSection>
              <LinkTitle>Conoce más:</LinkTitle>
              <LinkText href="https://trineoapp.cl" target="_blank" rel="noopener noreferrer">
                Chile Inicio - Trineo (trineoapp.cl)
              </LinkText>
            </LinkSection>
          </MarketingContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default MarketingPage; 