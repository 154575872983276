import React from 'react';
import styled from 'styled-components';
import { BORDER_RADIUS, COLORS, FONTS, SHADOWS, SPACING } from '../../styles/variables';

const CardWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.LARGE};
  padding: ${SPACING.LARGE};
  box-shadow: ${SHADOWS.MEDIUM};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${SHADOWS.LARGE};
  }
`;

const IconContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor || `${COLORS.BLUE_MAIN}${COLORS.OPACITY_VERY_SUBTLE * 100}`};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${SPACING.MEDIUM};
  font-size: 32px;
  color: ${({ iconColor }) => iconColor || COLORS.BLUE_MAIN};
`;

const Title = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.SMALL};
  color: ${COLORS.BLACK_TEXT};
`;

const Description = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  color: ${COLORS.BLACK_TEXT}CC;
  margin-bottom: 0;
`;

const FeatureCard = ({ 
  icon, 
  title, 
  description, 
  iconBgColor, 
  iconColor,
  ...props 
}) => {
  return (
    <CardWrapper {...props}>
      <IconContainer bgColor={iconBgColor} iconColor={iconColor}>
        {icon}
      </IconContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </CardWrapper>
  );
};

export default FeatureCard; 