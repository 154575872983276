import React from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaCreditCard,
  FaDesktop,
  FaMapMarkedAlt,
  FaMobileAlt,
  FaSearch,
  FaShippingFast,
  FaThumbsUp,
  FaWhatsapp
} from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import Container from '../Container/Container';

// Colores específicos para usuarios según la guía de diseño
const USER_BLUE = '#0078D7';
const USER_BLUE_LIGHT = '#4CA6FF';
const USER_BLUE_DARK = '#005A9E';

// Animaciones
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Workflow Section
const WorkflowSection = styled.div`
  position: relative;
  background: transparent;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  margin: 60px 0;
  padding: ${SPACING.XLARGE} 0;
  
  /* Técnica para extender a todo el ancho de la ventana sin causar scroll horizontal */
  width: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado de overflow-x a overflow para controlar también el scroll vertical */
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -10%;
    width: 300px; /* Reducido el tamaño para evitar desbordamiento */
    height: 300px; /* Reducido el tamaño para evitar desbordamiento */
    background: radial-gradient(circle, ${USER_BLUE_LIGHT}08, transparent 70%);
    border-radius: 0%;
    z-index: 0;
    opacity: 0.8;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -30%;
    left: -10%;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, ${COLORS.BLUE_LIGHT}05, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    animation: pulse 20s infinite alternate-reverse ease-in-out;
  }
`;

const FullWidthBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 120, 215, 0.08);
  z-index: -1;
`;

const WorkflowContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 ${SPACING.MEDIUM};
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 ${SPACING.LARGE};
  }
`;

const SectionTitle = styled.h2`
  font-family: ${FONTS.PRIMARY};
  font-weight: 700;
  font-size: 36px;
  color: ${USER_BLUE_DARK};
  text-align: center;
  margin-bottom: ${SPACING.MEDIUM};
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: ${USER_BLUE};
    margin: ${SPACING.SMALL} auto 0;
    border-radius: 2px;
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 28px;
  }
`;

const WorkflowIntro = styled.p`
  text-align: center;
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const ChannelsTitle = styled.h3`
  text-align: center;
  font-size: ${FONTS.SIZE_MEDIUM};
  color: ${USER_BLUE_DARK};
  margin-bottom: ${SPACING.MEDIUM};
  font-weight: 600;
`;

const ChannelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SPACING.MEDIUM};
  margin-bottom: ${SPACING.LARGE};
`;

const ChannelItem = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  padding: 10px 15px;
  border-radius: 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.4);
  }
  
  svg {
    color: ${USER_BLUE};
    margin-right: 8px;
    font-size: 18px;
  }
  
  span {
    color: ${COLORS.BLACK_TEXT};
    font-weight: 500;
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const WorkflowStepsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${SPACING.LARGE};
`;

const NavigationArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 120, 215, 0.2);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${USER_BLUE};
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background: rgba(0, 120, 215, 0.3);
    transform: translateY(-50%) scale(1.1);
  }
  
  &:focus {
    outline: none;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &.prev {
    left: 0;
  }
  
  &.next {
    right: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const WorkflowStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.LARGE};
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 0 40px; /* Espacio para las flechas de navegación */
  padding-bottom: ${SPACING.MEDIUM};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
    gap: ${SPACING.LARGE};
    overflow-x: visible;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex-direction: row;
    gap: ${SPACING.LARGE};
    overflow-x: visible;
    flex-wrap: nowrap;
    justify-content: center; /* Cambiado de space-between a center */
    padding: 0;
    margin: 0 auto; /* Añadido para centrar el contenedor */
  }
`;

const WorkflowStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.4s ease;
  min-width: calc(100% - 80px); /* Ancho completo menos el espacio para las flechas y un poco más */
  width: calc(100% - 10px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: ${SPACING.MEDIUM};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    min-width: auto;
    width: auto;
    flex: 1 1 calc(50% - ${SPACING.LARGE});
    max-width: calc(50% - ${SPACING.LARGE});
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    min-width: 200px;
    width: auto;
    flex: 1;
    max-width: calc(16.66% - ${SPACING.LARGE});
  }
`;

const WorkflowStepIcon = styled.div`
  margin-bottom: ${SPACING.SMALL};
  font-size: 28px;
  color: ${USER_BLUE};
`;

const WorkflowStepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WorkflowStepNumber = styled.div`
  font-size: 40px;
  font-weight: 800;
  color: ${USER_BLUE};
  line-height: 1;
  margin-bottom: ${SPACING.XSMALL};
`;

const WorkflowStepTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_5};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${USER_BLUE};
  margin-bottom: ${SPACING.SMALL};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    background: ${USER_BLUE};
    transition: width 0.4s ease;
    margin: 6px auto 0;
  }
  
  ${WorkflowStep}:hover &::after {
    width: 100%;
  }
`;

const WorkflowStepDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  flex-grow: 1;
  margin-top: ${SPACING.XSMALL};
`;

const WorkflowArrow = styled.div`
  margin-top: ${SPACING.SMALL};
  transition: transform 0.2s ease;
  
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
  
  ${WorkflowStep}:hover & {
    transform: translateX(5px);
  }
`;

const UserWorkflowSection = () => {
  return (
    <WorkflowSection>
      <FullWidthBackground />
      <Container>
        <WorkflowContent>
          <SectionTitle>¿Cómo funciona Trineo?</SectionTitle>
          <WorkflowIntro>
            Descubre lo fácil que es usar Trineo para transportar tus objetos. Nuestro proceso está diseñado para brindarte una experiencia sencilla y eficiente.
          </WorkflowIntro>
          
          <ChannelsTitle>Múltiples canales para programar tu flete</ChannelsTitle>
          <ChannelsContainer>
            <ChannelItem>
              <FaMobileAlt />
              <span>App Móvil</span>
            </ChannelItem>
            <ChannelItem>
              <FaDesktop />
              <span>Sitio Web</span>
            </ChannelItem>
            <ChannelItem>
              <FaWhatsapp />
              <span>WhatsApp</span>
            </ChannelItem>
          </ChannelsContainer>
          
          <WorkflowStepsWrapper>
            <NavigationArrow 
              className="prev" 
              onClick={() => {
                const container = document.getElementById('user-workflow-steps');
                const cardWidth = container.querySelector('div').offsetWidth;
                container.scrollBy({left: -cardWidth - 20, behavior: 'smooth'});
              }}
            >
              <FaArrowLeft />
            </NavigationArrow>
            
            <WorkflowStepsContainer id="user-workflow-steps">
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaMobileAlt />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>01</WorkflowStepNumber>
                  <WorkflowStepTitle>Elige tu Canal</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Usa nuestra app, sitio web o WhatsApp para programar tu flete.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
                <WorkflowArrow><FaArrowRight /></WorkflowArrow>
              </WorkflowStep>
              
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaMapMarkedAlt />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>02</WorkflowStepNumber>
                  <WorkflowStepTitle>Programa tu Flete</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Ingresa los detalles de tu envío, incluyendo origen, destino y tipo de carga.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
                <WorkflowArrow><FaArrowRight /></WorkflowArrow>
              </WorkflowStep>
              
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaSearch />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>03</WorkflowStepNumber>
                  <WorkflowStepTitle>Recibe Cotización</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Obtén un presupuesto instantáneo basado en la distancia y tipo de vehículo necesario.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
                <WorkflowArrow><FaArrowRight /></WorkflowArrow>
              </WorkflowStep>
              
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaCreditCard />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>04</WorkflowStepNumber>
                  <WorkflowStepTitle>Realiza el Pago</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Paga de forma segura a través de nuestra plataforma con múltiples métodos de pago.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
                <WorkflowArrow><FaArrowRight /></WorkflowArrow>
              </WorkflowStep>
              
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaShippingFast />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>05</WorkflowStepNumber>
                  <WorkflowStepTitle>Seguimiento</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Sigue el recorrido de tu carga en tiempo real a través de la aplicación.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
                <WorkflowArrow><FaArrowRight /></WorkflowArrow>
              </WorkflowStep>
              
              <WorkflowStep>
                <WorkflowStepIcon>
                  <FaThumbsUp />
                </WorkflowStepIcon>
                <WorkflowStepContent>
                  <WorkflowStepNumber>06</WorkflowStepNumber>
                  <WorkflowStepTitle>Confirmación</WorkflowStepTitle>
                  <WorkflowStepDescription>
                    Recibe tu carga y califica el servicio para ayudarnos a mejorar continuamente.
                  </WorkflowStepDescription>
                </WorkflowStepContent>
              </WorkflowStep>
            </WorkflowStepsContainer>
            
            <NavigationArrow 
              className="next" 
              onClick={() => {
                const container = document.getElementById('user-workflow-steps');
                const cardWidth = container.querySelector('div').offsetWidth;
                container.scrollBy({left: cardWidth + 20, behavior: 'smooth'});
              }}
            >
              <FaArrowRight />
            </NavigationArrow>
          </WorkflowStepsWrapper>
        </WorkflowContent>
      </Container>
    </WorkflowSection>
  );
};

export default UserWorkflowSection; 