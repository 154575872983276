import React from 'react';
import { FaDownload, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import Container from '../Container/Container';
import Section from '../Section/Section';

const FooterWrapper = styled.div`
  position: relative;
  backdrop-filter: blur(10px);
  background-color: ${COLORS.WHITE}88; /* 53% de opacidad */
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.MEDIUM};
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, ${COLORS.BLUE_MAIN}05, ${COLORS.WHITE}05);
    z-index: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.LARGE};
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const FooterColumn = styled.div`
  margin-bottom: ${SPACING.LARGE};
  position: relative;
  z-index: 1;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex: 1;
    min-width: 200px;
    margin-bottom: ${SPACING.LARGE};
    padding-right: ${SPACING.LARGE};
  }
`;

const FooterLogo = styled.div`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.SMALL};
`;

const FooterSlogan = styled.div`
  font-size: ${FONTS.SIZE_SECONDARY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLUE_SECONDARY};
  margin-bottom: ${SPACING.MEDIUM};
  font-style: italic;
`;

const FooterDescription = styled.p`
  color: ${COLORS.BLACK_TEXT}CC;
  margin-bottom: ${SPACING.MEDIUM};
`;

const FooterTitle = styled.h4`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLACK_TEXT};
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.li`
  margin-bottom: ${SPACING.SMALL};
  
  a {
    color: ${COLORS.BLACK_TEXT}CC;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${COLORS.BLUE_MAIN};
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${SPACING.MEDIUM};
  margin-top: ${SPACING.MEDIUM};
`;

const SocialIcon = styled.a`
  color: ${COLORS.BLACK_TEXT}CC;
  font-size: 20px;
  transition: color 0.2s ease;
  
  &:hover {
    color: ${COLORS.BLUE_MAIN};
  }
`;

const Copyright = styled.div`
  text-align: center;
  padding-top: ${SPACING.LARGE};
  margin-top: ${SPACING.LARGE};
  border-top: 1px solid ${COLORS.GREY_LIGHT}88;
  color: ${COLORS.BLACK_TEXT}99;
  font-size: ${FONTS.SIZE_SMALL};
  width: 100%;
  position: relative;
  z-index: 1;
`;

const ChannelBox = styled.a`
  background-color: ${COLORS.WHITE}99; /* 60% de opacidad */
  border-radius: ${SPACING.SMALL};
  padding: ${SPACING.MEDIUM};
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING.SMALL};
  border-left: 3px solid ${({ color }) => color || COLORS.BLUE_MAIN};
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  backdrop-filter: blur(8px);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: ${COLORS.WHITE}BB; /* Más opaco al hacer hover */
  }
`;

const ChannelIcon = styled.div`
  font-size: 24px;
  margin-right: ${SPACING.MEDIUM};
  color: ${({ color }) => color || COLORS.BLUE_MAIN};
`;

const ChannelInfo = styled.div`
  flex: 1;
`;

const ChannelTitle = styled.h5`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin: 0 0 ${SPACING.XXSMALL};
  color: ${COLORS.BLACK_TEXT};
`;

const ChannelDescription = styled.p`
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLACK_TEXT}99;
  margin: 0;
`;

const FullWidthBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(228, 228, 228, 0.08);
  backdrop-filter: blur(4px);
  z-index: -1;
`;

// URLs de ejemplo para enlaces
const WHATSAPP_URL = "https://wa.me/56977209949";
const COOKIES_URL = "/cookies";

const Footer = () => {
  // Función para descargar la app
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };

  // Función para abrir WhatsApp
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Section id="contacto">
      <FullWidthBackground />
      <Container>
        <FooterWrapper>
          <FooterContainer>
            <FooterColumn>
              <FooterLogo>TRINEO</FooterLogo>
              <FooterSlogan>Fletes más ágiles</FooterSlogan>
              <FooterDescription>
                Pedir un flete nunca fue tan fácil y seguro. Plataforma digital que agiliza y optimiza la gestión de fletes, conectando de forma rápida y eficiente a transportistas y usuarios.
              </FooterDescription>
              <SocialLinks>
                <SocialIcon href="https://www.facebook.com/TrineoApp" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                  <FaFacebook />
                </SocialIcon>
                <SocialIcon href="https://www.instagram.com/trineoapp/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <FaInstagram />
                </SocialIcon>
                <SocialIcon href="https://www.linkedin.com/company/trineo-spa" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <FaLinkedin />
                </SocialIcon>
              </SocialLinks>
            </FooterColumn>
            
            <FooterColumn>
              <FooterTitle>Enlaces Rápidos</FooterTitle>
              <FooterLinks>
                <FooterLink><a href="/app">Descargar App</a></FooterLink>
                <FooterLink><a href="/marketing">Marketing</a></FooterLink>
                <FooterLink><a href="/faq">Preguntas Frecuentes</a></FooterLink>
                <FooterLink><a href="/transportista">Para Transportistas</a></FooterLink>
                <FooterLink><a href="/soporte">Soporte</a></FooterLink>
                <FooterLink><a href="/nosotros">Quienes Somos</a></FooterLink>
              </FooterLinks>
            </FooterColumn>
            
            <FooterColumn>
              <FooterTitle>Legal</FooterTitle>
              <FooterLinks>
                <FooterLink><a href="/terminos">Términos y Condiciones</a></FooterLink>
                <FooterLink><a href="/privacidad">Política de Privacidad</a></FooterLink>
                <FooterLink><a href={COOKIES_URL}>Política de Cookies</a></FooterLink>
                <FooterLink><a href="/eliminar-cuenta">Eliminar Cuenta</a></FooterLink>
              </FooterLinks>
            </FooterColumn>
            
            <FooterColumn>
              <FooterTitle>Contacto Omnicanal</FooterTitle>
              <ChannelBox color={COLORS.BLUE_MAIN} href="/app">
                <ChannelIcon color={COLORS.BLUE_MAIN}>
                  <FaDownload />
                </ChannelIcon>
                <ChannelInfo>
                  <ChannelTitle>App Móvil</ChannelTitle>
                  <ChannelDescription>Descarga nuestra aplicación para Android o iOS</ChannelDescription>
                </ChannelInfo>
              </ChannelBox>
              
              <ChannelBox color="#4CAF50" href="#" onClick={(e) => { e.preventDefault(); openWhatsApp(); }}>
                <ChannelIcon color="#4CAF50">
                  <FaWhatsapp />
                </ChannelIcon>
                <ChannelInfo>
                  <ChannelTitle>WhatsApp</ChannelTitle>
                  <ChannelDescription>+56 9 7720 9949</ChannelDescription>
                </ChannelInfo>
              </ChannelBox>
              
              <ChannelBox color="#E74C3C" href="mailto:contacto@trineoapp.cl">
                <ChannelIcon color="#E74C3C">
                  <FaEnvelope />
                </ChannelIcon>
                <ChannelInfo>
                  <ChannelTitle>Correo Electrónico</ChannelTitle>
                  <ChannelDescription>contacto@trineoapp.cl</ChannelDescription>
                </ChannelInfo>
              </ChannelBox>
            </FooterColumn>
          </FooterContainer>
          
          <Copyright>
            © {new Date().getFullYear()} Trineo. Todos los derechos reservados.
          </Copyright>
        </FooterWrapper>
      </Container>
    </Section>
  );
};

export default Footer; 