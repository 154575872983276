import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS, SPACING } from '../../styles/variables';

const StyledSkipLink = styled.a`
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
  top: auto;
  overflow: hidden;
  background: ${COLORS.BLUE_MAIN};
  color: white;
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  z-index: 9999;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  
  &:focus {
    position: fixed;
    top: 10px;
    left: 10px;
    width: auto;
    height: auto;
    overflow: visible;
    outline: none;
    box-shadow: 0 0 0 3px ${COLORS.WHITE}, 0 0 0 6px ${COLORS.BLUE_MAIN};
  }
`;

export const SkipLink = ({ children, href }) => {
  return (
    <StyledSkipLink href={href} tabIndex="0">
      {children}
    </StyledSkipLink>
  );
};

export default SkipLink; 