import React from 'react';
import { FaEnvelope, FaHeadset, FaQuestionCircle, FaUserCog } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  position: relative;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const SupportContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: ${SPACING.XLARGE};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.LARGE};
  color: ${COLORS.BLUE_MAIN};
`;

const Introduction = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  margin-bottom: ${SPACING.XLARGE};
`;

const Section = styled.div`
  margin-bottom: ${SPACING.XLARGE};
`;

const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_SECONDARY};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: ${SPACING.SMALL};
    color: ${COLORS.BLUE_MAIN};
  }
`;

const Paragraph = styled.p`
  margin-bottom: ${SPACING.MEDIUM};
  line-height: 1.7;
`;

const ContactInfo = styled.div`
  background-color: ${COLORS.BLUE_MAIN}10;
  border-radius: 8px;
  padding: ${SPACING.LARGE};
  margin-top: ${SPACING.LARGE};
  border-left: 4px solid ${COLORS.BLUE_MAIN};
`;

const ContactEmail = styled.a`
  color: ${COLORS.BLUE_MAIN};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SupportPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Soporte" />
      
      <Content>
        <Container>
          <SupportContainer>
            <Title>Soporte</Title>
            <Introduction>
              Bienvenido a Trineo. Estamos comprometidos en brindarte una experiencia de transporte de carga fácil y sin complicaciones. Si necesitas ayuda con la aplicación, aquí encontrarás respuestas a las preguntas frecuentes que recibimos de nuestros usuarios.
            </Introduction>
            
            <Section>
              <SectionTitle>
                <FaQuestionCircle /> Ayuda con tu cuenta
              </SectionTitle>
              <Paragraph>
                Para comenzar, ¿tienes problemas con tu cuenta o tienes preguntas sobre cómo usar la aplicación? Consulta nuestra sección de "Preguntas frecuentes" para obtener información sobre cómo solucionar problemas comunes. Si no encuentras lo que buscas, no dudes en ponerte en contacto con nuestro equipo de soporte.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaHeadset /> Problemas de pago o facturación
              </SectionTitle>
              <Paragraph>
                También estamos disponibles para ayudarte con cualquier problema de pago o facturación que puedas tener. Si tienes preguntas sobre cargos, facturación o reembolsos, contáctanos y estaremos encantados de ayudarte.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaUserCog /> Ayuda para conductores
              </SectionTitle>
              <Paragraph>
                Si eres un conductor registrado en Trineo y necesitas ayuda con la aplicación o tienes preguntas sobre los pagos, te recomendamos que consultes nuestra sección "Ayuda para conductores". Allí encontrarás información detallada sobre cómo usar la aplicación, cómo aceptar y completar viajes, y cómo recibir pagos.
              </Paragraph>
            </Section>
            
            <Section>
              <SectionTitle>
                <FaEnvelope /> Contáctanos
              </SectionTitle>
              <Paragraph>
                En Trineo nos esforzamos por brindarte la mejor experiencia de transporte de carga posible. Si tienes algún problema o pregunta, no dudes en ponerte en contacto con nuestro equipo de soporte. Estamos aquí para ayudarte.
              </Paragraph>
              <ContactInfo>
                <Paragraph>
                  Escríbenos a <ContactEmail href="mailto:contacto@trineoapp.cl">contacto@trineoapp.cl</ContactEmail>
                </Paragraph>
              </ContactInfo>
            </Section>
          </SupportContainer>
        </Container>
      </Content>
      <Footer />
    </PageWrapper>
  );
};

export default SupportPage; 