import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import { COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  padding: ${SPACING.XLARGE};
`;

const CallbackCard = styled.div`
  position: relative;
  z-index: 2;
  max-width: 600px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: ${SPACING.XLARGE};
  text-align: center;
`;

const Title = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_DARK};
  margin-bottom: ${SPACING.MEDIUM};
`;

const Message = styled.p`
  font-size: ${FONTS.SIZE_MEDIUM};
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.MEDIUM};
`;

const LoadingSpinner = styled.div`
  margin: ${SPACING.MEDIUM} auto;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${COLORS.BLUE_MAIN};
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  padding: ${SPACING.MEDIUM};
  background-color: #ffe8e8;
  border-radius: 8px;
  color: #d32f2f;
  margin: ${SPACING.MEDIUM} 0;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  text-align: left;
  overflow-wrap: break-word;
`;

const DetailSection = styled.div`
  margin-top: ${SPACING.MEDIUM};
  text-align: left;
  background-color: #f5f5f5;
  padding: ${SPACING.MEDIUM};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 14px;
`;

const DetailTitle = styled.h3`
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: 5px;
  color: ${COLORS.BLUE_DARK};
`;

const Button = styled.button`
  padding: 10px 20px;
  background: ${props => props.secondary ? COLORS.GREY_MEDIUM : COLORS.BLUE_MAIN};
  color: ${props => props.secondary ? COLORS.BLACK_TEXT : COLORS.WHITE};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin: 0 5px;
  transition: all 0.3s ease;
  
  &:hover {
    background: ${props => props.secondary ? '#d0d0d0' : '#1565C0'};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${SPACING.MEDIUM};
  gap: 10px;
`;

const AuthCallbackPage = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [errorMessage, setErrorMessage] = useState('');
  const [details, setDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  
  useEffect(() => {
    const processAuth = async () => {
      // Obtener los parámetros completos de la URL
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const error = urlParams.get('error');
      const code = urlParams.get('code');
      
      // Almacenar todos los parámetros para depuración
      const allParams = {};
      for (const [key, value] of urlParams.entries()) {
        allParams[key] = value;
      }
      setDetails(allParams);
      
      // Si hay un error en la URL, mostrar el error
      if (error) {
        setStatus('error');
        setErrorMessage(decodeURIComponent(error));
        return;
      }
      
      // Si hay un código de Google pero no un token, puede ser que estemos en la URL incorrecta
      if (code && !token) {
        console.log('Se recibió un código de autorización de Google pero no un token JWT');
        setStatus('error');
        setErrorMessage('Se recibió un código de autorización de Google pero el servidor no generó un token JWT. Verifica la configuración del servidor.');
        return;
      }
      
      // Si no hay token ni código, mostrar error
      if (!token && !code) {
        setStatus('error');
        setErrorMessage('No se recibió un token de autenticación válido ni un código de autorización');
        return;
      }
      
      try {
        if (token) {
          // Decodificar el token JWT para obtener información del usuario
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          
          // Guardar el token en localStorage
          localStorage.setItem('auth_token', token);
          
          // Verificar si el usuario existe ya en el sistema
          if (decodedToken.userExists === true) {
            // Si el usuario existe, redirigir al dashboard
            setStatus('success');
            setTimeout(() => {
              navigate('/dashboard');
            }, 1500);
          } else {
            // Si el usuario no existe, redirigir al formulario de registro
            setStatus('new_user');
            setTimeout(() => {
              navigate('/register');
            }, 1500);
          }
        } else {
          setStatus('error');
          setErrorMessage('Error al procesar la autenticación. El servidor no generó un token JWT.');
        }
      } catch (error) {
        console.error('Error al procesar el token:', error);
        setStatus('error');
        setErrorMessage(`Error al procesar la autenticación: ${error.message || 'Error desconocido'}`);
      }
    };
    
    processAuth();
  }, [navigate]);
  
  return (
    <PageWrapper>
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <CallbackCard>
        {status === 'loading' && (
          <>
            <Title>Procesando autenticación</Title>
            <Message>Por favor espere mientras verificamos su información...</Message>
            <LoadingSpinner />
          </>
        )}
        
        {status === 'success' && (
          <>
            <Title>¡Autenticación exitosa!</Title>
            <Message>Redirigiendo al dashboard...</Message>
            <LoadingSpinner />
          </>
        )}
        
        {status === 'new_user' && (
          <>
            <Title>¡Bienvenido a Trineo!</Title>
            <Message>Necesitamos algunos datos más para completar tu registro.</Message>
            <LoadingSpinner />
          </>
        )}
        
        {status === 'error' && (
          <>
            <Title>Error de autenticación</Title>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            
            {Object.keys(details).length > 0 && (
              <>
                <Button 
                  secondary 
                  onClick={() => setShowDetails(!showDetails)}
                >
                  {showDetails ? 'Ocultar detalles técnicos' : 'Mostrar detalles técnicos'}
                </Button>
                
                {showDetails && (
                  <DetailSection>
                    <DetailTitle>Parámetros de la URL:</DetailTitle>
                    <pre>{JSON.stringify(details, null, 2)}</pre>
                    
                    {details.code && (
                      <>
                        <DetailTitle>Código de autorización de Google:</DetailTitle>
                        <div>Código recibido correctamente. El problema está en el procesamiento del servidor.</div>
                      </>
                    )}
                    
                    <DetailTitle>Sugerencias:</DetailTitle>
                    <ul>
                      <li>Verifica que la URL de redirección esté configurada correctamente en Google Cloud Console.</li>
                      <li>Asegúrate de que el servidor esté procesando correctamente el código de autorización.</li>
                      <li>Confirma que el ID de cliente y secreto de Google estén configurados correctamente en el servidor.</li>
                    </ul>
                  </DetailSection>
                )}
              </>
            )}
            
            <ButtonGroup>
              <Button onClick={() => navigate('/login')}>
                Volver a intentar
              </Button>
              
              <Button 
                secondary
                onClick={() => window.open('https://console.cloud.google.com/', '_blank')}
              >
                Google Cloud Console
              </Button>
            </ButtonGroup>
          </>
        )}
      </CallbackCard>
    </PageWrapper>
  );
};

export default AuthCallbackPage; 