import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../../assets/images/logo_r.png';
import { BREAKPOINTS, COLORS, FONTS, SHADOWS, SPACING } from '../../styles/variables';
import Container from '../Container/Container';

const HeaderWrapper = styled.header`
  padding: ${props => props.$scrolled ? SPACING.XSMALL : SPACING.SMALL} 0;
  background-color: ${props => props.$scrolled ? COLORS.WHITE + 'A0' : COLORS.WHITE + '20'};
  backdrop-filter: ${props => props.$scrolled ? 'blur(3px)' : 'blur(1.5px)'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease, padding 0.3s ease, background-color 0.3s ease, backdrop-filter 0.3s ease;
  ${({ $scrolled }) => $scrolled && `
    box-shadow: ${SHADOWS.MEDIUM};
  `}
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${props => props.$scrolled ? SPACING.SMALL : SPACING.MEDIUM} 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
    color: ${COLORS.BLUE_MAIN};
  }
`;

const LogoImage = styled.img`
  height: ${props => props.$scrolled ? '45px' : '60px'};
  width: auto;
  transition: height 0.3s ease;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    height: ${props => props.$scrolled ? '50px' : '70px'};
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: ${props => props.$scrolled ? '40px' : '50px'};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.SMALL};
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  color: ${COLORS.BLUE_MAIN};
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  font-size: ${FONTS.SIZE_BODY};
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: ${SPACING.XSMALL} ${SPACING.SMALL};
  border-radius: ${SPACING.SMALL};
  background-color: ${COLORS.WHITE}40;
  backdrop-filter: blur(5px);
  
  &:hover {
    color: ${COLORS.BLUE_SECONDARY};
    background-color: ${COLORS.WHITE}60;
  }
  
  svg {
    margin-right: ${SPACING.XSMALL};
  }
`;

const LoginButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  color: ${COLORS.WHITE};
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  transition: background-color 0.2s ease, transform 0.2s ease;
  border-radius: 50%;
  background-color: ${COLORS.BLUE_MAIN};
  
  &:hover {
    background-color: ${COLORS.BLUE_DARK};
    transform: translateY(-2px);
  }
`;

const ProfileButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  color: ${COLORS.WHITE};
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  transition: background-color 0.2s ease, transform 0.2s ease;
  border-radius: 50%;
  background-color: ${COLORS.BLUE_MAIN};
  
  &:hover {
    background-color: ${COLORS.BLUE_DARK};
    transform: translateY(-2px);
  }
`;

const SubpageHeader = ({ title }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    // Verificar si el usuario está autenticado
    const checkAuth = () => {
      const token = localStorage.getItem('auth_token');
      setIsLoggedIn(!!token);
    };
    
    window.addEventListener('scroll', handleScroll);
    checkAuth();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <HeaderWrapper $scrolled={scrolled}>
      <Container>
        <HeaderContainer>
          <Logo as={Link} to="/">
            <LogoImage src={logoImage} alt="Trineo Logo" $scrolled={scrolled} />
          </Logo>
          
          <ButtonsContainer>
            <Button as={Link} to="/">
              <FaArrowLeft /> Inicio
            </Button>
            
            {/* Botón de login/perfil temporalmente oculto mientras OAuth está en verificación 
            {isLoggedIn ? (
              <ProfileButton to="/dashboard" title="Mi perfil">
                <FaUser />
              </ProfileButton>
            ) : (
              <LoginButton to="/login" title="Iniciar sesión">
                <FaUser />
              </LoginButton>
            )}
            */}
          </ButtonsContainer>
        </HeaderContainer>
      </Container>
    </HeaderWrapper>
  );
};

export default SubpageHeader; 