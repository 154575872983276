import React, { useEffect } from 'react';
import { FaMobileAlt } from 'react-icons/fa';
import styled from 'styled-components';
import logoImage from '../../assets/images/logo_r.png';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING.XLARGE} 0;
  position: relative;
  overflow: visible;
  z-index: 2;
`;

const SuccessCard = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: ${SPACING.XXLARGE} ${SPACING.XLARGE};
  text-align: center;
  position: relative;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
    margin: 0 ${SPACING.MEDIUM};
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${SPACING.XLARGE};
`;

const LogoImage = styled.img`
  height: 80px;
  width: auto;
`;

const Title = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.LARGE};
`;

const Message = styled.p`
  font-size: ${FONTS.SIZE_LARGE};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
`;

const ReturnMessage = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.XLARGE};
`;

const StyledButton = styled.button`
  background: linear-gradient(135deg, ${COLORS.BLUE_MAIN}, ${COLORS.BLUE_SECONDARY});
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 36px;
  font-size: ${FONTS.SIZE_LARGE};
  font-weight: ${FONTS.WEIGHT_BOLD};
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(10, 75, 129, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(10, 75, 129, 0.4);
  }
  
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(10, 75, 129, 0.3);
  }
  
  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${SPACING.LARGE};
`;

const CardSuccessPage = () => {
  // Función para redirigir a la app después de un tiempo
  useEffect(() => {
    // Intentar obtener la URL de la app desde los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect_url');
    
    // Si hay una URL de redirección, configurar un temporizador para redirigir
    if (redirectUrl) {
      const timer = setTimeout(() => {
        window.location.href = redirectUrl;
      }, 5000); // Redirigir después de 5 segundos
      
      // Limpiar el temporizador si el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, []);

  // Función para abrir la app
  const openApp = () => {
    // Intentar obtener la URL de la app desde los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect_url');
    
    // Si hay una URL de redirección, usarla; de lo contrario, usar una URL predeterminada
    const appUrl = redirectUrl || 'trineo://';
    
    // Intentar abrir la app
    window.location.href = appUrl;
  };

  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <MainContent>
        <Container>
          <SuccessCard>
            <Logo>
              <LogoImage src={logoImage} alt="Trineo Logo" />
            </Logo>
            
            <Title>Tarjeta Registrada Exitosamente</Title>
            
            <Message>Tu tarjeta ha sido agregada correctamente a tu cuenta.</Message>
            
            <ReturnMessage>Regresa a la app Trineo para continuar.</ReturnMessage>
            
            <ButtonContainer>
              <StyledButton onClick={openApp}>
                <FaMobileAlt /> Volver a la App
              </StyledButton>
            </ButtonContainer>
          </SuccessCard>
        </Container>
      </MainContent>
    </PageWrapper>
  );
};

export default CardSuccessPage; 