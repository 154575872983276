import React from 'react';
import SEO from './SEO';

/**
 * Componente SEO específico para la página de inicio
 * Incluye datos estructurados avanzados para mejorar la visibilidad en buscadores
 */
const HomePageSEO = () => {
  // Datos estructurados para la página de inicio
  const homePageSchema = {
    '@context': 'https://schema.org',
    '@graph': [
      // Información de la organización
      {
        '@type': 'Organization',
        '@id': 'https://www.trineoapp.com/#organization',
        'name': 'Trineo',
        'url': 'https://www.trineoapp.com',
        'logo': {
          '@type': 'ImageObject',
          '@id': 'https://www.trineoapp.com/#logo',
          'inLanguage': 'es-CL',
          'url': 'https://www.trineoapp.com/logo.png',
          'width': 512,
          'height': 512,
          'caption': 'Trineo - Solución de fletes y transporte'
        },
        'sameAs': [
          'https://www.facebook.com/trineoapp',
          'https://www.instagram.com/trineoapp',
          'https://twitter.com/trineoapp'
        ]
      },
      // Información de la página web
      {
        '@type': 'WebSite',
        '@id': 'https://www.trineoapp.com/#website',
        'url': 'https://www.trineoapp.com',
        'name': 'Trineo',
        'description': 'Solución de fletes y transporte para tus necesidades logísticas',
        'publisher': {
          '@id': 'https://www.trineoapp.com/#organization'
        },
        'inLanguage': 'es-CL'
      },
      // Información de la página de inicio
      {
        '@type': 'WebPage',
        '@id': 'https://www.trineoapp.com/#webpage',
        'url': 'https://www.trineoapp.com',
        'name': 'Trineo - Solución de fletes y transporte para tus necesidades logísticas',
        'isPartOf': {
          '@id': 'https://www.trineoapp.com/#website'
        },
        'about': {
          '@id': 'https://www.trineoapp.com/#organization'
        },
        'description': 'Trineo conecta usuarios con transportistas para fletes y traslados de forma rápida y segura. Disponible vía app móvil y WhatsApp.',
        'inLanguage': 'es-CL',
        'potentialAction': [
          {
            '@type': 'ReadAction',
            'target': ['https://www.trineoapp.com']
          }
        ]
      },
      // Información del software (aplicación)
      {
        '@type': 'SoftwareApplication',
        'name': 'Trineo App',
        'operatingSystem': 'Android, iOS',
        'applicationCategory': 'TransportationApplication',
        'offers': {
          '@type': 'Offer',
          'price': '0',
          'priceCurrency': 'CLP'
        },
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '4.8',
          'ratingCount': '1250'
        }
      },
      // Información del servicio
      {
        '@type': 'Service',
        'name': 'Servicio de Fletes y Transporte Trineo',
        'serviceType': 'Transporte y Logística',
        'provider': {
          '@id': 'https://www.trineoapp.com/#organization'
        },
        'description': 'Servicio de fletes y transporte para particulares y empresas. Conectamos usuarios con transportistas verificados para traslados seguros y eficientes.',
        'areaServed': {
          '@type': 'Country',
          'name': 'Chile'
        }
      }
    ]
  };

  return (
    <SEO 
      title="Trineo - Solución de fletes y transporte para tus necesidades logísticas"
      description="Trineo conecta usuarios con transportistas para fletes y traslados de forma rápida y segura. Disponible vía app móvil y WhatsApp."
      keywords="fletes, transporte, logística, app de fletes, servicio de transporte, mudanzas, traslados, Chile, Santiago"
      canonicalUrl="https://www.trineoapp.com"
      ogImage="https://www.trineoapp.com/images/trineo-og-image.jpg"
      schema={homePageSchema}
    />
  );
};

export default HomePageSEO; 