import React from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS, SPACING } from '../../styles/variables';
import Container from '../Container/Container';

const SectionWrapper = styled.section`
  padding: ${SPACING.LARGE} 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.XLARGE} 0;
  }
  
  ${({ background }) => background === 'light' && css`
    background-color: ${COLORS.GREY_LIGHT}33;
  `}
  
  ${({ background }) => background === 'dark' && css`
    background-color: ${COLORS.BLUE_SECONDARY};
    color: ${COLORS.WHITE};
  `}
  
  ${({ background }) => background === 'primary' && css`
    background-color: ${COLORS.BLUE_MAIN}CC; /* CC = 80% opacidad */
    color: ${COLORS.WHITE};
  `}
  
  ${({ spacingTop }) => {
    if (spacingTop === 'small') return css`
      padding-top: ${SPACING.SMALL};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-top: ${SPACING.MEDIUM};
      }
    `;
    if (spacingTop === 'large') return css`
      padding-top: ${SPACING.XLARGE};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-top: ${SPACING.XXLARGE};
      }
    `;
    if (spacingTop === 'none') return css`padding-top: 0;`;
    return css`
      padding-top: ${SPACING.LARGE};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-top: ${SPACING.XLARGE};
      }
    `;
  }}
  
  ${({ spacingBottom }) => {
    if (spacingBottom === 'small') return css`
      padding-bottom: ${SPACING.SMALL};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-bottom: ${SPACING.MEDIUM};
      }
    `;
    if (spacingBottom === 'large') return css`
      padding-bottom: ${SPACING.XLARGE};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-bottom: ${SPACING.XXLARGE};
      }
    `;
    if (spacingBottom === 'none') return css`padding-bottom: 0;`;
    return css`
      padding-bottom: ${SPACING.LARGE};
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        padding-bottom: ${SPACING.XLARGE};
      }
    `;
  }}
`;

const Section = ({
  children,
  background = 'white',
  spacingTop = 'medium',
  spacingBottom = 'medium',
  containerProps = {},
  ...props
}) => {
  return (
    <SectionWrapper
      background={background}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      {...props}
    >
      <Container {...containerProps}>
        {children}
      </Container>
    </SectionWrapper>
  );
};

export default Section; 