import React from 'react';
import { FaAndroid, FaApple, FaDownload } from 'react-icons/fa';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Button from '../../components/Button/Button';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  position: relative;
  overflow: visible;
  z-index: 2;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const AppContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background-color: ${COLORS.BLUE_SECONDARY};
    border-radius: 2px;
  }
`;

const PageDescription = styled.p`
  font-size: ${FONTS.SIZE_HEADING_3};
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.XLARGE};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const DownloadOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.LARGE};
  margin-top: ${SPACING.XLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
    justify-content: center;
  }
`;

const DownloadCard = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.LARGE};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1;
  max-width: 300px;
  margin: 0 auto;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 0;
  }
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
`;

const PlatformIcon = styled.div`
  font-size: 48px;
  color: ${props => props.color || COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
`;

const PlatformTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_3};
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.SMALL};
`;

const PlatformDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  color: ${COLORS.BLACK_TEXT}CC;
  margin-bottom: ${SPACING.LARGE};
  flex-grow: 1;
`;

const FloatingParticle = styled.div`
  position: absolute;
  width: ${props => props.size || '20px'};
  height: ${props => props.size || '20px'};
  border-radius: 50%;
  background-color: ${props => props.color || COLORS.BLUE_MAIN};
  opacity: ${props => props.opacity || '0.1'};
  top: ${props => props.top || '10%'};
  left: ${props => props.left || '10%'};
  animation: float ${props => props.duration || '10s'} ease-in-out infinite;
  animation-delay: ${props => props.delay || '0s'};
  
  @keyframes float {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(20px) translateX(10px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
`;

const AppPage = () => {
  // Función para descargar la app de Android
  const downloadAndroidApp = () => {
    window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
  };
  
  // Función para descargar la app de iOS
  const downloadIOSApp = () => {
    window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
  };
  
  return (
    <PageWrapper>
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        backgroundColor: '#f8f9fa',
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Descarga la App" />
      
      <MainContent>
        <Container>
          <AppContainer>
            <PageTitle>Descarga Trineo App</PageTitle>
            <PageDescription>
              Pedir un flete nunca fue tan fácil y seguro. Descarga nuestra aplicación en tu dispositivo móvil y comienza a disfrutar de fletes más ágiles.
            </PageDescription>
            
            <DownloadOptions>
              <DownloadCard>
                <PlatformIcon color="#3DDC84">
                  <FaAndroid />
                </PlatformIcon>
                <PlatformTitle>Android</PlatformTitle>
                <PlatformDescription>
                  Descarga nuestra app para dispositivos Android desde Google Play Store.
                </PlatformDescription>
                <Button variant="primary" size="large" onClick={downloadAndroidApp}>
                  <FaDownload style={{ marginRight: '8px' }} /> Descargar
                </Button>
              </DownloadCard>
              
              <DownloadCard>
                <PlatformIcon color="#000000">
                  <FaApple />
                </PlatformIcon>
                <PlatformTitle>iOS</PlatformTitle>
                <PlatformDescription>
                  Descarga nuestra app para iPhone y iPad desde la App Store.
                </PlatformDescription>
                <Button variant="primary" size="large" onClick={downloadIOSApp}>
                  <FaDownload style={{ marginRight: '8px' }} /> Descargar
                </Button>
              </DownloadCard>
            </DownloadOptions>
          </AppContainer>
          
          {/* Partículas flotantes para efecto visual */}
          <FloatingParticle size="30px" color={COLORS.BLUE_MAIN} opacity="0.05" top="10%" left="5%" duration="15s" />
          <FloatingParticle size="20px" color={COLORS.BLUE_MAIN} opacity="0.07" top="70%" left="15%" duration="12s" delay="2s" />
          <FloatingParticle size="25px" color={COLORS.BLUE_SECONDARY} opacity="0.06" top="30%" left="80%" duration="18s" delay="1s" />
          <FloatingParticle size="15px" color={COLORS.BLUE_SECONDARY} opacity="0.08" top="80%" left="70%" duration="10s" delay="3s" />
        </Container>
      </MainContent>
      
      <Footer />
    </PageWrapper>
  );
};

export default AppPage; 