import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import AddToHomeScreen from './components/AddToHomeScreen/AddToHomeScreen';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import FloatingButton from './components/FloatingButton/FloatingButton';
import HomePage from './pages/HomePage';
import AboutPage from './pages/about/AboutPage';
import AppPage from './pages/app/AppPage';
import AuthCallbackPage from './pages/auth/AuthCallbackPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import CardSuccessPage from './pages/card-success/CardSuccessPage';
import ContactPage from './pages/contact/ContactPage';
import CookiesPage from './pages/cookies/CookiesPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DeleteAccountPage from './pages/delete-account/DeleteAccountPage';
import FaqPage from './pages/faq/FaqPage';
import MarketingPage from './pages/marketing/MarketingPage';
import PrivacyPage from './pages/privacy/PrivacyPage';
import SupportPage from './pages/support/SupportPage';
import TermsPage from './pages/terms/TermsPage';
import TransportistaPage from './pages/transportista/TransportistaPage';
import { COLORS, FONTS } from './styles/variables';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: ${COLORS.BLACK_TEXT};
    background-color: ${COLORS.WHITE};
    font-size: ${FONTS.SIZE_BODY};
    line-height: 1.6;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  /* Optimizaciones para dispositivos táctiles */
  @media (hover: none) and (pointer: coarse) {
    /* Aumentar tamaño de elementos interactivos en móviles */
    button, 
    a, 
    input[type="button"], 
    input[type="submit"],
    .clickable {
      min-height: 44px;
      min-width: 44px;
    }
    
    /* Mejorar espaciado para facilitar el toque */
    nav a, 
    .menu-item,
    .touch-target {
      padding: 12px;
      margin: 4px;
    }
  }
`;

const App = () => {
  return (
    <HelmetProvider>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/app" element={<AppPage />} />
          <Route path="/nosotros" element={<AboutPage />} />
          <Route path="/terminos" element={<TermsPage />} />
          <Route path="/privacidad" element={<PrivacyPage />} />
          <Route path="/cookies" element={<CookiesPage />} />
          <Route path="/soporte" element={<SupportPage />} />
          <Route path="/marketing" element={<MarketingPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/eliminar-cuenta" element={<DeleteAccountPage />} />
          <Route path="/tarjeta-registrada" element={<CardSuccessPage />} />
          <Route path="/contacto" element={<ContactPage />} />
          <Route path="/transportista" element={<TransportistaPage />} />
          
          {/* Rutas de autenticación */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/callback" element={<AuthCallbackPage />} />
          
          {/* Rutas protegidas */}
          <Route 
            path="/register" 
            element={
              <ProtectedRoute>
                <RegisterPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            } 
          />
        </Routes>
        <FloatingButton />
        <AddToHomeScreen />
      </Router>
    </HelmetProvider>
  );
};

export default App;
