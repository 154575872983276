import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Estilos
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 100px;
  }
`;

const RegisterSection = styled.div`
  position: relative;
  padding: ${SPACING.XLARGE} 0;
  margin: 40px 0 60px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 60px 0;
  }
`;

const RegisterContent = styled.div`
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: ${SPACING.LARGE};
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.XLARGE};
  }
`;

const RegisterTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_DARK};
  margin-bottom: ${SPACING.MEDIUM};
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background-color: ${COLORS.BLUE_MAIN};
    margin: ${SPACING.SMALL} auto;
    border-radius: 2px;
  }
`;

const RegisterDescription = styled.p`
  text-align: center;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
`;

const FormGroup = styled.div`
  margin-bottom: ${SPACING.MEDIUM};
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.MEDIUM};
  margin-bottom: ${SPACING.MEDIUM};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
  }
`;

const FormColumn = styled.div`
  flex: 1;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: ${SPACING.SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${COLORS.BLACK_TEXT};
`;

const FormInput = styled.input`
  width: 100%;
  padding: ${SPACING.MEDIUM};
  border: 1px solid ${props => props.error ? '#d32f2f' : COLORS.GREY_MEDIUM};
  border-radius: 8px;
  font-size: ${FONTS.SIZE_MEDIUM};
  background-color: ${COLORS.WHITE};
  transition: border-color 0.3s, box-shadow 0.3s;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.BLUE_MAIN};
    box-shadow: 0 0 0 2px ${COLORS.BLUE_LIGHT}80;
  }
  
  &:disabled {
    background-color: ${COLORS.GREY_LIGHT};
    cursor: not-allowed;
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: ${SPACING.MEDIUM};
  border: 1px solid ${props => props.error ? '#d32f2f' : COLORS.GREY_MEDIUM};
  border-radius: 8px;
  font-size: ${FONTS.SIZE_MEDIUM};
  background-color: ${COLORS.WHITE};
  transition: border-color 0.3s, box-shadow 0.3s;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.BLUE_MAIN};
    box-shadow: 0 0 0 2px ${COLORS.BLUE_LIGHT}80;
  }
`;

const ErrorText = styled.p`
  color: #d32f2f;
  font-size: ${FONTS.SIZE_SMALL};
  margin-top: 5px;
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  padding: ${SPACING.MEDIUM};
  background: linear-gradient(135deg, ${COLORS.BLUE_MAIN} 0%, ${COLORS.BLUE_DARK} 100%);
  color: ${COLORS.WHITE};
  border: none;
  border-radius: 8px;
  font-size: ${FONTS.SIZE_MEDIUM};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: ${SPACING.LARGE};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    background: ${COLORS.GREY_MEDIUM};
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: ${COLORS.WHITE};
  animation: spin 1s ease-in-out infinite;
  margin: 0 auto;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const AlertMessage = styled.div`
  padding: ${SPACING.MEDIUM};
  background-color: ${props => props.type === 'error' ? '#ffe8e8' : '#e8f4fd'};
  border-radius: 8px;
  color: ${props => props.type === 'error' ? '#d32f2f' : COLORS.BLUE_DARK};
  margin: ${SPACING.MEDIUM} 0;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
`;

const RegisterPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: '', message: '' });
  
  const [userData, setUserData] = useState({
    email: '',
    name: '',
    phone: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    city: '',
    region: '',
    postalCode: '',
    country: 'Chile', // Valor predeterminado
    acceptTerms: false
  });
  
  const [errors, setErrors] = useState({});
  
  useEffect(() => {
    // Verificar si hay un token en localStorage
    const token = localStorage.getItem('auth_token');
    
    if (!token) {
      // Si no hay token, redirigir a la página de login
      navigate('/login');
      return;
    }
    
    try {
      // Decodificar el token JWT para obtener información del usuario
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      
      // Verificar si el usuario ya existe en el sistema
      if (decodedToken.userExists === true) {
        // Si el usuario ya existe, redirigir al dashboard
        navigate('/dashboard');
        return;
      }
      
      // Prellenar el formulario con la información del usuario de Google
      setUserData(prevData => ({
        ...prevData,
        email: decodedToken.email || '',
        name: decodedToken.name || ''
      }));
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      setAlert({
        show: true,
        type: 'error',
        message: 'Error al recuperar la información de la sesión. Por favor, inicie sesión nuevamente.'
      });
      
      // En caso de error, limpiar el token y redirigir a login
      setTimeout(() => {
        localStorage.removeItem('auth_token');
        navigate('/login');
      }, 3000);
    }
  }, [navigate]);
  
  const validate = () => {
    const newErrors = {};
    
    if (!userData.name) newErrors.name = 'El nombre es obligatorio';
    if (!userData.phone) newErrors.phone = 'El teléfono es obligatorio';
    if (!userData.gender) newErrors.gender = 'El género es obligatorio';
    
    // Validación adicional según tus requisitos
    if (userData.phone && !/^\+?[0-9]{8,15}$/.test(userData.phone)) {
      newErrors.phone = 'Número de teléfono inválido';
    }
    
    if (!userData.acceptTerms) {
      newErrors.acceptTerms = 'Debe aceptar los términos y condiciones';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    // Limpiar el error cuando el usuario modifica el campo
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validate()) return;
    
    setIsLoading(true);
    setAlert({ show: false, type: '', message: '' });
    
    try {
      // Obtener el token de autenticación
      const token = localStorage.getItem('auth_token');
      
      if (!token) {
        throw new Error('No se encontró un token de autenticación');
      }
      
      // Enviar los datos al backend para completar el registro
      const response = await fetch(`${process.env.REACT_APP_API_URL || ''}/api/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData)
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al registrar el usuario');
      }
      
      // Registro exitoso
      setAlert({
        show: true,
        type: 'success',
        message: '¡Registro exitoso! Redirigiendo al dashboard...'
      });
      
      // Redirigir al dashboard después de unos segundos
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
      
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      setAlert({
        show: true,
        type: 'error',
        message: error.message || 'Error al registrar el usuario. Por favor, intente nuevamente.'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <PageWrapper>
      <Helmet>
        <title>Completar Registro - Trineo</title>
        <meta name="description" content="Complete su registro en Trineo para acceder a todos los servicios." />
      </Helmet>
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Completar Registro" />
      
      <MainContent>
        <Container>
          <RegisterSection>
            <RegisterContent>
              <RegisterTitle>Completar Registro</RegisterTitle>
              <RegisterDescription>
                Para finalizar tu registro, necesitamos algunos datos adicionales.
              </RegisterDescription>
              
              {alert.show && (
                <AlertMessage type={alert.type}>
                  {alert.message}
                </AlertMessage>
              )}
              
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                      <FormInput 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={userData.email} 
                        disabled 
                        title="El correo electrónico no se puede modificar" 
                      />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="name">Nombre completo *</FormLabel>
                      <FormInput 
                        type="text" 
                        id="name" 
                        name="name" 
                        value={userData.name} 
                        onChange={handleChange} 
                        error={errors.name}
                      />
                      {errors.name && <ErrorText>{errors.name}</ErrorText>}
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="phone">Teléfono *</FormLabel>
                      <FormInput 
                        type="tel" 
                        id="phone" 
                        name="phone" 
                        placeholder="+56912345678" 
                        value={userData.phone} 
                        onChange={handleChange} 
                        error={errors.phone}
                      />
                      {errors.phone && <ErrorText>{errors.phone}</ErrorText>}
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="gender">Género *</FormLabel>
                      <FormSelect 
                        id="gender" 
                        name="gender" 
                        value={userData.gender} 
                        onChange={handleChange} 
                        error={errors.gender}
                      >
                        <option value="">Seleccionar</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                        <option value="O">Otro</option>
                      </FormSelect>
                      {errors.gender && <ErrorText>{errors.gender}</ErrorText>}
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                
                <FormGroup>
                  <FormLabel htmlFor="dateOfBirth">Fecha de nacimiento</FormLabel>
                  <FormInput 
                    type="date" 
                    id="dateOfBirth" 
                    name="dateOfBirth" 
                    value={userData.dateOfBirth} 
                    onChange={handleChange} 
                    max={new Date().toISOString().split('T')[0]}
                  />
                </FormGroup>
                
                <FormGroup>
                  <FormLabel htmlFor="address">Dirección</FormLabel>
                  <FormInput 
                    type="text" 
                    id="address" 
                    name="address" 
                    value={userData.address} 
                    onChange={handleChange} 
                  />
                </FormGroup>
                
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="city">Ciudad</FormLabel>
                      <FormInput 
                        type="text" 
                        id="city" 
                        name="city" 
                        value={userData.city} 
                        onChange={handleChange} 
                      />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="region">Región</FormLabel>
                      <FormInput 
                        type="text" 
                        id="region" 
                        name="region" 
                        value={userData.region} 
                        onChange={handleChange} 
                      />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                
                <FormRow>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="postalCode">Código postal</FormLabel>
                      <FormInput 
                        type="text" 
                        id="postalCode" 
                        name="postalCode" 
                        value={userData.postalCode} 
                        onChange={handleChange} 
                      />
                    </FormGroup>
                  </FormColumn>
                  <FormColumn>
                    <FormGroup>
                      <FormLabel htmlFor="country">País</FormLabel>
                      <FormInput 
                        type="text" 
                        id="country" 
                        name="country" 
                        value={userData.country} 
                        onChange={handleChange} 
                        disabled
                      />
                    </FormGroup>
                  </FormColumn>
                </FormRow>
                
                <FormGroup style={{ marginTop: SPACING.MEDIUM }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <input 
                      type="checkbox" 
                      id="acceptTerms" 
                      name="acceptTerms" 
                      checked={userData.acceptTerms} 
                      onChange={handleChange}
                      style={{ marginRight: '10px', marginTop: '5px' }}
                    />
                    <FormLabel htmlFor="acceptTerms" style={{ marginBottom: 0 }}>
                      Acepto los <a href="/terminos" target="_blank" rel="noopener noreferrer">términos y condiciones</a> y la <a href="/privacidad" target="_blank" rel="noopener noreferrer">política de privacidad</a> *
                    </FormLabel>
                  </div>
                  {errors.acceptTerms && <ErrorText>{errors.acceptTerms}</ErrorText>}
                </FormGroup>
                
                <SubmitButton type="submit" disabled={isLoading}>
                  {isLoading ? <LoadingSpinner /> : 'Completar Registro'}
                </SubmitButton>
              </form>
            </RegisterContent>
          </RegisterSection>
        </Container>
      </MainContent>
      
      <Footer />
    </PageWrapper>
  );
};

export default RegisterPage; 