import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * Componente SEO para optimizar el SEO on-page
 * @param {Object} props - Propiedades del componente
 * @param {string} props.title - Título de la página
 * @param {string} props.description - Descripción de la página
 * @param {string} props.keywords - Palabras clave separadas por comas
 * @param {string} props.canonicalUrl - URL canónica de la página
 * @param {string} props.ogImage - URL de la imagen para Open Graph
 * @param {string} props.ogType - Tipo de contenido para Open Graph
 * @param {Object} props.schema - Datos estructurados Schema.org en formato JSON
 */
const SEO = ({
  title = 'Trineo - Solución de fletes y transporte para tus necesidades logísticas',
  description = 'Trineo conecta usuarios con transportistas para fletes y traslados de forma rápida y segura. Disponible vía app móvil y WhatsApp.',
  keywords = 'fletes, transporte, logística, app de fletes, servicio de transporte, mudanzas, traslados',
  canonicalUrl = 'https://www.trineoapp.com',
  ogImage = 'https://www.trineoapp.com/images/og-image.jpg',
  ogType = 'website',
  schema = null,
}) => {
  // Schema.org por defecto para la organización
  const defaultSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Trineo',
    'url': 'https://www.trineoapp.com',
    'logo': 'https://www.trineoapp.com/logo.png',
    'sameAs': [
      'https://www.facebook.com/TrineoApp',
      'https://www.instagram.com/trineoapp/'
    ],
    'description': description
  };

  // Usar el schema proporcionado o el predeterminado
  const schemaData = schema || defaultSchema;

  return (
    <Helmet>
      {/* Metadatos básicos */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Metadatos para dispositivos móviles */}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      <meta name="theme-color" content="#1976D2" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      
      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
      
      {/* Preconectar a dominios externos importantes */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
    </Helmet>
  );
};

export default SEO; 