import React, { useCallback, useEffect, useState } from 'react';
import {
  FaAndroid,
  FaApple,
  FaArrowLeft,
  FaArrowRight,
  FaCheckCircle,
  FaClipboardCheck,
  FaClock,
  FaCreditCard,
  FaDownload,
  FaFileAlt,
  FaMoneyBillWave,
  FaReceipt,
  FaShieldAlt,
  FaTruck,
  FaUserCog,
  FaUserFriends
} from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Importar imágenes
import camionetaImage from '../../assets/images/04-PickUp-768x768.png';
import furgonImage from '../../assets/images/05-Cargo-768x768.png';
import camionImage from '../../assets/images/08-3cuarto-768x768.png';
import musherAppImage from '../../assets/images/app-musher-home.jpeg';

// Colores específicos para transportistas según la guía de diseño
const T_YELLOW_BTN = '#EDB940';
const T_YELLOW_BG = '#FFCF5E';
const TRIP_YELLOW = '#FBAC02';

// Animaciones
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Estilos base
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 60px; /* Reducido de 60px */
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 70px; /* Reducido de 80px */
  }
`;

const SectionTitle = styled.h2`
  font-family: ${FONTS.PRIMARY};
  font-weight: 700;
  font-size: 36px;
  color: ${({ $yellow }) => $yellow ? TRIP_YELLOW : COLORS.BLUE_DARK};
  text-align: center;
  margin-bottom: ${SPACING.MEDIUM};
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: ${({ $yellow }) => $yellow ? TRIP_YELLOW : COLORS.BLUE_DARK};
    margin: ${SPACING.SMALL} auto 0;
    border-radius: 2px;
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 28px;
  }
`;

const SectionSubtitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${props => props.yellow ? TRIP_YELLOW : COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
  text-align: center;
`;

const SectionDescription = styled.p`
  text-align: center;
  max-width: 800px;
  margin: 0 auto ${SPACING.LARGE};
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${SPACING.MEDIUM};
  margin-top: ${SPACING.LARGE};
  justify-content: ${({ $left }) => $left ? 'flex-start' : 'center'};
  flex-wrap: wrap;
`;

const AppButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 8px;
  font-family: ${FONTS.PRIMARY};
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: white;
  border: none;
  transition: all 0.3s ease;
  background: ${({ $startColor, $endColor }) => 
    $startColor && $endColor 
      ? `linear-gradient(135deg, ${$startColor} 0%, ${$endColor} 100%)`
      : `linear-gradient(135deg, ${T_YELLOW_BTN} 0%, ${TRIP_YELLOW} 100%)`
  };
  cursor: pointer;
  
  svg {
    font-size: 20px;
  }
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    width: 100%;
    padding: 14px 20px;
  }
`;

// Hero Section
const HeroSection = styled.div`
  position: relative;
  padding-top: 0;
  padding-bottom: 40px; /* Añadido padding inferior para evitar que la sombra se corte */
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 20px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 0;
    padding-bottom: 50px; /* Aumentado en tablets */
    margin-top: 30px;
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    padding-top: 0;
    padding-bottom: 60px; /* Aumentado en desktop */
    min-height: 580px;
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${SPACING.MEDIUM};
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 500px; /* Reducido de 550px */
    height: 100%;
    padding-top: 0; /* Eliminado el padding superior */
    padding-right: 0;
  }
`;

const HeroContent = styled.div`
  max-width: 600px;
  margin-bottom: ${SPACING.LARGE}; /* Reducido de XLARGE */
  animation: ${fadeIn} 0.8s ease-out;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex: 0 0 50%;
    padding-right: ${SPACING.XXLARGE};
    margin-bottom: 0;
    align-self: flex-start;
    padding-top: 0; /* Eliminado el padding superior */
    max-width: 500px;
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: ${SPACING.SMALL};
  margin-bottom: ${SPACING.SMALL};
  justify-content: center;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const Badge = styled.span`
  background-color: ${T_YELLOW_BG}44;
  color: ${TRIP_YELLOW};
  padding: ${SPACING.XXSMALL} ${SPACING.SMALL};
  border-radius: 50px;
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
`;

const HeroTitle = styled.h1`
  font-size: calc(${FONTS.SIZE_HEADING_1} * 1.2);
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${TRIP_YELLOW};
  margin-bottom: ${SPACING.SMALL}; /* Reducido de MEDIUM */
  line-height: 1.2;
  position: relative;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    font-size: calc(${FONTS.SIZE_HEADING_1} * 1.4);
  }
`;

const HeroSubtitle = styled.p`
  font-size: ${FONTS.SIZE_HEADING_3};
  line-height: 1.7;
  margin-bottom: ${SPACING.MEDIUM}; /* Reducido de LARGE */
  color: ${COLORS.BLACK_TEXT};
`;

const HeroImageContainer = styled.div`
  margin-top: ${SPACING.MEDIUM};
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  padding-bottom: 20px; /* Reducido de 50px para que la sombra no se extienda tanto */
  /* Fijar altura para evitar reposicionamiento */
  height: 570px;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex: 0 0 45%;
    margin-top: 20px;
    align-self: flex-start;
    height: 610px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 0;
    position: relative; /* Asegurar que el contenedor sea relativo en desktop */
  }
  
  @media (min-width: 1400px) {
    margin-right: -60px;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
`;

const LoadingText = styled.div`
  margin-top: 15px;
  font-size: ${FONTS.SIZE_SMALL};
  color: ${TRIP_YELLOW};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  opacity: 0.8;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${TRIP_YELLOW};
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Phone3D = styled.div`
  position: relative; /* Volver a posición relativa para evitar superposición */
  width: 280px;
  height: 570px;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  cursor: grab;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  
  /* Animación de entrada mejorada */
  opacity: ${props => props.isLoaded ? 1 : 0};
  transform: ${props => props.isLoaded 
    ? 'rotateY(-20deg) rotateX(10deg) translateY(0) scale(1)' 
    : 'rotateY(-20deg) rotateX(10deg) translateY(80px) scale(0.4)'};
  transition: opacity 0.8s ease-out, transform 1s cubic-bezier(0.17, 0.67, 0.3, 1.33);
  animation: ${props => props.isLoaded ? 'phoneFloat 8s ease-in-out infinite' : 'none'};
  animation-delay: 0.8s;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    width: 300px;
    height: 610px;
    margin-top: 0;
    position: relative; /* Mantener posición relativa en desktop */
    top: auto;
    right: auto;
  }
  
  @keyframes phoneFloat {
    0% {
      transform: rotateY(-20deg) rotateX(10deg) translateY(0);
    }
    25% {
      transform: rotateY(-18deg) rotateX(8deg) translateY(-5px);
    }
    50% {
      transform: rotateY(-15deg) rotateX(5deg) translateY(-10px);
    }
    75% {
      transform: rotateY(-17deg) rotateX(7deg) translateY(-5px);
    }
    100% {
      transform: rotateY(-20deg) rotateX(10deg) translateY(0);
    }
  }
  
  &:hover {
    animation-play-state: paused !important;
    transform: rotateY(-10deg) rotateX(5deg) translateY(-10px) scale(1) !important; /* Añadido zoom del 15% (scale 1) */
  }
  
  &:active {
    cursor: grabbing;
  }
`;

const PhoneBody = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 36px;
  background: linear-gradient(45deg, #333, #111);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.25), /* Reducido de 0 30px 60px para menor extensión vertical */
    0 8px 15px rgba(0, 0, 0, 0.15), /* Reducido de 0 15px 30px para menor extensión vertical */
    0 0 0 2px #000 inset,
    0 0 15px rgba(0, 0, 0, 0.3); /* Reducido de 0.5 para menor intensidad */
  transform-style: preserve-3d;
  transform: translateZ(-10px);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 36px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 50%);
    z-index: 3;
    pointer-events: none;
  }
`;

const PhoneScreen = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  transform: translateZ(0);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }
`;

const PhoneNotch = styled.div`
  position: absolute;
  width: 150px;
  height: 28px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  z-index: 11;
  
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(100, 225, 100, 0.9);
    border-radius: 50%;
    right: 20px;
    top: 9px;
    box-shadow: 0 0 5px rgba(100, 225, 100, 0.7);
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    left: 20px;
    top: 9px;
  }
`;

const AppVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  transform: translateZ(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Color de fondo mientras carga */
  
  /* Añadir propiedades para optimizar el rendimiento */
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    /* Añadir propiedades para optimizar la carga */
    will-change: transform;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
  }
  
  .play-button {
    display: none;
  }
`;

const PhoneButton = styled.div`
  position: absolute;
  width: 3px;
  height: 50px;
  background: #222;
  right: -3px;
  top: 120px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  
  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 70px;
    background: #222;
    right: 0;
    top: 90px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    background: #222;
    right: 0;
    top: -60px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const PhoneSpeaker = styled.div`
  position: absolute;
  width: 60px;
  height: 5px;
  background: #333;
  border-radius: 3px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
  z-index: 12;
`;

const PhoneReflection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 15%,
    rgba(0, 0, 0, 0) 60%
  );
  border-radius: 30px;
  z-index: 11;
  pointer-events: none;
`;

const AppStatusBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: ${TRIP_YELLOW};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  z-index: 2;
`;

const FloatingParticle = styled.div`
  position: absolute;
  width: ${props => props.$size || '10px'};
  height: ${props => props.$size || '10px'};
  background: ${props => props.$color || TRIP_YELLOW};
  border-radius: 50%;
  opacity: ${props => props.$opacity || 0.3};
  top: ${props => props.$top};
  left: ${props => props.$left};
  filter: blur(2px);
  z-index: -1;
  animation: ${float} ${props => props.$duration || '8s'} ease-in-out infinite;
  animation-delay: ${props => props.$delay || '0s'};
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    /* Adjust particle positions to match the new phone position */
    &:nth-child(1) {
      top: calc(${props => props.$top} - 20px);
    }
    &:nth-child(2) {
      top: calc(${props => props.$top} - 20px);
    }
    &:nth-child(3) {
      top: calc(${props => props.$top} - 20px);
    }
    &:nth-child(4) {
      top: calc(${props => props.$top} - 20px);
    }
  }
`;

// Workflow Section
const WorkflowSection = styled.div`
  position: relative;
  background: rgba(251, 172, 2, 0.08);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  margin: 60px 0;
  padding: ${SPACING.XLARGE} 0;
  
  /* Técnica para extender a todo el ancho de la ventana sin causar scroll horizontal */
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado de overflow-x a overflow para controlar también el scroll vertical */
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -10%;
    width: 300px; /* Reducido el tamaño para evitar desbordamiento */
    height: 300px; /* Reducido el tamaño para evitar desbordamiento */
    background: radial-gradient(circle, ${TRIP_YELLOW}08, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    opacity: 0.8;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -30%;
    left: -10%;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, ${COLORS.BLUE_LIGHT}05, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    animation: pulse 20s infinite alternate-reverse ease-in-out;
  }
`;

const WorkflowContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 ${SPACING.MEDIUM};
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 ${SPACING.LARGE};
  }
`;

const WorkflowIntro = styled.p`
  text-align: center;
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const WorkflowStepsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${SPACING.LARGE};
`;

const NavigationArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(251, 172, 2, 0.2);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TRIP_YELLOW};
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background: rgba(251, 172, 2, 0.3);
    transform: translateY(-50%) scale(1);
  }
  
  &:focus {
    outline: none;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &.prev {
    left: 0;
  }
  
  &.next {
    right: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const WorkflowStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.LARGE};
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  padding-bottom: ${SPACING.MEDIUM};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  padding: 0 40px; /* Espacio para las flechas de navegación */
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    flex-direction: row;
    gap: ${SPACING.LARGE};
    overflow-x: visible;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex-direction: row;
    gap: ${SPACING.LARGE};
    overflow-x: visible;
    flex-wrap: nowrap;
    justify-content: center; /* Cambiado de space-between a center */
    padding: 0;
    margin: 0 auto; /* Añadido para centrar el contenedor */
  }
`;

const WorkflowStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.4s ease;
  min-width: calc(100% - 80px); /* Ancho completo menos el espacio para las flechas */
  width: calc(100% - 10px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: ${SPACING.MEDIUM};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;
  
  &:hover {
    transform: translateY(-10px);
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    min-width: auto;
    width: auto;
    flex: 1 1 calc(50% - ${SPACING.LARGE});
    max-width: calc(50% - ${SPACING.LARGE});
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    min-width: 200px;
    width: auto;
    flex: 1;
    max-width: calc(16.66% - ${SPACING.LARGE});
  }
`;

const WorkflowStepIcon = styled.div`
  margin-bottom: ${SPACING.SMALL};
  font-size: 28px;
  color: ${TRIP_YELLOW};
`;

const WorkflowStepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WorkflowStepNumber = styled.div`
  font-size: 40px;
  font-weight: 800;
  color: ${TRIP_YELLOW};
  line-height: 1;
  margin-bottom: ${SPACING.XSMALL};
`;

const WorkflowStepTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_5};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${TRIP_YELLOW};
  margin-bottom: ${SPACING.SMALL};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    background: ${TRIP_YELLOW};
    transition: width 0.4s ease;
    margin: 6px auto 0;
  }
  
  ${WorkflowStep}:hover &::after {
    width: 100%;
  }
`;

const WorkflowStepDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  flex-grow: 1;
  margin-top: ${SPACING.XSMALL};
`;

const WorkflowArrow = styled.div`
  margin-top: ${SPACING.SMALL};
  transition: transform 0.2s ease;
  
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
  
  ${WorkflowStep}:hover & {
    transform: translateX(5px);
  }
`;

// Beneficios Section
const FeaturesSection = styled.div`
  position: relative;
  padding: ${SPACING.XLARGE} 0;
  margin: 60px 0;
  
  /* Técnica para extender a todo el ancho de la ventana sin causar scroll horizontal */
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado de overflow-x a overflow para controlar también el scroll vertical */
`;

const FeaturesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 ${SPACING.MEDIUM};
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 ${SPACING.LARGE};
  }
`;

const BenefitsIntro = styled.p`
  text-align: center;
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const BenefitsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${SPACING.LARGE};
`;

const BenefitsGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.LARGE};
  margin-top: ${SPACING.LARGE};
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 0 40px; /* Espacio para las flechas de navegación */
  padding-bottom: ${SPACING.MEDIUM};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-x: visible;
    gap: ${SPACING.LARGE};
    padding: 0;
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${SPACING.LARGE};
    overflow-x: visible;
    padding: 0;
  }
`;

const BenefitCard = styled.div`
  min-width: calc(100% - 40px); /* Ancho completo menos el espacio para las flechas */
  width: calc(100% - 10px);
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  padding: 30px 25px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: ${fadeIn} 0.8s ease-out forwards;
  animation-delay: ${({ $delay }) => $delay || '0s'};
  opacity: 0;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, ${TRIP_YELLOW} 0%, ${T_YELLOW_BTN} 100%);
    border-radius: 2px 2px 0 0;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15%;
    right: 15%;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(251, 172, 2, 0.3), transparent);
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.35);
    
    &::after {
      opacity: 1;
    }
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    min-width: auto;
    width: auto;
    padding: 25px 20px;
  }
`;

const BenefitIconWrapper = styled.div`
  position: relative;
  margin-bottom: ${SPACING.MEDIUM};
  
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 8px;
    left: 8px;
    background: ${TRIP_YELLOW}15;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.3s ease;
  }
  
  ${BenefitCard}:hover &::before {
    transform: scale(1.2) translateX(-5px) translateY(-5px);
    opacity: 0.2;
  }
`;

const BenefitIcon = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, ${TRIP_YELLOW} 0%, ${T_YELLOW_BTN} 100%);
  color: ${COLORS.WHITE};
  font-size: 28px;
  box-shadow: 0 8px 15px rgba(251, 172, 2, 0.15);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  z-index: 1;
  
  ${BenefitCard}:hover & {
    transform: scale(1.08) rotate(5deg);
    box-shadow: 0 10px 20px rgba(251, 172, 2, 0.2);
  }
  
  svg {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
    transition: all 0.3s ease;
    
    ${BenefitCard}:hover & {
      transform: scale(1);
    }
  }
`;

const BenefitNumber = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  font-weight: 800;
  color: ${TRIP_YELLOW}10;
  line-height: 1;
  transition: all 0.3s ease;
  
  ${BenefitCard}:hover & {
    color: ${TRIP_YELLOW}15;
    transform: scale(1.05);
  }
`;

const BenefitTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_5};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${TRIP_YELLOW};
  margin-bottom: ${SPACING.SMALL};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 2px;
    background: ${TRIP_YELLOW};
    transition: width 0.4s ease;
    margin: 6px auto 0;
  }
  
  ${BenefitCard}:hover &::after {
    width: 100%;
  }
`;

const BenefitDescription = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  flex-grow: 1;
  margin-top: ${SPACING.XSMALL};
`;

// Categorías de Vehículos Section
const VehicleCategoriesSection = styled.div`
  position: relative;
  padding: ${SPACING.XLARGE} 0;
  /* Eliminado el fondo con efecto glassmorphism */
  margin: 60px 0;
  
  /* Técnica para extender a todo el ancho de la ventana sin causar scroll horizontal */
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado de overflow-x a overflow para controlar también el scroll vertical */
  
  &::before {
    content: '';
    position: absolute;
    top: -30%;
    left: -10%;
    width: 300px; /* Reducido el tamaño para evitar desbordamiento */
    height: 300px; /* Reducido el tamaño para evitar desbordamiento */
    background: radial-gradient(circle, ${TRIP_YELLOW}10, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    opacity: 0.5;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -20%;
    right: -10%;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, ${COLORS.BLUE_LIGHT}08, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    opacity: 0.5;
  }
`;

const VehicleCategoriesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 ${SPACING.MEDIUM};
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: 0 ${SPACING.LARGE};
  }
`;

const VehicleCategoriesTitleWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: ${SPACING.XLARGE};
  
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, ${TRIP_YELLOW}, ${T_YELLOW_BTN});
    margin: ${SPACING.MEDIUM} auto 0;
    border-radius: 2px;
  }
`;

const VehicleCategoriesIntro = styled.div`
  max-width: 900px;
  margin: 0 auto ${SPACING.XLARGE};
  background: rgba(255, 255, 255, 0.8); /* Cambiado a un fondo más sólido */
  border-radius: 20px;
  padding: ${SPACING.LARGE};
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); /* Sombra más sutil */
`;

const VehicleCategoriesText = styled.p`
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  text-align: center;
`;

const VehicleCategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.XLARGE};
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const VehicleCategoryCard = styled.div`
  background-color: ${COLORS.WHITE}99; /* Cambiado a fondo blanco sólido */
  backdrop-filter: blur(2px);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); /* Sombra más sutil */
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-out forwards;
  animation-delay: ${({ $delay }) => $delay || '0s'};
  opacity: 0;
  height: 100%;
  position: relative;
  
  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08); /* Sombra más sutil al hacer hover */
  }
  
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    margin-bottom: ${SPACING.MEDIUM};
  }
`;

const VehicleCategoryImageWrapper = styled.div`
  position: relative;
  height: 200px;
  overflow: hidden;
  
  /* Eliminada la sombra generada en las imágenes */
`;

const VehicleCategoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Cambiado de 'cover' a 'contain' para evitar que se corten */
  transition: transform 0.6s ease;
  background-color: ${COLORS.GREY_LIGHT}60; /* Añadido un fondo claro para que las imágenes se vean mejor */
  padding: 10px; /* Añadido un pequeño padding para que no toquen los bordes */
  
  ${VehicleCategoryCard}:hover & {
    transform: scale(1.08);
  }
`;

const VehicleCategoryContent = styled.div`
  padding: ${SPACING.LARGE};
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const VehicleCategoryTitle = styled.h3`
  font-family: ${FONTS.PRIMARY};
  font-weight: 700;
  font-size: 22px;
  color: ${TRIP_YELLOW};
  margin-bottom: ${SPACING.SMALL};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${TRIP_YELLOW};
    transition: width 0.3s ease;
    margin: 5px 0 0;
  }
  
  ${VehicleCategoryCard}:hover &::after {
    width: 100%;
  }
`;

const VehicleCategoryDescription = styled.p`
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  flex: 1;
`;

const VehicleCategoryCapacity = styled.div`
  display: inline-block;
  margin-top: ${SPACING.MEDIUM};
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  background: linear-gradient(135deg, ${TRIP_YELLOW}30, ${T_YELLOW_BTN}30);
  border-radius: 30px;
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  color: ${TRIP_YELLOW};
  font-size: ${FONTS.SIZE_SMALL};
  box-shadow: 0 4px 10px rgba(251, 172, 2, 0.1);
  transition: all 0.3s ease;
  
  ${VehicleCategoryCard}:hover & {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(251, 172, 2, 0.15);
    background: linear-gradient(135deg, ${TRIP_YELLOW}40, ${T_YELLOW_BTN}40);
  }
`;

const VehicleCategoryBadge = styled.span`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: ${SPACING.XSMALL} ${SPACING.MEDIUM};
  background-color: rgba(255, 255, 255, 0.9);
  color: ${TRIP_YELLOW};
  font-weight: ${FONTS.WEIGHT_BOLD};
  font-size: ${FONTS.SIZE_SMALL};
  border-radius: 30px;
  z-index: 2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

// Registro Section
const RegisterSection = styled.div`
  position: relative;
  background: rgba(251, 172, 2, 0.08);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  margin: 60px 0;
  margin-bottom: 0;
  padding: ${SPACING.XLARGE} 0;
  
  /* Técnica para extender a todo el ancho de la ventana sin causar scroll horizontal */
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden; /* Cambiado de overflow-x a overflow para controlar también el scroll vertical */
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -10%;
    width: 300px; /* Reducido el tamaño para evitar desbordamiento */
    height: 300px; /* Reducido el tamaño para evitar desbordamiento */
    background: radial-gradient(circle, ${TRIP_YELLOW}08, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    opacity: 0.8;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -30%;
    left: -10%;
    width: 400px;
    height: 400px;
    background: radial-gradient(circle, ${COLORS.BLUE_LIGHT}05, transparent 70%);
    border-radius: 50%;
    z-index: 0;
    animation: pulse 20s infinite alternate-reverse ease-in-out;
  }
`;

const RegisterContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 ${SPACING.MEDIUM};
`;

const RegisterTitle = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.MEDIUM};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: ${TRIP_YELLOW};
    margin: ${SPACING.SMALL} auto 0;
    border-radius: 2px;
  }
`;

const RegisterText = styled.p`
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.7;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.XLARGE};
`;

// Añadir estilos para la sección de descarga de app
const ChannelOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.MEDIUM};
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: ${SPACING.SMALL}; /* Añadido margen superior reducido */
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    margin: ${SPACING.SMALL} 0 0 0; /* Ajustado margen */
  }
`;

const ChannelOption = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.MEDIUM};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    padding: ${SPACING.SMALL} ${SPACING.MEDIUM}; /* Reducido el padding vertical */
  }
`;

const ChannelTitle = styled.h3`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.XSMALL}; /* Reducido de SMALL */
  display: flex;
  align-items: center;
`;

const ChannelIcon = styled.span`
  margin-right: ${SPACING.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $color }) => $color || TRIP_YELLOW};
  font-size: 20px;
`;

const ChannelDescription = styled.p`
  font-size: ${FONTS.SIZE_SECONDARY};
  margin-bottom: ${SPACING.SMALL}; /* Reducido de MEDIUM */
  color: ${COLORS.BLACK_TEXT}CC;
`;

const ArrowIcon = styled.span`
  margin-left: ${SPACING.SMALL};
  transition: transform 0.2s ease;
  
  ${ChannelOption}:hover & {
    transform: translateX(5px);
  }
`;

const StoreIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING.SMALL};
  gap: ${SPACING.MEDIUM};
`;

const StoreIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${COLORS.BLACK_TEXT}99;
  
  span {
    margin-left: 4px;
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const TransportistaPage = () => {
  // Ref para el teléfono 3D
  const [phoneRotation, setPhoneRotation] = useState({ x: 10, y: -20 });
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
  // Estado para controlar si la imagen está cargada
  const [imageLoaded, setImageLoaded] = useState(false);
  // Estado para controlar si el mouse está sobre el teléfono
  const [isHovering, setIsHovering] = useState(false);
  
  // Manejador para rotation del teléfono con el mouse - usando useCallback para evitar advertencias de dependencias
  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    
    const dx = e.clientX - lastMousePosition.x;
    const dy = e.clientY - lastMousePosition.y;
    
    setPhoneRotation({
      x: Math.max(-15, Math.min(15, phoneRotation.x - dy * 0.5)),
      y: Math.max(-30, Math.min(30, phoneRotation.y + dx * 0.5))
    });
    
    setLastMousePosition({ x: e.clientX, y: e.clientY });
  }, [isDragging, lastMousePosition, phoneRotation]);
  
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastMousePosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  
  // Manejadores para el hover
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  
  // Manejador para la carga de la imagen
  const handleImageLoad = () => {
    // Agregamos un pequeño retraso para asegurar una transición suave
    setTimeout(() => {
      setImageLoaded(true);
    }, 400);
  };
  
  // Efecto para precargar la imagen al montar el componente
  useEffect(() => {
    const img = new Image();
    img.src = musherAppImage;
    img.onload = handleImageLoad;
    
    // Si la imagen ya está en caché, activar manualmente
    if (img.complete) {
      handleImageLoad();
    }
  }, []);

  // Efecto para manejar eventos del mouse globales
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);
  
  // Efecto para auto-rotación suave cuando no se está interactuando
  useEffect(() => {
    if (!isDragging && imageLoaded) {
      const timer = setTimeout(() => {
        setPhoneRotation(prev => ({
          x: prev.x * 0.95 + 10 * 0.05,
          y: prev.y * 0.95 + (-20) * 0.05
        }));
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [isDragging, phoneRotation, imageLoaded]);

  // Función para descargar la app de transportista
  const downloadTransportistaApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/search?q=trineo%20musher&c=apps&hl=en_US', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/trineo-musher/id6471224008', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /transportista
      window.location.href = '/transportista';
    }
  };

  // Determinar el icono según el dispositivo
  const getStoreIcon = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return <FaAndroid />;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return <FaApple />;
    } else {
      return <FaDownload />;
    }
  };

  // Determinar los colores del botón según el dispositivo
  const getButtonColors = () => {
    // Siempre devolver los colores del hero (amarillo)
    return {
      startColor: T_YELLOW_BTN,
      endColor: TRIP_YELLOW
    };
  };

  const buttonColors = getButtonColors();

  return (
    <PageWrapper>
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Transportista" />
      
      <MainContent>
        <Container>
          <HeroSection>
            <HeroContainer>
              <HeroContent>
                <BadgeContainer>
                  <Badge>Transportista</Badge>
                  <Badge>Ganancias</Badge>
                  <Badge>Flexible</Badge>
                </BadgeContainer>
                <HeroTitle>App de transportistas</HeroTitle>
                <HeroSubtitle>
                  ¡Gana más que en otras aplicaciones de conductor! Trineo Musher la app para Transportistas que conecta con usuarios para realizar fletes. Aumenta tus ganancias con nuestras comisiones bajas y a tu propio ritmo de trabajo.
                </HeroSubtitle>
                
                <ChannelOptions>
                  <ChannelOption style={{ 
                    border: `2px solid ${TRIP_YELLOW}`, 
                    transform: 'scale(1.03)', 
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)' 
                  }}>
                    <ChannelTitle>
                      <ChannelIcon $color={TRIP_YELLOW}><FaDownload /></ChannelIcon>
                      Descarga nuestra App
                    </ChannelTitle>
                    <ChannelDescription>
                      Recibe solicitudes de fletes, conecta con usuarios y aumenta tus ganancias con comisiones más bajas. Trabaja a tu propio ritmo.
                    </ChannelDescription>
                    <AppButton onClick={downloadTransportistaApp}>
                      Obtener la App <ArrowIcon><FaArrowRight /></ArrowIcon>
                    </AppButton>
                    <StoreIcons>
                      <StoreIcon>
                        <FaAndroid /> <span>Android</span>
                      </StoreIcon>
                      <StoreIcon>
                        <FaApple /> <span>iOS</span>
                      </StoreIcon>
                    </StoreIcons>
                  </ChannelOption>
                </ChannelOptions>
              </HeroContent>
              <HeroImageContainer>
                {!imageLoaded && (
                  <LoaderContainer>
                    <Spinner />
                    <LoadingText>Cargando aplicación...</LoadingText>
                  </LoaderContainer>
                )}
                
                <Phone3D 
                  isLoaded={imageLoaded}
                  onMouseDown={handleMouseDown}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ 
                    transform: isDragging && imageLoaded
                      ? `rotateX(${phoneRotation.x}deg) rotateY(${phoneRotation.y}deg) scale(1)`
                      : isHovering && imageLoaded
                        ? 'rotateY(-10deg) rotateX(5deg) translateY(-10px) scale(1)' /* Añadido zoom del 15% (scale 1.1) */
                        : undefined,
                    animationPlayState: (isDragging || isHovering) ? 'paused' : 'running'
                  }}
                >
                  <PhoneBody />
                  <PhoneScreen>
                    <AppStatusBar>TRINEO MUSHER</AppStatusBar>
                    <AppVideo>
                      <img 
                        src={musherAppImage} 
                        alt="Trineo Musher App" 
                        loading="eager" 
                        decoding="async"
                        style={{
                          transform: 'translateZ(0)',
                          WebkitTransform: 'translateZ(0)'
                        }}
                      />
                    </AppVideo>
                    <PhoneReflection />
                  </PhoneScreen>
                  <PhoneNotch />
                  <PhoneSpeaker />
                  <PhoneButton />
                </Phone3D>
                
                {/* Partículas flotantes para efecto visual */}
                <FloatingParticle $size="30px" $color={TRIP_YELLOW} $opacity="0.15" $top="10%" $left="20%" $duration="12s" />
                <FloatingParticle $size="20px" $color={COLORS.BLUE_MAIN} $opacity="0.1" $top="70%" $left="10%" $duration="10s" $delay="1s" />
                <FloatingParticle $size="15px" $color={TRIP_YELLOW} $opacity="0.2" $top="30%" $left="80%" $duration="15s" $delay="2s" />
                <FloatingParticle $size="25px" $color={COLORS.BLUE_MAIN} $opacity="0.12" $top="80%" $left="70%" $duration="11s" $delay="3s" />
              </HeroImageContainer>
            </HeroContainer>
          </HeroSection>
          
          <WorkflowSection>
            <WorkflowContent>
              <SectionTitle>Nuestro Flujo de Trabajo</SectionTitle>
              <WorkflowIntro>
                Conoce el proceso completo de Trineo Musher y cómo te integrarás como transportista para realizar entregas eficientes y transparentes.
              </WorkflowIntro>
              
              <WorkflowStepsWrapper>
                <NavigationArrow 
                  className="prev" 
                  onClick={() => {
                    const container = document.getElementById('workflow-steps');
                    const cardWidth = container.querySelector('div').offsetWidth;
                    container.scrollBy({left: -cardWidth - 20, behavior: 'smooth'});
                  }}
                >
                  <FaArrowLeft />
                </NavigationArrow>
                
                <WorkflowStepsContainer id="workflow-steps">
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaCheckCircle />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>01</WorkflowStepNumber>
                      <WorkflowStepTitle>Programado</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        El cliente programa un servicio de flete a través de nuestra plataforma, iniciando el proceso.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                    <WorkflowArrow><FaArrowRight /></WorkflowArrow>
                  </WorkflowStep>
                  
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaFileAlt />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>02</WorkflowStepNumber>
                      <WorkflowStepTitle>Resumen</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        El sistema envía al cliente un resumen detallado con la información del servicio solicitado.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                    <WorkflowArrow><FaArrowRight /></WorkflowArrow>
                  </WorkflowStep>
                  
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaCreditCard />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>03</WorkflowStepNumber>
                      <WorkflowStepTitle>Validar Pago</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        Se valida el pago del cliente antes de proceder con la asignación del transportista.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                    <WorkflowArrow><FaArrowRight /></WorkflowArrow>
                  </WorkflowStep>
                  
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaUserCog />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>04</WorkflowStepNumber>
                      <WorkflowStepTitle>Asignación</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        Aquí entras tú: serás asignado al servicio según tu disponibilidad, ubicación y tipo de vehículo.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                    <WorkflowArrow><FaArrowRight /></WorkflowArrow>
                  </WorkflowStep>
                  
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaReceipt />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>05</WorkflowStepNumber>
                      <WorkflowStepTitle>Enviar Voucher</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        Tanto tú como el cliente reciben un voucher digital con todos los detalles del servicio.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                    <WorkflowArrow><FaArrowRight /></WorkflowArrow>
                  </WorkflowStep>
                  
                  <WorkflowStep>
                    <WorkflowStepIcon>
                      <FaTruck />
                    </WorkflowStepIcon>
                    <WorkflowStepContent>
                      <WorkflowStepNumber>06</WorkflowStepNumber>
                      <WorkflowStepTitle>Flete</WorkflowStepTitle>
                      <WorkflowStepDescription>
                        Realizas el servicio de flete exitosamente y confirmas la entrega para recibir tu pago.
                      </WorkflowStepDescription>
                    </WorkflowStepContent>
                  </WorkflowStep>
                </WorkflowStepsContainer>
                
                <NavigationArrow 
                  className="next" 
                  onClick={() => {
                    const container = document.getElementById('workflow-steps');
                    const cardWidth = container.querySelector('div').offsetWidth;
                    container.scrollBy({left: cardWidth + 20, behavior: 'smooth'});
                  }}
                >
                  <FaArrowRight />
                </NavigationArrow>
              </WorkflowStepsWrapper>
            </WorkflowContent>
          </WorkflowSection>
          
          <FeaturesSection>
            <FeaturesContent>
              <SectionTitle $yellow>Beneficios para nuestros transportistas</SectionTitle>
                <BenefitsIntro>
                  Descubre los beneficios exclusivos que tenemos para ti como transportista de Trineo Musher. Diseñamos nuestra plataforma pensando en tus necesidades.
                </BenefitsIntro>              
              <BenefitsWrapper>
                <NavigationArrow 
                  className="prev" 
                  onClick={() => {
                    const container = document.getElementById('benefits-grid');
                    const cardWidth = container.querySelector('div').offsetWidth;
                    container.scrollBy({left: -cardWidth - 20, behavior: 'smooth'});
                  }}
                >
                  <FaArrowLeft />
                </NavigationArrow>
                
                <BenefitsGrid id="benefits-grid">
                  <BenefitCard $delay="0.1s">
                    <BenefitNumber>01</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaClipboardCheck />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Regístrate en Minutos</BenefitTitle>
                    <BenefitDescription>
                      Un registro fácil y una aplicación sencilla de ocupar. Sin complicaciones ni largos procesos de verificación.
                    </BenefitDescription>
                  </BenefitCard>
                  
                  <BenefitCard $delay="0.2s">
                    <BenefitNumber>02</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaUserFriends />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Conecta con Usuarios</BenefitTitle>
                    <BenefitDescription>
                      Desde la comodidad de tu casa recibirás solicitudes de flete. Nuestra plataforma te conecta con clientes cercanos a tu ubicación.
                    </BenefitDescription>
                  </BenefitCard>
                  
                  <BenefitCard $delay="0.3s">
                    <BenefitNumber>03</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaMoneyBillWave />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Comisiones más Bajas</BenefitTitle>
                    <BenefitDescription>
                      La más baja y transparente del mercado de transporte. Sin cargos ocultos ni sorpresas en tus pagos.
                    </BenefitDescription>
                  </BenefitCard>
                  
                  <BenefitCard $delay="0.4s">
                    <BenefitNumber>04</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaMoneyBillWave />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Grandes Ganancias</BenefitTitle>
                    <BenefitDescription>
                      Gana más que en otras aplicaciones de conductor. Nuestro modelo de negocio está diseñado para maximizar tus ingresos.
                    </BenefitDescription>
                  </BenefitCard>
                  
                  <BenefitCard $delay="0.5s">
                    <BenefitNumber>05</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaClock />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Horario Flexible</BenefitTitle>
                    <BenefitDescription>
                      Trabaja cuando quieras y a tu propio ritmo. Tú decides cuándo estar disponible y qué servicios aceptar.
                    </BenefitDescription>
                  </BenefitCard>
                  
                  <BenefitCard $delay="0.6s">
                    <BenefitNumber>06</BenefitNumber>
                    <BenefitIconWrapper>
                      <BenefitIcon>
                        <FaShieldAlt />
                      </BenefitIcon>
                    </BenefitIconWrapper>
                    <BenefitTitle>Seguridad Garantizada</BenefitTitle>
                    <BenefitDescription>
                      Todos los usuarios están verificados para tu tranquilidad. Trabajamos para crear un entorno seguro para todos.
                    </BenefitDescription>
                  </BenefitCard>
                </BenefitsGrid>
                
                <NavigationArrow 
                  className="next" 
                  onClick={() => {
                    const container = document.getElementById('benefits-grid');
                    const cardWidth = container.querySelector('div').offsetWidth;
                    container.scrollBy({left: cardWidth + 20, behavior: 'smooth'});
                  }}
                >
                  <FaArrowRight />
                </NavigationArrow>
              </BenefitsWrapper>
            </FeaturesContent>
          </FeaturesSection>
          
          <VehicleCategoriesSection>
            <VehicleCategoriesContent>
              <VehicleCategoriesTitleWrapper>
                <SectionTitle>Registra tu vehículo en la categoría adecuada</SectionTitle>
              </VehicleCategoriesTitleWrapper>
              
              <VehicleCategoriesIntro>
                <VehicleCategoriesText>
                  En Trineo, invitamos a los conductores interesados en registrarse a seleccionar la categoría de vehículo que mejor se adapte a sus capacidades. Ofrecemos una variedad de categorías, desde automóviles hasta camiones tres cuartos, todas aptas para el transporte de mascotas. Al elegir la categoría correcta, podrás maximizar tus ganancias y garantizar un servicio de calidad para nuestros usuarios. ¡Únete a Trineo y cubre todas tus necesidades con las comisiones más bajas!
                </VehicleCategoriesText>
              </VehicleCategoriesIntro>
              
              <VehicleCategoriesGrid>               
                <VehicleCategoryCard $delay="0.2s">
                  <VehicleCategoryBadge>Más demanda</VehicleCategoryBadge>
                  <VehicleCategoryImageWrapper>
                    <VehicleCategoryImage src={camionetaImage} alt="Camioneta Abierta o Cerrada" />
                  </VehicleCategoryImageWrapper>
                  <VehicleCategoryContent>
                    <VehicleCategoryTitle>Camioneta Abierta o Cerrada</VehicleCategoryTitle>
                    <VehicleCategoryDescription>
                      Si posees una pickup con una capacidad de carga de hasta 800 kg y espacio para objetos de hasta 3 metros de longitud, puedes transportar muebles medianos, materiales de construcción y electrodomésticos medianos.
                    </VehicleCategoryDescription>
                    <VehicleCategoryCapacity>Capacidad: hasta 800 kg</VehicleCategoryCapacity>
                  </VehicleCategoryContent>
                </VehicleCategoryCard>
                
                <VehicleCategoryCard $delay="0.3s">
                  <VehicleCategoryBadge>Versátil</VehicleCategoryBadge>
                  <VehicleCategoryImageWrapper>
                    <VehicleCategoryImage src={furgonImage} alt="Furgón M / L" />
                  </VehicleCategoryImageWrapper>
                  <VehicleCategoryContent>
                    <VehicleCategoryTitle>Furgón M / L</VehicleCategoryTitle>
                    <VehicleCategoryDescription>
                      Si dispones de un furgón con una capacidad de carga de hasta 600 kg, puedes especializarte en el transporte de muebles medianos y grandes, equipos y suministros de oficina, y electrodomésticos grandes.
                    </VehicleCategoryDescription>
                    <VehicleCategoryCapacity>Capacidad: hasta 600 kg</VehicleCategoryCapacity>
                  </VehicleCategoryContent>
                </VehicleCategoryCard>
                
                <VehicleCategoryCard $delay="0.4s">
                  <VehicleCategoryBadge>Mayor capacidad</VehicleCategoryBadge>
                  <VehicleCategoryImageWrapper>
                    <VehicleCategoryImage src={camionImage} alt="Camión 3/4" />
                  </VehicleCategoryImageWrapper>
                  <VehicleCategoryContent>
                    <VehicleCategoryTitle>Camión 3/4</VehicleCategoryTitle>
                    <VehicleCategoryDescription>
                      Si cuentas con un camión de 3/4 con una capacidad de carga de hasta 4000 kg, puedes transportar cargas más pesadas como muebles grandes, materiales de construcción en grandes cantidades y mudanzas completas.
                    </VehicleCategoryDescription>
                    <VehicleCategoryCapacity>Capacidad: hasta 4000 kg</VehicleCategoryCapacity>
                  </VehicleCategoryContent>
                </VehicleCategoryCard>
              </VehicleCategoriesGrid>
            </VehicleCategoriesContent>
          </VehicleCategoriesSection>
        </Container>
        
        <RegisterSection>
          <RegisterContent>
            <SectionTitle>¡Regístrate como Musher transportista ahora!</SectionTitle>
            <RegisterText>
              Si quieres aumentar tus ingresos y ser parte de Trineo descarga la app de Transportistas. Regístrate en la aplicación ingresando tus datos y los de tu vehículo además te solicitaremos algunos documentos adicionales para validarte como transportista. Una vez realizado el proceso de validación, estarás listo en la app para comenzar a recibir solicitudes y realizar servicios de fletes.
            </RegisterText>
            
            <ButtonsContainer>
              <AppButton 
                onClick={downloadTransportistaApp}
                $startColor={buttonColors.startColor}
                $endColor={buttonColors.endColor}
                style={{ boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)' }}
              >
                {getStoreIcon()} Descarga Trineo Musher
              </AppButton>
            </ButtonsContainer>
          </RegisterContent>
        </RegisterSection>
      </MainContent>
      
      <Footer />
    </PageWrapper>
  );
};

export default TransportistaPage; 