import React from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINTS, SPACING } from '../../styles/variables';

const ContainerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${({ $noPadding }) => $noPadding ? '0' : `0 ${SPACING.MEDIUM}`};
  max-width: ${({ $fluid }) => $fluid ? '100%' : '1200px'};
  
  ${({ $narrow }) => $narrow && css`
    max-width: 800px;
  `}
  
  ${({ $centerContent }) => $centerContent && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `}
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${({ $noPadding }) => $noPadding ? '0' : `0 ${SPACING.LARGE}`};
  }
`;

const Container = ({ 
  children, 
  fluid = false,
  narrow = false,
  noPadding = false,
  centerContent = false,
  as = 'div',
  ...props 
}) => {
  return (
    <ContainerWrapper
      $fluid={fluid}
      $narrow={narrow}
      $noPadding={noPadding}
      $centerContent={centerContent}
      as={as}
      {...props}
    >
      {children}
    </ContainerWrapper>
  );
};

export default Container; 