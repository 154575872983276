import axios from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Estilos
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 100px;
  }
`;

const LoginSection = styled.div`
  position: relative;
  padding: ${SPACING.XLARGE} 0;
  margin: 40px 0 60px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 60px 0;
  }
`;

const LoginContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: ${SPACING.LARGE};
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.XLARGE};
  }
`;

const LoginTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_2};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_DARK};
  margin-bottom: ${SPACING.MEDIUM};
  text-align: center;
  
  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background-color: ${COLORS.BLUE_MAIN};
    margin: ${SPACING.SMALL} auto;
    border-radius: 2px;
  }
`;

const LoginDescription = styled.p`
  text-align: center;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  margin: ${SPACING.LARGE} 0;
  
  &:before, &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${COLORS.GREY_MEDIUM};
  }
  
  span {
    margin: 0 ${SPACING.SMALL};
    color: ${COLORS.GREY_DARK};
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING.SMALL};
  width: 100%;
  padding: ${SPACING.MEDIUM};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK_TEXT};
  border: 1px solid ${COLORS.GREY_MEDIUM};
  border-radius: 8px;
  font-size: ${FONTS.SIZE_MEDIUM};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: #f5f5f5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  svg {
    font-size: 20px;
    color: #4285F4;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${COLORS.BLUE_MAIN};
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

// Configuración de axios
axios.defaults.withCredentials = true;

// URL y cliente de Google configurados manualmente según Swagger
const API_BASE_URL = "https://localhost:5001";

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Usar la URL exacta que viste en Swagger
  const handleGoogleLogin = () => {
    setIsLoading(true);
    setError(null);
    
    try {
      // URL exacta copiada de tu respuesta de Swagger
      const authUrl = "https://accounts.google.com/o/oauth2/v2/auth?client_id=896392839047-vmd8spqlqjns477jai2tobd9nphqnh3e.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Flocalhost%3A5001%2Fapi%2Fauth%2Fgoogle-callback&response_type=code&scope=openid%20email%20profile&access_type=offline&prompt=consent";
      
      console.log('Redirigiendo a URL de Swagger:', authUrl);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error al redirigir:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <PageWrapper>
      <Helmet>
        <title>Iniciar Sesión - Trineo</title>
        <meta name="description" content="Inicia sesión en tu cuenta de Trineo para acceder a tus servicios." />
      </Helmet>
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Iniciar Sesión" />
      
      <MainContent>
        <Container>
          <LoginSection>
            <LoginContent>
              <LoginTitle>Iniciar Sesión</LoginTitle>
              <LoginDescription>
                Accede a tu cuenta para gestionar tus servicios de fletes con Trineo.
              </LoginDescription>
              
              {/* Botón de autenticación con Google usando la URL exacta de Swagger */}
              <GoogleButton onClick={handleGoogleLogin} disabled={isLoading}>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <FaGoogle /> Continuar con Google
                  </>
                )}
              </GoogleButton>
              
              {/* Mostrar error si existe */}
              {error && (
                <div style={{ 
                  marginTop: '15px', 
                  padding: '10px', 
                  backgroundColor: '#ffebee', 
                  color: '#c62828', 
                  borderRadius: '8px',
                  fontSize: '14px'
                }}>
                  Error: {error}
                </div>
              )}
              
              <Divider>
                <span>PRÓXIMAMENTE</span>
              </Divider>
              
              <GoogleButton disabled style={{ opacity: 0.6, cursor: 'not-allowed' }}>
                <FaFacebook style={{ color: '#4267B2' }} /> Otros métodos de ingreso
              </GoogleButton>
            </LoginContent>
          </LoginSection>
        </Container>
      </MainContent>
      
      <Footer />
    </PageWrapper>
  );
};

export default LoginPage; 