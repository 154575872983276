import React from 'react';
import styled from 'styled-components';
import { SkipLink } from '../components/Accessibility/SkipLink';
import CTASection from '../components/CTASection/CTASection';
import FeaturesSection from '../components/FeaturesSection/FeaturesSection';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import HeroSection from '../components/HeroSection/HeroSection';
import HomePageSEO from '../components/SEO/HomePageSEO';
import TestimonialsSection from '../components/TestimonialsSection/TestimonialsSection';
import VehicleCategoriesSection from '../components/VehicleCategoriesSection/VehicleCategoriesSection';
import UserWorkflowSection from '../components/WorkflowSection/UserWorkflowSection';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

const HomePage = () => {
  return (
    <>
      {/* Componente SEO optimizado para la página de inicio */}
      <HomePageSEO />
      
      <SkipLink href="#main-content">Saltar al contenido principal</SkipLink>
      <AppWrapper>
        <Header />
        
        <MainContent id="main-content" role="main">
          {/* Hero Section */}
          <HeroSection />
          
          {/* User Workflow Section */}
          <div id="proceso" role="region" aria-label="Proceso de uso">
            <UserWorkflowSection />
          </div>
          
          {/* Features Section */}
          <div id="caracteristicas" role="region" aria-label="Características principales">
            <FeaturesSection />
          </div>
          
          {/* Vehicle Categories Section */}
          <div id="categorias" role="region" aria-label="Categorías de vehículos">
            <VehicleCategoriesSection />
          </div>
          
          {/* Testimonials Section */}
          <div id="testimonios" role="region" aria-label="Testimonios de clientes">
            <TestimonialsSection />
          </div>
          
          {/* CTA Section */}
          <div role="region" aria-label="Llamado a la acción">
            <CTASection />
          </div>
        </MainContent>
        
        <Footer />
      </AppWrapper>
    </>
  );
};

export default HomePage; 