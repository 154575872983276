import React, { useEffect, useState } from 'react';
import { FaAndroid, FaApple, FaDownload, FaTimes, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Contenedor principal del prompt
const PromptContainer = styled.div`
  position: fixed;
  bottom: ${({ isVisible }) => (isVisible ? '0' : '-100%')};
  left: 0;
  right: 0;
  background-color: ${COLORS.WHITE};
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  padding: ${SPACING.MEDIUM};
  z-index: 1000;
  transition: bottom 0.3s ease-in-out;
  border-top-left-radius: ${SPACING.MEDIUM};
  border-top-right-radius: ${SPACING.MEDIUM};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none; /* Solo mostrar en móviles */
  }
`;

const PromptContent = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: ${COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${SPACING.MEDIUM};
  font-size: 24px;
  color: ${COLORS.WHITE};
  flex-shrink: 0;
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.XSMALL};
  color: ${COLORS.BLACK_TEXT};
`;

const Description = styled.p`
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLACK_TEXT}CC;
  margin-bottom: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${SPACING.MEDIUM};
  gap: ${SPACING.SMALL};
`;

const Button = styled.button`
  flex: 1;
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  border-radius: ${SPACING.SMALL};
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;
  
  &:active {
    transform: scale(0.98);
  }
`;

const PrimaryButton = styled(Button)`
  background-color: ${COLORS.BLUE_MAIN};
  color: ${COLORS.WHITE};
  border: none;
  
  &:hover {
    background-color: ${COLORS.BLUE_SECONDARY};
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${COLORS.BLUE_MAIN};
  border: 1px solid ${COLORS.BLUE_MAIN};
  
  &:hover {
    background-color: ${COLORS.BLUE_MAIN}11;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${SPACING.SMALL};
  right: ${SPACING.SMALL};
  background: transparent;
  border: none;
  color: ${COLORS.BLACK_TEXT}99;
  font-size: 18px;
  cursor: pointer;
  padding: ${SPACING.XSMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${COLORS.BLACK_TEXT};
  }
`;

const InstructionStep = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SPACING.SMALL};
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StepNumber = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLORS.BLUE_MAIN};
  color: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONTS.SIZE_SMALL};
  margin-right: ${SPACING.SMALL};
  flex-shrink: 0;
`;

const StepText = styled.span`
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLACK_TEXT};
`;

const AddToHomeScreen = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  
  useEffect(() => {
    // Detectar si es un dispositivo móvil
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Detectar sistema operativo
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const android = /Android/i.test(navigator.userAgent);
    
    setIsIOS(iOS);
    setIsAndroid(android);
    
    // Verificar si la app ya está instalada
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    
    // Mostrar el prompt solo en móviles y si no está en modo standalone
    if (isMobile && !isStandalone) {
      // Esperar un tiempo antes de mostrar el prompt
      const timer = setTimeout(() => {
        // Verificar si el usuario ya ha cerrado el prompt antes
        const hasClosedPrompt = localStorage.getItem('a2hs_closed');
        if (!hasClosedPrompt) {
          setIsVisible(true);
        }
      }, 5000); // Mostrar después de 5 segundos
      
      return () => clearTimeout(timer);
    }
  }, []);
  
  const handleClose = () => {
    setIsVisible(false);
    // Guardar en localStorage que el usuario cerró el prompt
    localStorage.setItem('a2hs_closed', 'true');
    // Resetear después de 7 días
    setTimeout(() => {
      localStorage.removeItem('a2hs_closed');
    }, 7 * 24 * 60 * 60 * 1000);
  };
  
  const downloadApp = () => {
    if (isIOS) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else if (isAndroid) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };
  
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };
  
  // Determinar el icono y texto según el dispositivo
  const getAppDownloadInfo = () => {
    if (isIOS) {
      return {
        icon: <FaApple />,
        text: 'Descargar en iOS'
      };
    } else if (isAndroid) {
      return {
        icon: <FaAndroid />,
        text: 'Descargar en Android'
      };
    } else {
      return {
        icon: <FaDownload />,
        text: 'Descargar App'
      };
    }
  };
  
  const appInfo = getAppDownloadInfo();
  
  return (
    <PromptContainer isVisible={isVisible}>
      <CloseButton onClick={handleClose}>
        <FaTimes />
      </CloseButton>
      
      <PromptContent>
        <IconContainer>
          {appInfo.icon}
        </IconContainer>
        <TextContainer>
          <Title>¡Descarga Trineo y pide tu flete!</Title>
          <Description>
            Accede a la mejor experiencia para tus traslados
          </Description>
        </TextContainer>
      </PromptContent>
      
      <ButtonsContainer>
        <PrimaryButton onClick={downloadApp}>
          {appInfo.icon} {appInfo.text}
        </PrimaryButton>
        <SecondaryButton onClick={openWhatsApp} style={{ borderColor: '#4CAF50', color: '#4CAF50' }}>
          <FaWhatsapp style={{ marginRight: '8px' }} /> Pedir por WhatsApp
        </SecondaryButton>
      </ButtonsContainer>
    </PromptContainer>
  );
};

export default AddToHomeScreen; 