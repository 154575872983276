import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * Componente para proteger rutas que requieren autenticación
 * @param {Object} props - Propiedades del componente
 * @param {React.ReactNode} props.children - Componente hijo a renderizar si el usuario está autenticado
 * @returns {React.ReactNode}
 */
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();
  
  useEffect(() => {
    // Verificar si el usuario está autenticado
    const checkAuth = () => {
      const token = localStorage.getItem('auth_token');
      
      if (!token) {
        setIsAuthenticated(false);
        return;
      }
      
      try {
        // Verificar que el token es válido (tiene un formato correcto)
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        
        // Verificar si el token tiene al menos email (campo básico)
        if (!decodedToken.email) {
          throw new Error('Token inválido');
        }
        
        // En un entorno real, aquí haríamos una petición al servidor para
        // verificar que el token es válido y no ha expirado
        
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error al verificar la autenticación:', error);
        localStorage.removeItem('auth_token');
        setIsAuthenticated(false);
      }
    };
    
    checkAuth();
  }, []);
  
  // Mientras se verifica la autenticación, mostrar un mensaje de carga
  if (isAuthenticated === null) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <div>Verificando autenticación...</div>
      </div>
    );
  }
  
  // Si no está autenticado, redirigir a la página de login
  if (!isAuthenticated) {
    // Guardar la ubicación actual para redirigir después de login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  // Si está autenticado, renderizar el componente hijo
  return children;
};

export default ProtectedRoute; 