import React, { useCallback, useEffect, useState } from 'react';
import { FaAndroid, FaApple, FaArrowRight, FaDownload, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import NetworkBackground from '../Accessibility/NetworkBackground';
import Button from '../Button/Button';
import Section from '../Section/Section';
// Importar imágenes de las categorías
import appUsuarioHome from '../../assets/images/app-usuario-home.png';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 60px; // Reduced space for fixed header on mobile
  position: relative;
  margin-bottom: 30px; // Espacio adicional en la parte inferior
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 80px; // Medium space for tablet
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    padding-top: 100px; // Original space for desktop
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeroContent = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex: 1;
    padding-right: ${SPACING.XLARGE};
  }
`;

const HeroTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLUE_MAIN};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    font-size: calc(${FONTS.SIZE_HEADING_1} * 1.2);
  }
`;

const HeroSubtitle = styled.p`
  font-size: ${FONTS.SIZE_HEADING_3};
  margin-bottom: ${SPACING.LARGE};
  max-width: 600px;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    margin-left: 0;
  }
`;

const ChannelOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.MEDIUM};
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    margin: 0;
  }
`;

const ChannelOption = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.MEDIUM};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ChannelTitle = styled.h3`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.SMALL};
  display: flex;
  align-items: center;
`;

const ChannelIcon = styled.span`
  margin-right: ${SPACING.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color || COLORS.BLUE_MAIN};
  font-size: 20px;
`;

const ChannelDescription = styled.p`
  font-size: ${FONTS.SIZE_SECONDARY};
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLACK_TEXT}CC;
`;

const ArrowIcon = styled.span`
  margin-left: ${SPACING.SMALL};
  transition: transform 0.2s ease;
  
  ${ChannelOption}:hover & {
    transform: translateX(5px);
  }
`;

const HeroImageContainer = styled.div`
  margin-top: ${SPACING.XLARGE};
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 1500px;
  transform-style: preserve-3d;
  padding-bottom: 20px; // Reducido de 70px para que la sombra no se extienda tanto
  /* Fijar altura para evitar reposicionamiento */
  height: 570px;
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    flex: 1;
    margin-top: 0;
    height: 610px;
  }
`;

const Phone3D = styled.div`
  position: absolute; /* Cambio a posicionamiento absoluto */
  width: 280px;
  height: 570px;
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  cursor: grab;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  top: 0; /* Fijar en la parte superior del contenedor */
  
  /* Animación de entrada mejorada */
  opacity: ${props => props.isLoaded ? 1 : 0};
  transform: ${props => props.isLoaded 
    ? 'rotateY(-20deg) rotateX(10deg) translateY(0) scale(1)' 
    : 'rotateY(-20deg) rotateX(10deg) translateY(80px) scale(0.4)'};
  transition: opacity 0.8s ease-out, transform 1s cubic-bezier(0.17, 0.67, 0.3, 1.33);
  animation: ${props => props.isLoaded ? 'phoneFloat 8s ease-in-out infinite' : 'none'};
  animation-delay: 0.8s;
  
  @keyframes phoneFloat {
    0% {
      transform: rotateY(-20deg) rotateX(10deg) translateY(0);
    }
    25% {
      transform: rotateY(-18deg) rotateX(8deg) translateY(-5px);
    }
    50% {
      transform: rotateY(-15deg) rotateX(5deg) translateY(-10px);
    }
    75% {
      transform: rotateY(-17deg) rotateX(7deg) translateY(-5px);
    }
    100% {
      transform: rotateY(-20deg) rotateX(10deg) translateY(0);
    }
  }
  
  &:hover {
    animation-play-state: paused !important;
    transform: rotateY(-10deg) rotateX(5deg) translateY(-10px) scale(1.1) !important; /* Añadido zoom del 15% (scale 1.1) */
  }
  
  &:active {
    cursor: grabbing;
  }
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    width: 300px;
    height: 610px;
  }
`;

const PhoneBody = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 36px;
  background: linear-gradient(45deg, #333, #111);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.25), /* Reducido de 0 30px 60px para menor extensión vertical */
    0 8px 15px rgba(0, 0, 0, 0.15), /* Reducido de 0 15px 30px para menor extensión vertical */
    0 0 0 2px #000 inset,
    0 0 15px rgba(0, 0, 0, 0.3); /* Reducido de 0.5 para menor intensidad */
  transform-style: preserve-3d;
  transform: translateZ(-10px);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 36px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 50%);
    z-index: 3;
    pointer-events: none;
  }
`;

const PhoneScreen = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 30px;
  overflow: hidden;
  background: #fff;
  transform: translateZ(0);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }
`;

const PhoneNotch = styled.div`
  position: absolute;
  width: 150px;
  height: 28px;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  z-index: 11;
  
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(100, 225, 100, 0.9);
    border-radius: 50%;
    right: 20px;
    top: 9px;
    box-shadow: 0 0 5px rgba(100, 225, 100, 0.7);
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    left: 20px;
    top: 9px;
  }
`;

const AppImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center top;
  transform: translateZ(1px);
  
  /* Añadir propiedades para optimizar la carga */
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  
  /* Añadir un color de fondo mientras carga */
  background-color: #f5f5f5;
`;

const PhoneButton = styled.div`
  position: absolute;
  width: 3px;
  height: 50px;
  background: #222;
  right: -3px;
  top: 120px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  
  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 70px;
    background: #222;
    right: 0;
    top: 90px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    background: #222;
    right: 0;
    top: -60px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const PhoneSpeaker = styled.div`
  position: absolute;
  width: 60px;
  height: 5px;
  background: #333;
  border-radius: 3px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
  z-index: 12;
`;

const PhoneReflection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 15%,
    rgba(0, 0, 0, 0) 60%
  );
  border-radius: 30px;
  z-index: 11;
  pointer-events: none;
`;

const AppStatusBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: ${COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  z-index: 2;
`;

const FloatingParticle = styled.div`
  position: absolute;
  width: ${props => props.size || '10px'};
  height: ${props => props.size || '10px'};
  background: ${props => props.color || COLORS.BLUE_MAIN};
  border-radius: 50%;
  opacity: ${props => props.opacity || 0.3};
  top: ${props => props.top};
  left: ${props => props.left};
  filter: blur(2px);
  z-index: -1;
  animation: float ${props => props.duration || '8s'} ease-in-out infinite;
  animation-delay: ${props => props.delay || '0s'};
  
  @keyframes float {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-20px) translateX(10px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: ${SPACING.SMALL};
  margin-bottom: ${SPACING.MEDIUM};
`;

const Badge = styled.span`
  background-color: ${COLORS.BLUE_MAIN}22;
  color: ${COLORS.BLUE_MAIN};
  padding: ${SPACING.XXSMALL} ${SPACING.SMALL};
  border-radius: 50px;
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
`;

const StoreIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING.SMALL};
  gap: ${SPACING.MEDIUM};
`;

const StoreIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${COLORS.BLACK_TEXT}99;
  
  span {
    margin-left: 4px;
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 0;
  left: 0;
`;

const LoadingText = styled.div`
  margin-top: 15px;
  font-size: ${FONTS.SIZE_SMALL};
  color: ${COLORS.BLUE_MAIN};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  opacity: 0.8;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${COLORS.BLUE_MAIN};
  animation: spin 1s ease-in-out infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const HeroSection = () => {
  // Ref para el teléfono 3D
  const [phoneRotation, setPhoneRotation] = useState({ x: 10, y: -20 });
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });
  // Estado para controlar si la imagen está cargada
  const [imageLoaded, setImageLoaded] = useState(false);
  // Estado para controlar si el mouse está sobre el teléfono
  const [isHovering, setIsHovering] = useState(false);
  
  // Manejador para rotation del teléfono con el mouse - usando useCallback para evitar advertencias de dependencias
  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    
    const dx = e.clientX - lastMousePosition.x;
    const dy = e.clientY - lastMousePosition.y;
    
    setPhoneRotation({
      x: Math.max(-15, Math.min(15, phoneRotation.x - dy * 0.5)),
      y: Math.max(-30, Math.min(30, phoneRotation.y + dx * 0.5))
    });
    
    setLastMousePosition({ x: e.clientX, y: e.clientY });
  }, [isDragging, lastMousePosition, phoneRotation]);
  
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastMousePosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  
  // Manejadores para el hover
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  
  // Manejador para la carga de la imagen
  const handleImageLoad = () => {
    // Agregamos un pequeño retraso para asegurar una transición suave
    setTimeout(() => {
      setImageLoaded(true);
    }, 400);
  };
  
  // Efecto para precargar la imagen al montar el componente
  useEffect(() => {
    const img = new Image();
    img.src = appUsuarioHome;
    img.onload = handleImageLoad;
    
    // Si la imagen ya está en caché, activar manualmente
    if (img.complete) {
      handleImageLoad();
    }
  }, []);
  
  // Función para abrir WhatsApp
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };
  
  // Función para descargar la app
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };
  
  // Efecto para manejar eventos del mouse globales
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);
  
  // Efecto para auto-rotación suave cuando no se está interactuando
  useEffect(() => {
    if (!isDragging && imageLoaded) {
      const timer = setTimeout(() => {
        setPhoneRotation(prev => ({
          x: prev.x * 0.95 + 10 * 0.05,
          y: prev.y * 0.95 + (-20) * 0.05
        }));
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [isDragging, phoneRotation, imageLoaded]);
  
  return (
    <Section spacingTop="large" spacingBottom="large">
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <HeroContainer>
          <HeroContent>
            <BadgeContainer>
              <Badge>Omnicanal</Badge>
              <Badge>Simple</Badge>
              <Badge>Eficiente</Badge>
            </BadgeContainer>
            <HeroTitle>
              Traslada lo que necesites,<br />
              cuando lo necesites
            </HeroTitle>
            <HeroSubtitle>
              Pedir un flete nunca fue tan fácil y seguro. 
              Trineo conecta personas con transportistas de forma rápida y eficiente.
            </HeroSubtitle>
            
            <ChannelOptions>
              <ChannelOption style={{ 
                border: `2px solid ${COLORS.BLUE_MAIN}`, 
                transform: 'scale(1.03)', 
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)' 
              }}>
                <ChannelTitle>
                  <ChannelIcon color={COLORS.BLUE_MAIN}><FaDownload /></ChannelIcon>
                  Descarga nuestra App
                </ChannelTitle>
                <ChannelDescription>
                  Solicita fletes para tus compras, mudanzas o cualquier objeto que necesites trasladar. Seguimiento en tiempo real y más.
                </ChannelDescription>
                <Button variant="primary" size="medium" onClick={downloadApp} style={{ fontWeight: 'bold', padding: '12px 20px', fontSize: '1.05em' }}>
                  Obtener la App <ArrowIcon><FaArrowRight /></ArrowIcon>
                </Button>
                <StoreIcons>
                  <StoreIcon>
                    <FaAndroid /> <span>Android</span>
                  </StoreIcon>
                  <StoreIcon>
                    <FaApple /> <span>iOS</span>
                  </StoreIcon>
                </StoreIcons>
              </ChannelOption>
              
              <ChannelOption>
                <ChannelTitle>
                  <ChannelIcon color="#4CAF50"><FaWhatsapp /></ChannelIcon>
                  Solicita por WhatsApp
                </ChannelTitle>
                <ChannelDescription>
                  ¿Necesitas mover algo rápido? Contáctanos por WhatsApp y te ayudamos a trasladar lo que necesites sin complicaciones.
                </ChannelDescription>
                <Button 
                  variant="outline" 
                  size="medium" 
                  onClick={openWhatsApp}
                  style={{ borderColor: '#4CAF50', color: '#4CAF50' }}
                >
                  Chatear ahora <ArrowIcon><FaArrowRight /></ArrowIcon>
                </Button>
              </ChannelOption>
            </ChannelOptions>
          </HeroContent>
          
          <HeroImageContainer>
            {!imageLoaded && (
              <LoaderContainer>
                <Spinner />
                <LoadingText>Cargando aplicación...</LoadingText>
              </LoaderContainer>
            )}
            
            <Phone3D 
              isLoaded={imageLoaded}
              onMouseDown={handleMouseDown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ 
                transform: isDragging && imageLoaded
                  ? `rotateX(${phoneRotation.x}deg) rotateY(${phoneRotation.y}deg) scale(1)`
                  : isHovering && imageLoaded
                    ? 'rotateY(-10deg) rotateX(5deg) translateY(-10px) scale(1.1)' /* Añadido zoom del 15% (scale 1.1) */
                    : undefined,
                animationPlayState: (isDragging || isHovering) ? 'paused' : 'running'
              }}
            >
              <PhoneBody />
              <PhoneScreen>
                <AppStatusBar>TRINEO</AppStatusBar>
                <AppImage 
                  src={appUsuarioHome} 
                  style={{
                    transform: 'translateZ(0)',
                    WebkitTransform: 'translateZ(0)'
                  }}
                />
                <PhoneReflection />
              </PhoneScreen>
              <PhoneNotch />
              <PhoneSpeaker />
              <PhoneButton />
            </Phone3D>
            
            {/* Partículas flotantes para efecto visual - Mover dentro del contenedor para evitar reposicionamiento */}
            <FloatingParticle size="30px" color={COLORS.BLUE_MAIN} opacity="0.1" top="10%" left="20%" duration="12s" />
            <FloatingParticle size="20px" color="#FBAC02" opacity="0.15" top="70%" left="10%" duration="10s" delay="1s" />
            <FloatingParticle size="15px" color="#4CAF50" opacity="0.1" top="30%" left="80%" duration="15s" delay="2s" />
            <FloatingParticle size="25px" color="#4CAF50" opacity="0.12" top="80%" left="70%" duration="11s" delay="3s" />
          </HeroImageContainer>
        </HeroContainer>
      </div>
    </Section>
  );
};

export default HeroSection; 