import React from 'react';
import { FaAndroid, FaApple, FaCommentDots, FaDownload, FaMobileAlt, FaWhatsapp } from 'react-icons/fa';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';
import Button from '../Button/Button';
import Section from '../Section/Section';

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
`;

// Colores específicos para usuarios según la guía de diseño
const USER_BLUE = '#0078D7';
const USER_BLUE_LIGHT = '#4CA6FF';
const USER_BLUE_DARK = '#005A9E';

const CTATitle = styled.h2`
  font-family: ${FONTS.PRIMARY};
  font-weight: 700;
  font-size: 36px;
  color: ${USER_BLUE_DARK};
  text-align: center;
  margin-bottom: ${SPACING.MEDIUM};
  position: relative;
  
  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-color: ${USER_BLUE};
    margin: ${SPACING.SMALL} auto 0;
    border-radius: 2px;
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    font-size: 28px;
  }
`;

const CTADescription = styled.p`
  text-align: center;
  font-size: ${FONTS.SIZE_MEDIUM};
  line-height: 1.6;
  color: ${COLORS.BLACK_TEXT};
  margin-bottom: ${SPACING.LARGE};
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const CTAGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.LARGE};
  width: 100%;
  max-width: 1000px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CTACard = styled.div`
  background-color: ${COLORS.WHITE}DD;
  border-radius: ${SPACING.MEDIUM};
  padding: ${SPACING.LARGE};
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  backdrop-filter: blur(5px);
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const CTACardContent = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CTACardFooter = styled.div`
  width: 100%;
  margin-top: auto;
`;

const CTACardTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  margin-bottom: ${SPACING.SMALL};
  color: ${COLORS.BLACK_TEXT};
  display: flex;
  align-items: center;
`;

const CTAIconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor || COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${COLORS.WHITE};
  margin-bottom: ${SPACING.MEDIUM};
`;

const CTAFeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: ${SPACING.LARGE};
  flex-grow: 1;
`;

const CTAFeatureItem = styled.li`
  margin-bottom: ${SPACING.SMALL};
  position: relative;
  padding-left: ${SPACING.MEDIUM};
  color: ${COLORS.BLACK_TEXT}CC;
  
  &:before {
    content: "•";
    position: absolute;
    left: 0;
    color: ${({ bulletColor }) => bulletColor || COLORS.BLUE_MAIN};
    font-weight: bold;
  }
`;

const CTATagline = styled.p`
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-top: auto;
  margin-bottom: ${SPACING.MEDIUM};
  color: ${COLORS.BLACK_TEXT};
`;

const StoreIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING.SMALL};
  gap: ${SPACING.MEDIUM};
  height: 32px; /* Altura fija para los iconos de tienda */
`;

const StoreIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${COLORS.BLACK_TEXT}99;
  
  span {
    margin-left: 4px;
    font-size: ${FONTS.SIZE_SMALL};
  }
`;

const FullWidthBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 120, 215, 0.08);
  backdrop-filter: blur(4px);
  z-index: -1;
`;

const CTASection = () => {
  // Función para abrir WhatsApp
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };
  
  // Función para descargar la app
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };

  return (
    <Section>
      <FullWidthBackground />
      <CTAContainer>
        <CTATitle>¿Necesitas trasladar algo?</CTATitle>
        <CTADescription>
          Trineo te ayuda a mover tus compras, muebles o cualquier objeto de forma fácil y segura.
        </CTADescription>
        
        <CTAGrid>
          <CTACard>
            <CTACardContent>
              <CTAIconContainer bgColor={COLORS.BLUE_SECONDARY}>
                <FaMobileAlt />
              </CTAIconContainer>
              <CTACardTitle>
                App móvil - Recomendado
              </CTACardTitle>
              <CTAFeatureList>
                <CTAFeatureItem bulletColor={COLORS.BLUE_SECONDARY}>Solicita fletes para tus compras</CTAFeatureItem>
                <CTAFeatureItem bulletColor={COLORS.BLUE_SECONDARY}>Seguimiento en tiempo real</CTAFeatureItem>
                <CTAFeatureItem bulletColor={COLORS.BLUE_SECONDARY}>Califica a los transportistas</CTAFeatureItem>
                <CTAFeatureItem bulletColor={COLORS.BLUE_SECONDARY}>Programa traslados con anticipación</CTAFeatureItem>
                <CTAFeatureItem bulletColor={COLORS.BLUE_SECONDARY}>Guarda tus direcciones favoritas</CTAFeatureItem>
              </CTAFeatureList>
              <CTATagline>La forma más fácil de trasladar lo que necesitas</CTATagline>
            </CTACardContent>
            <CTACardFooter>
              <Button variant="primary" size="large" fullWidth onClick={downloadApp}>
                <FaDownload style={{ marginRight: '8px' }} /> Descargar App
              </Button>
              <StoreIcons>
                <StoreIcon>
                  <FaAndroid /> <span>Android</span>
                </StoreIcon>
                <StoreIcon>
                  <FaApple /> <span>iOS</span>
                </StoreIcon>
              </StoreIcons>
            </CTACardFooter>
          </CTACard>
          
          <CTACard>
            <CTACardContent>
              <CTAIconContainer bgColor="#4CAF50">
                <FaCommentDots />
              </CTAIconContainer>
              <CTACardTitle>
                WhatsApp
              </CTACardTitle>
              <CTAFeatureList>
                <CTAFeatureItem bulletColor="#4CAF50">Solicitud inmediata para tus traslados</CTAFeatureItem>
                <CTAFeatureItem bulletColor="#4CAF50">Atención personalizada</CTAFeatureItem>
                <CTAFeatureItem bulletColor="#4CAF50">Cotizaciones a medida</CTAFeatureItem>
                <CTAFeatureItem bulletColor="#4CAF50">Ideal para objetos de tamaño especial</CTAFeatureItem>
              </CTAFeatureList>
              <CTATagline>Solución rápida para tus necesidades de traslado</CTATagline>
            </CTACardContent>
            <CTACardFooter>
              <Button 
                variant="outline" 
                size="large" 
                fullWidth
                style={{ borderColor: '#4CAF50', color: '#4CAF50' }}
                onClick={openWhatsApp}
              >
                <FaWhatsapp style={{ marginRight: '8px' }} /> Chatear vía WhatsApp
              </Button>
              <div style={{ height: '32px', marginTop: SPACING.SMALL }}></div> {/* Espacio para compensar los iconos de tienda */}
            </CTACardFooter>
          </CTACard>
        </CTAGrid>
      </CTAContainer>
    </Section>
  );
};

export default CTASection; 