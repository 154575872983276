import React, { useEffect, useState } from 'react';
import { FaAndroid, FaApple, FaBars, FaComments, FaDownload, FaEnvelope, FaHome, FaStar, FaTimes, FaTruck, FaTruckMoving, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../../assets/images/logo_r.png';
import { BREAKPOINTS, COLORS, FONTS, SHADOWS, SPACING } from '../../styles/variables';
import Button from '../Button/Button';
import Container from '../Container/Container';

const HeaderWrapper = styled.header`
  padding: ${props => props.$scrolled ? SPACING.XSMALL : SPACING.SMALL} 0;
  background-color: ${props => props.$scrolled ? COLORS.WHITE + 'A0' : COLORS.WHITE + '20'}; /* 20 = 12.5% opacidad en estado inicial */
  backdrop-filter: ${props => props.$scrolled ? 'blur(3px)' : 'blur(1.5px)'}; /* Blur muy sutil en estado inicial */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease, padding 0.3s ease, background-color 0.3s ease, backdrop-filter 0.3s ease;
  ${({ $scrolled }) => $scrolled && `
    box-shadow: ${SHADOWS.MEDIUM};
  `}
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${props => props.$scrolled ? SPACING.SMALL : SPACING.MEDIUM} 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  display: flex;
  align-items: center;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
    color: ${COLORS.BLUE_MAIN};
  }
`;

const LogoImage = styled.img`
  height: ${props => props.$scrolled ? '45px' : '60px'};
  width: auto;
  transition: height 0.3s ease;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    height: ${props => props.$scrolled ? '50px' : '90px'};
  }
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    height: ${props => props.$scrolled ? '40px' : '50px'};
  }
`;

const LogoPlaceholder = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${COLORS.BLUE_MAIN};
  margin-right: ${SPACING.SMALL};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

const LogoText = styled.span`
  @media (max-width: 380px) {
    display: none;
  }
`;

const LogoTagline = styled.span`
  display: none; /* Nunca mostramos el tagline */
`;

const NavLinks = styled.nav`
  display: none;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: flex;
    align-items: center;
    gap: ${SPACING.LARGE};
  }
`;

const NavLink = styled.a`
  color: ${props => props.$scrolled ? COLORS.BLACK_TEXT : COLORS.BLACK_TEXT};
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  text-decoration: none;
  transition: color 0.2s ease;
  position: relative;
  text-shadow: ${props => props.$scrolled ? 'none' : '0 0 5px rgba(255, 255, 255, 0.5)'};
  
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${COLORS.BLUE_MAIN};
    transition: width 0.2s ease;
  }
  
  &:hover {
    color: ${COLORS.BLUE_MAIN};
    
    &:after {
      width: 100%;
    }
  }
`;

const TransportistaLink = styled(NavLink)`
  color: #FBAC02;
  font-weight: ${FONTS.WEIGHT_BOLD};
  display: flex;
  align-items: center;
  text-shadow: ${props => props.$scrolled ? 'none' : '0 0 5px rgba(255, 255, 255, 0.5)'};
  
  svg {
    margin-right: 5px;
    color: #FBAC02;
  }
  
  &:after {
    background-color: #FBAC02;
  }
  
  &:hover {
    color: #EDB940;
    
    svg {
      color: #EDB940;
    }
  }
`;

const ChannelButtons = styled.div`
  display: none;
  gap: ${SPACING.SMALL};
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    display: flex;
  }
`;

const ButtonIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${SPACING.XSMALL};
`;

const StoreIconsSmall = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 5px;
  font-size: 12px;
`;

const MobileMenuButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: ${COLORS.BLUE_MAIN};
  cursor: pointer;
  display: flex;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.WHITE}E0;
  backdrop-filter: blur(5px);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding: ${SPACING.XSMALL};
  transform: translateX(${({ $isOpen }) => $isOpen ? '0' : '100%'});
  transition: transform 0.3s ease;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING.MEDIUM};
  width: 100%;
`;

const MobileMenuContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
`;

const MobileNavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.XXSMALL};
  width: 100%;
`;

const MobileNavLink = styled.a`
  color: ${COLORS.BLACK_TEXT};
  font-size: ${FONTS.SIZE_BODY};
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  text-decoration: none;
  padding-top: ${SPACING.XSMALL};
  padding-bottom: ${SPACING.XXSMALL};
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  margin-bottom: ${SPACING.XXSMALL};
  display: flex;
  align-items: center;
  width: 100%;
  
  &:hover {
    color: ${COLORS.BLUE_MAIN};
  }
  
  svg {
    margin-right: 8px;
    font-size: 18px;
    color: ${COLORS.BLUE_MAIN};
  }
`;

const MobileNavDescription = styled.p`
  font-size: ${FONTS.SIZE_XSMALL};
  color: ${COLORS.BLACK_TEXT}99;
  margin-top: 2px;
  margin-bottom: ${SPACING.XXSMALL};
  line-height: 1.3;
`;

const MobileNavItem = styled.div`
  margin-bottom: ${SPACING.XSMALL};
  width: 100%;
`;

const MobileChannelButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.XSMALL};
  margin-top: ${SPACING.MEDIUM};
  width: 100%;
  padding: ${SPACING.SMALL} 0;
  
  @media (min-height: 600px) {
    margin-top: auto; /* Empuja los botones hacia abajo */
    border-top: 1px solid ${COLORS.GREY_LIGHT}50;
    padding-top: ${SPACING.MEDIUM};
  }
`;

const MobileChannelTitle = styled.p`
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  margin-bottom: ${SPACING.XSMALL};
  margin-top: ${SPACING.SMALL};
  color: ${COLORS.BLACK_TEXT}99;
`;

const MobileTransportistaLink = styled(MobileNavLink)`
  color: #FBAC02;
  font-weight: ${FONTS.WEIGHT_BOLD};
  display: flex;
  align-items: center;
  width: 100%;
  
  svg {
    margin-right: 8px;
    font-size: 18px;
    color: #FBAC02;
  }
`;

const LoginButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: ${COLORS.BLUE_MAIN};
  background-color: transparent;
  border: 1px solid ${COLORS.BLUE_MAIN};
  border-radius: 50%;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${COLORS.BLUE_MAIN}10;
    transform: translateY(-2px);
  }
  
  svg {
    font-size: 14px;
  }
`;

const ProfileButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: ${COLORS.WHITE};
  text-decoration: none;
  font-weight: ${FONTS.WEIGHT_MEDIUM};
  transition: background-color 0.2s ease, transform 0.2s ease;
  border-radius: 50%;
  background-color: ${COLORS.BLUE_MAIN};
  
  &:hover {
    background-color: ${COLORS.BLUE_DARK};
    transform: translateY(-2px);
  }
`;

const MobileLoginLink = styled(MobileNavLink)`
  color: ${COLORS.BLUE_MAIN};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  
  svg {
    margin-right: 8px;
    font-size: 18px;
    color: ${COLORS.BLUE_MAIN};
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // Función para abrir WhatsApp
  const openWhatsApp = () => {
    // Número de WhatsApp con código de país
    const phoneNumber = '+56977209949';
    // Mensaje predefinido
    const message = encodeURIComponent('Hola, necesito trasladar algo y me gustaría saber cómo Trineo puede ayudarme.');
    // URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappUrl, '_blank');
  };
  
  // Función para descargar la app
  const downloadApp = () => {
    // Detectar sistema operativo para redireccionar a la tienda correspondiente
    const userAgent = navigator.userAgent || navigator.vendor;
    
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.trineoapp.trienoapp&hl=es_419', '_blank');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.open('https://apps.apple.com/cl/app/fletes-trineo/id6471224117', '_blank');
    } else {
      // Si es Windows u otro dispositivo no móvil, redirigir a la página /app
      window.location.href = '/app';
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    // Verificar si el usuario está autenticado
    const checkAuth = () => {
      const token = localStorage.getItem('auth_token');
      setIsLoggedIn(!!token);
    };
    
    window.addEventListener('scroll', handleScroll);
    checkAuth(); // Verificar autenticación al cargar el componente
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Función para alternar el menú
  const toggleMenu = () => {
    const newMenuState = !isMenuOpen;
    setIsMenuOpen(newMenuState);
    
    // Prevenir el scroll de la página cuando el menú está abierto
    if (newMenuState) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };
  
  // Limpiar el estilo de overflow cuando el componente se desmonta
  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  
  return (
    <>
      <HeaderWrapper $scrolled={scrolled}>
        <Container>
          <HeaderContainer>
            <Logo as="a" href="/">
              <LogoImage src={logoImage} alt="Trineo Logo" $scrolled={scrolled} />
            </Logo>
            <NavLinks>
              <NavLink href="#caracteristicas" $scrolled={scrolled}>Características</NavLink>
              <NavLink href="#categorias" $scrolled={scrolled}>Categorías</NavLink>
              <NavLink href="#testimonios" $scrolled={scrolled}>Testimonios</NavLink>
              <NavLink href="/contacto" $scrolled={scrolled}>Contacto</NavLink>
              <TransportistaLink href="/transportista" $scrolled={scrolled}>
                <FaTruck /> Transportistas
              </TransportistaLink>
            </NavLinks>
            <ChannelButtons>
              {/* Botón de login/perfil temporalmente oculto mientras OAuth está en verificación 
              {isLoggedIn ? (
                <ProfileButton to="/dashboard" title="Mi perfil">
                  <FaUser />
                </ProfileButton>
              ) : (
                <LoginButton to="/login" title="Ingresar">
                  <FaUser />
                </LoginButton>
              )}
              */}
              <Button 
                variant="outline" 
                size="small" 
                style={{ borderColor: '#4CAF50', color: '#4CAF50' }}
                onClick={openWhatsApp}
              >
                <ButtonIcon><FaWhatsapp /></ButtonIcon>
                WhatsApp
              </Button>
              <Button variant="primary" size="small" onClick={downloadApp}>
                <ButtonIcon><FaDownload /></ButtonIcon>
                App
                <StoreIconsSmall>
                  <FaAndroid />
                  <FaApple />
                </StoreIconsSmall>
              </Button>
            </ChannelButtons>
            <MobileMenuButton onClick={toggleMenu}>
              <FaBars />
            </MobileMenuButton>
          </HeaderContainer>
        </Container>
      </HeaderWrapper>
      
      <MobileMenu $isOpen={isMenuOpen}>
        <MobileMenuHeader>
          <Logo as="a" href="/" onClick={toggleMenu}>
            <LogoImage src={logoImage} alt="Trineo Logo" $scrolled={true} />
          </Logo>
          <MobileMenuButton onClick={toggleMenu}>
            <FaTimes />
          </MobileMenuButton>
        </MobileMenuHeader>
        
        <MobileMenuContent>
          <MobileNavLinks>
            <MobileNavItem>
              <MobileNavLink href="/" onClick={toggleMenu}>
                <FaHome /> Inicio
              </MobileNavLink>
              <MobileNavDescription>Vuelve a la página principal de Trineo</MobileNavDescription>
            </MobileNavItem>
            
            {/* Opción de login/perfil temporalmente oculta mientras OAuth está en verificación 
            {isLoggedIn ? (
              <MobileNavItem>
                <MobileLoginLink to="/dashboard" as={Link} onClick={toggleMenu}>
                  <FaUser /> Mi Perfil
                </MobileLoginLink>
                <MobileNavDescription>Accede a tu panel de control</MobileNavDescription>
              </MobileNavItem>
            ) : (
              <MobileNavItem>
                <MobileLoginLink to="/login" as={Link} onClick={toggleMenu}>
                  <FaUser /> Iniciar Sesión
                </MobileLoginLink>
                <MobileNavDescription>Accede a tu cuenta de Trineo</MobileNavDescription>
              </MobileNavItem>
            )}
            */}
            
            <MobileNavItem>
              <MobileTransportistaLink href="/transportista" onClick={toggleMenu}>
                <FaTruck /> Transportistas
              </MobileTransportistaLink>
              <MobileNavDescription>Se parte de nuestra red de transportistas</MobileNavDescription>
            </MobileNavItem>
            
            <MobileNavItem>
              <MobileNavLink href="#caracteristicas" onClick={toggleMenu}>
                <FaStar /> ¿Por qué elegir Trineo?
              </MobileNavLink>
              <MobileNavDescription>Descubre nuestra plataforma</MobileNavDescription>
            </MobileNavItem>
            
            <MobileNavItem>
              <MobileNavLink href="#categorias" onClick={toggleMenu}>
                <FaTruckMoving /> Categorías de vehículos
              </MobileNavLink>
              <MobileNavDescription>Conoce las diferentes categorías para tus fletes</MobileNavDescription>
            </MobileNavItem>
            
            <MobileNavItem>
              <MobileNavLink href="#testimonios" onClick={toggleMenu}>
                <FaComments /> Experiencias con Trineo
              </MobileNavLink>
              <MobileNavDescription>Lee las experiencias de nuestros usuarios</MobileNavDescription>
            </MobileNavItem>
            
            <MobileNavItem>
              <MobileNavLink href="/contacto" onClick={toggleMenu}>
                <FaEnvelope /> Contacto
              </MobileNavLink>
              <MobileNavDescription>Buscanos en Redes Sociales</MobileNavDescription>
            </MobileNavItem>
          </MobileNavLinks>
        </MobileMenuContent>
        
        <MobileChannelButtons>
          <Button 
            variant="outline" 
            size="medium" 
            style={{ borderColor: '#4CAF50', color: '#4CAF50' }}
            onClick={openWhatsApp}
          >
            <FaWhatsapp style={{ marginRight: '8px' }} /> WhatsApp
          </Button>
          <Button variant="primary" size="medium" onClick={downloadApp}>
            <FaDownload style={{ marginRight: '8px' }} /> Descargar App
            <StoreIconsSmall style={{ marginLeft: '8px' }}>
              <FaAndroid />
              <FaApple />
            </StoreIconsSmall>
          </Button>
        </MobileChannelButtons>
      </MobileMenu>
    </>
  );
};

export default Header; 