import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

// Estilos
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 100px;
  }
`;

const DashboardSection = styled.div`
  position: relative;
  padding: ${SPACING.XLARGE} 0;
  margin: 40px 0 60px;
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    margin: 60px 0;
  }
`;

const DashboardContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const WelcomeCard = styled.div`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: ${SPACING.LARGE};
  margin-bottom: ${SPACING.LARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding: ${SPACING.XLARGE};
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${SPACING.LARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const DashboardCard = styled.div`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: ${SPACING.LARGE};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
  }
`;

const DashboardCardTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_DARK};
  margin-bottom: ${SPACING.MEDIUM};
`;

const DashboardCardContent = styled.div`
  color: ${COLORS.BLACK_TEXT};
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.MEDIUM};
  margin-bottom: ${SPACING.LARGE};
`;

const ProfilePicture = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${COLORS.GREY_LIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  svg {
    font-size: 40px;
    color: ${COLORS.GREY_MEDIUM};
  }
`;

const ProfileInfo = styled.div`
  flex: 1;
`;

const ProfileName = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_DARK};
  margin-bottom: ${SPACING.XSMALL};
`;

const ProfileEmail = styled.p`
  font-size: ${FONTS.SIZE_MEDIUM};
  color: ${COLORS.GREY_DARK};
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${SPACING.SMALL};
  padding: ${SPACING.SMALL} ${SPACING.MEDIUM};
  background-color: transparent;
  color: ${COLORS.BLUE_MAIN};
  border: 1px solid ${COLORS.BLUE_MAIN};
  border-radius: 8px;
  font-size: ${FONTS.SIZE_SMALL};
  font-weight: ${FONTS.WEIGHT_SEMI_BOLD};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${COLORS.BLUE_MAIN}10;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: ${COLORS.BLUE_MAIN};
  animation: spin 1s ease-in-out infinite;
  margin: ${SPACING.XLARGE} auto;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${SPACING.XLARGE};
`;

const DashboardPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const checkAuth = async () => {
      // Verificar si hay un token en localStorage
      const token = localStorage.getItem('auth_token');
      
      if (!token) {
        // Si no hay token, redirigir a la página de login
        navigate('/login');
        return;
      }
      
      try {
        // Decodificar el token JWT para obtener información del usuario
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        
        // Verificar si el token tiene información del usuario
        if (!decodedToken.email) {
          throw new Error('Token inválido');
        }
        
        // En un entorno real, aquí haríamos una petición al servidor para
        // verificar que el token es válido y obtener los datos actualizados del usuario
        
        // Simulamos una petición al servidor
        setTimeout(() => {
          setUser({
            name: decodedToken.name || 'Usuario',
            email: decodedToken.email,
            // Otros datos del usuario...
          });
          setIsLoading(false);
        }, 1000);
        
      } catch (error) {
        console.error('Error al verificar la autenticación:', error);
        localStorage.removeItem('auth_token');
        navigate('/login');
      }
    };
    
    checkAuth();
  }, [navigate]);
  
  const handleLogout = () => {
    // Eliminar el token de autenticación
    localStorage.removeItem('auth_token');
    
    // Redirigir a la página de inicio
    navigate('/');
  };
  
  if (isLoading) {
    return (
      <PageWrapper>
        <Helmet>
          <title>Cargando... - Trineo</title>
        </Helmet>
        
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100vh', 
          overflow: 'hidden',
          zIndex: -1
        }}>
          <NetworkBackground />
        </div>
        
        <SubpageHeader title="Dashboard" />
        
        <MainContent>
          <Container>
            <LoadingContainer>
              <LoadingSpinner />
              <p>Cargando información del usuario...</p>
            </LoadingContainer>
          </Container>
        </MainContent>
        
        <Footer />
      </PageWrapper>
    );
  }
  
  return (
    <PageWrapper>
      <Helmet>
        <title>Mi Dashboard - Trineo</title>
        <meta name="description" content="Gestiona tus servicios y datos en el panel de control de Trineo." />
      </Helmet>
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh', 
        overflow: 'hidden',
        zIndex: -1
      }}>
        <NetworkBackground />
      </div>
      
      <SubpageHeader title="Dashboard" />
      
      <MainContent>
        <Container>
          <DashboardSection>
            <DashboardContent>
              <WelcomeCard>
                <ProfileHeader>
                  <ProfilePicture>
                    <FaUser />
                  </ProfilePicture>
                  <ProfileInfo>
                    <ProfileName>Hola, {user.name}</ProfileName>
                    <ProfileEmail>{user.email}</ProfileEmail>
                  </ProfileInfo>
                  <LogoutButton onClick={handleLogout}>
                    <FaSignOutAlt /> Cerrar Sesión
                  </LogoutButton>
                </ProfileHeader>
                
                <p>Bienvenido a tu panel de control de Trineo. Aquí podrás gestionar tus servicios de fletes, revisar tu historial y actualizar tu información personal.</p>
              </WelcomeCard>
              
              <DashboardGrid>
                <DashboardCard>
                  <DashboardCardTitle>Mis Servicios</DashboardCardTitle>
                  <DashboardCardContent>
                    <p>No tienes servicios activos actualmente.</p>
                    <p>¡Programa un flete con nosotros!</p>
                  </DashboardCardContent>
                </DashboardCard>
                
                <DashboardCard>
                  <DashboardCardTitle>Historial</DashboardCardTitle>
                  <DashboardCardContent>
                    <p>No tienes servicios completados todavía.</p>
                  </DashboardCardContent>
                </DashboardCard>
                
                <DashboardCard>
                  <DashboardCardTitle>Mi Perfil</DashboardCardTitle>
                  <DashboardCardContent>
                    <p>Actualiza tu información personal y preferencias.</p>
                  </DashboardCardContent>
                </DashboardCard>
              </DashboardGrid>
            </DashboardContent>
          </DashboardSection>
        </Container>
      </MainContent>
      
      <Footer />
    </PageWrapper>
  );
};

export default DashboardPage; 