// Theme variables for Trineo Landing Page
export const COLORS = {
  // Primary Colors
  BLUE_MAIN: '#0E3386',
  BLUE_SECONDARY: '#0A2A70',
  
  // Neutral Colors
  WHITE: '#FFFFFF',
  BLACK_TEXT: '#182135',
  GREY_LIGHT: '#E5E5E5',
  
  // State Colors
  SUCCESS: '#4CAF50',
  ERROR: '#D32F2F',
  WARNING: '#FFA000',
  
  // Opacity levels for backgrounds and overlays
  OPACITY_VERY_SUBTLE: 0.1,
  OPACITY_SUBTLE: 0.3,
  OPACITY_MEDIUM: 0.5,
  OPACITY_HIGH: 0.7,
};

export const FONTS = {
  // Font Family
  PRIMARY: "'Roboto', sans-serif",
  
  // Font Sizes
  SIZE_HEADING_1: '26px',
  SIZE_HEADING_2: '22px',
  SIZE_HEADING_3: '20px',
  SIZE_BUTTON: '16px',
  SIZE_BODY: '16px',
  SIZE_SECONDARY: '14px',
  SIZE_SMALL: '12px',
  
  // Font Weights
  WEIGHT_BOLD: 700,
  WEIGHT_SEMI_BOLD: 600,
  WEIGHT_MEDIUM: 500,
  WEIGHT_REGULAR: 400,
};

export const SPACING = {
  XXSMALL: '4px',
  XSMALL: '8px',
  SMALL: '12px',
  MEDIUM: '16px',
  LARGE: '24px',
  XLARGE: '32px',
  XXLARGE: '48px',
};

export const BORDER_RADIUS = {
  SMALL: '8px',
  MEDIUM: '12px',
  LARGE: '15px',
  PILL: '999px',
};

export const SHADOWS = {
  SMALL: '0 2px 4px rgba(0, 0, 0, 0.1)',
  MEDIUM: '0 4px 8px rgba(0, 0, 0, 0.1)',
  LARGE: '0 8px 16px rgba(0, 0, 0, 0.1)',
};

export const BREAKPOINTS = {
  MOBILE: '576px',
  TABLET: '768px',
  DESKTOP: '1024px',
  LARGE_DESKTOP: '1280px',
};

export const ANIMATIONS = {
  DURATION_FAST: '150ms',
  DURATION_MEDIUM: '250ms',
  DURATION_SLOW: '350ms',
  EASING: 'ease-in-out',
}; 