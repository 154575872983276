import React from 'react';
import styled from 'styled-components';
import NetworkBackground from '../../components/Accessibility/NetworkBackground';
import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import SubpageHeader from '../../components/Header/SubpageHeader';
import { BREAKPOINTS, COLORS, FONTS, SPACING } from '../../styles/variables';

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  overflow-x: hidden;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px;
  padding-bottom: ${SPACING.XXLARGE};
  
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    padding-top: 120px;
    padding-bottom: ${SPACING.XXXLARGE};
  }
`;

const PrivacyContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: ${SPACING.XLARGE};
  position: relative;
  z-index: 2;
  
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    padding: ${SPACING.LARGE};
  }
`;

const PageTitle = styled.h1`
  font-size: ${FONTS.SIZE_HEADING_1};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-bottom: ${SPACING.LARGE};
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: ${FONTS.SIZE_HEADING_3};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_MAIN};
  margin-top: ${SPACING.XLARGE};
  margin-bottom: ${SPACING.MEDIUM};
`;

const SubSectionTitle = styled.h3`
  font-size: ${FONTS.SIZE_HEADING_4};
  font-weight: ${FONTS.WEIGHT_BOLD};
  color: ${COLORS.BLUE_SECONDARY};
  margin-top: ${SPACING.LARGE};
  margin-bottom: ${SPACING.SMALL};
`;

const Paragraph = styled.p`
  margin-bottom: ${SPACING.MEDIUM};
  line-height: 1.7;
`;

const List = styled.ul`
  margin-bottom: ${SPACING.MEDIUM};
  padding-left: ${SPACING.LARGE};
`;

const ListItem = styled.li`
  margin-bottom: ${SPACING.SMALL};
`;

const PrivacyPage = () => {
  return (
    <PageWrapper>
      <div style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        background: `linear-gradient(135deg, ${COLORS.BLUE_LIGHT}40 0%, ${COLORS.WHITE} 100%)`,
        zIndex: -1
      }} />
      
      <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100vh',
        zIndex: -1
      }}>
        <NetworkBackground 
          color="#0A4B81" 
          opacity={0.15} 
          nodeCount={50} 
          speed={0.4} 
          parallaxFactor={0}
        />
      </div>
      
      <SubpageHeader title="Políticas de Privacidad" />
      
      <MainContent>
        <Container>
          <PrivacyContainer>
            <PageTitle>Políticas de Privacidad</PageTitle>
            
            <SubSectionTitle>Introducción</SubSectionTitle>
            <Paragraph>1.1. Bienvenido a TrineoApp. Esta política de privacidad (en adelante, "Política") explica cómo TrineoApp (en adelante, "Empresa") recopila, utiliza y protege la información personal del conductor (Musher) y el usuario (Ambos en adelante, "Los usuarios") cuando utilizan la plataforma de la Empresa.</Paragraph>
            
            <SectionTitle>Información personal</SectionTitle>
            <Paragraph>2.1. Información de Registro:</Paragraph>
            <Paragraph>La Empresa recopila información personal de los usuarios cuando se registran en la plataforma. Esta información puede incluir el nombre, dirección, número de teléfono y dirección de correo electrónico, como datos dentro de la aplicación, además a quien transporta se le exigirán los siguientes documentos del vehículo como Inscripción del vehículo (Padrón), Revisión Técnica, Seguro Obligatorio, permiso de circulación, y documentos personales, cedula de identidad, Licencia de conducir, Hoja de vida del conductor y certificado de antecedentes. Esta información es necesaria para crear las cuentas de los usuarios y utilizar los servicios de la plataforma.</Paragraph>
            
            <Paragraph>2.2. Información Adicional:</Paragraph>
            <Paragraph>Además de la información de registro, la Empresa también puede recopilar información personal adicional. Esto incluirá información de pago de los usuarios, información de tarjeta de crédito e información de cuenta bancaria, que es necesaria para procesar pagos relacionados con el servicio utilizado. También se puede recopilar información de seguimiento de la carga que se traslada, como detalles sobre los envíos o rutas. Esta información adicional es esencial para garantizar la correcta ejecución de los servicios.</Paragraph>
            
            <Paragraph>2.3. Uso de Cookies y Tecnologías Similares:</Paragraph>
            <Paragraph>La Empresa puede utilizar cookies y tecnologías similares para recopilar información sobre cómo el usuario utiliza la plataforma. Estas tecnologías se utilizan para mejorar la experiencia del usuario, proporcionar funciones específicas y analizar el rendimiento de la plataforma. Se puede proporcionar más información sobre cómo se utilizan las cookies y cómo los usuarios pueden gestionar sus preferencias de cookies en una sección específica de la política de privacidad.</Paragraph>
            
            <Paragraph>2.4. Uso de la Información Personal:</Paragraph>
            <Paragraph>La información personal del usuario, incluidos los datos de la lista de contactos y las imágenes, se utiliza únicamente con el propósito de brindar y mejorar el servicio ofrecido por TrineoApp. Esto incluye la coordinación de envíos, la facturación, la comunicación con el usuario, la mejora de la calidad de los servicios, y facilitar la interacción entre usuarios mediante la sugerencia de conexiones basadas en la lista de contactos. Nos comprometemos a no utilizar estos datos para otros fines sin el consentimiento previo y explícito del usuario.</Paragraph>
            
            <Paragraph>2.5. Recolección de Datos de Lista de Contactos e Imágenes:</Paragraph>
            <Paragraph>Además de la información mencionada anteriormente, TrineoApp recopila datos de la lista de contactos y de imágenes de los usuarios. Estos datos pueden incluir, pero no se limitan a, nombres, números de teléfono, direcciones de correo electrónico de la lista de contactos y cualquier tipo de dato contenido en las imágenes que los usuarios elijan compartir con la aplicación. La recopilación de estos datos tiene como finalidad mejorar la experiencia del usuario en nuestra plataforma, facilitar la conexión entre usuarios y permitir la prestación de nuestros servicios de manera más efectiva. Estos datos se recogen solo con el consentimiento explícito del usuario y se tratan con el máximo cuidado y seguridad, conforme a esta política de privacidad.</Paragraph>
            
            <SectionTitle>Compartir información personal</SectionTitle>
            <Paragraph>3.1. Compartir con Terceros para Prestar Servicios:</Paragraph>
            <Paragraph>La Empresa puede compartir la información personal de los usuarios con terceros, como subcontratistas y proveedores de servicios de pago, con el fin de llevar a cabo eficientemente los servicios ofrecidos por la plataforma. Estos terceros pueden incluir, conductores, empresas de logística y entidades encargadas del procesamiento de pagos. Estos terceros solo tendrán acceso a la información personal necesaria para prestar los servicios y estarán sujetos a acuerdos de confidencialidad y seguridad de datos para proteger la privacidad del usuario.</Paragraph>
            
            <Paragraph>3.2. Cumplimiento de la Ley y Protección de Derechos:</Paragraph>
            <Paragraph>La Empresa puede compartir la información personal de los usuarios si se requiere por ley o para proteger los derechos o propiedad de la Empresa. Esto puede incluir situaciones en las que se exija la divulgación de información personal por parte de autoridades legales, como tribunales o agencias gubernamentales. Además, la información personal puede ser compartida para proteger los derechos legales de la Empresa en caso de disputas legales, fraudes o abusos.</Paragraph>
            
            <Paragraph>3.3. Compartir Datos de Lista de Contactos e Imágenes:</Paragraph>
            <Paragraph>Los datos de la lista de contactos y las imágenes recopiladas pueden ser compartidos con terceros únicamente para la prestación de nuestros servicios, como facilitar la conexión entre usuarios o mejorar las funcionalidades de la plataforma. Todos los terceros que tienen acceso a estos datos están obligados a seguir nuestra política de privacidad y a implementar medidas de seguridad adecuadas para proteger esta información. La compartición de estos datos siempre se realiza con el consentimiento explícito del usuario y bajo un marco de seguridad y privacidad estricto.</Paragraph>
            
            <SectionTitle>Seguridad de la información personal</SectionTitle>
            <Paragraph>4.1. La Empresa se compromete a implementar y mantener medidas de seguridad razonables para proteger la información personal los usuarios contra el acceso no autorizado, la divulgación y el uso indebido. Estas medidas pueden incluir controles de acceso, autenticación de los usuarios, monitoreo de actividad. Nuestro objetivo es garantizar la confidencialidad y la integridad de sus datos.</Paragraph>
            
            <Paragraph>4.2. Uso de Servidores Seguros y Encriptación:</Paragraph>
            <Paragraph>La Empresa utiliza servidores seguros y tecnología de encriptación para proteger la información personal del usuario durante la transmisión y el almacenamiento. La encriptación de extremo a extremo garantiza que sus datos estén protegidos, tanto en tránsito como en reposo. Además, nuestros servidores están alojados en centros de datos de alta seguridad con medidas adicionales de protección física.</Paragraph>
            
            <Paragraph>4.3. Notificación de Violaciones de Seguridad:</Paragraph>
            <Paragraph>En caso de que ocurra cualquier violación de seguridad que pueda poner en riesgo la información personal del usuario, la Empresa se compromete a notificar al usuario de manera oportuna y de acuerdo con las leyes aplicables. Esta notificación incluirá información sobre la naturaleza de la violación, los datos afectados y las medidas que la Empresa está tomando para abordar la situación. Nuestra prioridad es mantener la transparencia y trabajar de manera proactiva para resolver cualquier problema de seguridad.</Paragraph>
            
            <SectionTitle>Derechos del Usuario</SectionTitle>
            <Paragraph>5.1. Derecho de Acceso, Rectificación y Eliminación:</Paragraph>
            <Paragraph>El Usuario tiene derecho a acceder a su información personal que hemos recopilado y procesado. Además, tiene el derecho de solicitar correcciones o actualizaciones a esta información si es inexacta o incompleta. Asimismo, el usuario tiene el derecho de solicitar la eliminación de su información personal cuando ya no sea necesaria para los fines para los que fue recopilada o procesada.</Paragraph>
            
            <Paragraph>5.2. Derecho a la Restricción y Objeción:</Paragraph>
            <Paragraph>El Usuario tiene derecho a solicitar la restricción del procesamiento de su información personal en ciertas circunstancias. Esto puede aplicarse cuando se está llevando a cabo una revisión de la exactitud de los datos o si el usuario se opone al procesamiento en ciertas situaciones. El usuario también tiene derecho a objetar el procesamiento de su información personal en circunstancias específicas, como el procesamiento con fines de marketing directo.</Paragraph>
            
            <Paragraph>5.3. Derecho a Presentar Quejas:</Paragraph>
            <Paragraph>El Usuario tiene derecho a presentar una queja ante la autoridad de protección de datos correspondiente si considera que su información personal ha sido procesada de manera indebida o si sus derechos de privacidad no se han respetado. Proporcionaremos al usuario la información necesaria para ponerse en contacto con la autoridad de protección de datos y explicaremos cómo ejercer este derecho.</Paragraph>
            
            <Paragraph>5.4. Derecho al Control de Datos de Lista de Contactos e Imágenes:</Paragraph>
            <Paragraph>El usuario tiene el derecho específico de gestionar el consentimiento otorgado para la recopilación y uso de datos de la lista de contactos y de imágenes. Esto incluye el derecho a retirar el consentimiento en cualquier momento, solicitar la eliminación de datos específicos o acceder a la información sobre cómo estos datos se utilizan y se comparten. TrineoApp se compromete a proporcionar los medios necesarios para que los usuarios ejerzan estos derechos de manera sencilla y efectiva.</Paragraph>
            
            <SectionTitle>Cambios a esta política de privacidad</SectionTitle>
            <Paragraph>6.1. Modificaciones de la Política:</Paragraph>
            <Paragraph>La Empresa se reserva el derecho de modificar esta política de privacidad en cualquier momento y a su entera discreción. Estas modificaciones pueden ser necesarias debido a cambios en la legislación de privacidad, nuevas funciones de la plataforma o para abordar preocupaciones de seguridad. Cualquier modificación se realizará con el objetivo de mejorar el servicio para los usuarios e intereses de la empresa.</Paragraph>
            
            <Paragraph>6.2. Aceptación de las Modificaciones:</Paragraph>
            <Paragraph>El usuario acepta y comprende que la continuación del uso de la plataforma después de cualquier modificación de esta política constituirá su aceptación de esta. Si el usuario no está de acuerdo con los cambios realizados en la política, deberá dejar de utilizar la plataforma. Nos esforzaremos por comunicar las modificaciones de manera clara y destacada para que los usuarios estén al tanto de las actualizaciones.</Paragraph>
            
            <Paragraph>Estas Políticas están publicadas en www.trineoapp.com y dentro de la aplicación por lo tanto al registrarse en trineo o trineo musher se entenderá como leída, entendida y aceptada.</Paragraph>
            
            <SectionTitle>Preguntas e Inquietudes</SectionTitle>
            <Paragraph>Si tiene alguna pregunta o inquietud sobre esta política de privacidad, no dudes en contactarnos a contacto@trineoapp.cl. Estamos aquí para responder a cualquier duda que pueda surgir y proporcionar aclaraciones sobre cómo se manejan los datos personales. La transparencia y la comunicación abierta son fundamentales para mantener la confianza.</Paragraph>
          </PrivacyContainer>
        </Container>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
};

export default PrivacyPage; 